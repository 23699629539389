/// <reference types="cordova-sqlite-storage" />
// import "core-js/stable";
// import "regenerator-runtime/runtime";
import {ResourceInstance, ResourceName, resourcesConfig} from "@co-common-libs/resources";
import {sharePromiseFactory} from "@co-frontend-libs/utils";
import {Query, QueryIDStruct, QueryTimestampsStruct} from "../types";
import {getCordovaSQLiteStorageConnection} from "./cordova-sqlite-storage";
import {buildQueryIdAssociation, buildQueryTimestampsMap, makeQueryInstances} from "./helpers";
import {getIndexedDBConnection} from "./indexeddb";
import {ResourceDBConnection} from "./types";

// vacuum about once every 100 starts...
const VACUUM_FREQUENCY = 0.01;

const getDBConnection = (): Promise<ResourceDBConnection> => {
  if (typeof cordova !== "undefined" && window.sqlitePlugin) {
    return getCordovaSQLiteStorageConnection(window.sqlitePlugin, "offline");
  }
  return getIndexedDBConnection(window.indexedDB, "offline");
};

// http://localhost:8080/api/users/04e5e71c-4512-4022-b7fa-034a8249915f/
const resourceURLPrefixRx = new RegExp("^http.*/api/([^/]+)/[0-9a-z-]{36}/$");
const resourceURLToName: {[urlPrefix: string]: string} = {};
Object.entries(resourcesConfig).forEach(([name, urlPrefix]) => {
  resourceURLToName[urlPrefix] = name;
});

function resourceNameForInstanceURL(url: string): string {
  console.assert(url, "resourceNameForInstanceURL called with empty URL");
  const match = resourceURLPrefixRx.exec(url) as RegExpExecArray;
  console.assert(match, `no resource name match for URL ${url}`);
  const urlPrefix = match[1];
  console.assert(urlPrefix);
  const resourceName = resourceURLToName[urlPrefix];
  if (process.env.NODE_ENV !== "production") {
    if (!resourceName) {
      // eslint-disable-next-line no-console
      console.warn(`No resource for URL prefix: ${urlPrefix}`);
    }
  }
  return resourceName;
}
// return resourceName -> instance[] mapping
function buildResourceInstanceMap(instanceArray: ResourceInstance[]): {
  invalidInstances: ResourceInstance[];
  resourceInstanceMap: Partial<{[resourceName: string]: ResourceInstance[]}>;
} {
  const resourceInstanceMap: Partial<{
    [resourceName: string]: ResourceInstance[];
  }> = {};
  const invalidInstances: ResourceInstance[] = [];
  for (let i = 0; i < instanceArray.length; i += 1) {
    const instance = instanceArray[i];
    const {url} = instance;
    const resourceName = resourceNameForInstanceURL(url);
    if (resourceName) {
      const resourceInstanceArray = resourceInstanceMap[resourceName];
      if (resourceInstanceArray) {
        resourceInstanceArray.push(instance);
      } else {
        resourceInstanceMap[resourceName] = [instance];
      }
    } else {
      invalidInstances.push(instance);
    }
  }
  return {invalidInstances, resourceInstanceMap};
}

(window as any).REQUIRED_INITIAL_DATA_IS_MISSING = true;
(window as any).CURRENT_INITIAL_DB_WRITES = 0;

export class OfflineDB {
  private activeCalls: number;
  private closeCallbacks: {
    reject(error: unknown): void;
    resolve(): void;
  } | null;
  private closing: boolean;
  private connection: ResourceDBConnection;
  private initialized: boolean;
  private queryIdAssociation: ReadonlyMap<string, QueryIDStruct>;
  private updatePromise: Promise<any> = Promise.resolve();
  constructor(connection: ResourceDBConnection) {
    this.connection = connection;
    this.initialized = false;
    this.queryIdAssociation = new Map();
    this.activeCalls = 0;
    this.closing = false;
    this.closeCallbacks = null;
  }
  _closeConnection(): Promise<void> {
    this.closing = true;
    if (this.activeCalls) {
      return new Promise<void>((resolve, reject) => {
        this.closeCallbacks = {reject, resolve};
      });
    } else {
      return this.connection.close();
    }
  }
  _deleteDatabase(): Promise<void> {
    return this.connection.deleteDatabase();
  }
  readInitialData(): Promise<{
    changesTimestamp: string | null;
    idFetch: Partial<{[resourceName in ResourceName]: readonly string[]}>;
    querySet: Query[];
    queryTimestampsMap: Partial<{
      [keyString in ResourceName]: QueryTimestampsStruct;
    }>;
    relatedFetch: Partial<{
      [resourceName in ResourceName]: Partial<{
        [memberName: string]: readonly string[];
      }>;
    }>;
    resourceInstanceMap: Partial<{
      [resourceName in ResourceName]: ResourceInstance[];
    }>;
  }> {
    return this.trackActive("readInitialData", () => {
      if (process.env.NODE_ENV !== "production") {
        if (console && console.assert) {
          console.assert(!this.initialized);
        }
      }
      this.initialized = true;
      const conn = this.connection;
      const queriesPromise = conn.readQueries().then(makeQueryInstances);
      const dataPromise = conn.readData();
      const queryTimestampsPromise = conn.readQueryTimestamps();
      const idFetchPromise = conn.readIdFetch();
      const relatedFetchPromise = conn.readRelatedFetch();
      const changesTimestampPromise = conn.getChangesTimestamp();
      return Promise.all([
        queriesPromise,
        dataPromise,
        queryTimestampsPromise,
        idFetchPromise,
        relatedFetchPromise,
        changesTimestampPromise,
      ]).then((values) => {
        const [
          idQueryMap,
          instanceArray,
          idTimestampsMap,
          idFetch,
          relatedFetch,
          changesTimestamp,
        ] = values;
        let cleanupPromise: Promise<void>;
        if (Math.random() < VACUUM_FREQUENCY) {
          cleanupPromise = conn.vacuum();
        } else {
          cleanupPromise = Promise.resolve();
        }
        const {invalidQueryIdAssociations, queryIdAssociation} =
          buildQueryIdAssociation(idQueryMap);
        // NOTE: invalidQueryIdAssociations is a (temporary) workaround/fix
        // for problematic data in local DB...
        if (invalidQueryIdAssociations.length) {
          invalidQueryIdAssociations.forEach((invalidQueryIdAssociation) => {
            const invalidQueries = new Set(
              Array.from(invalidQueryIdAssociation.values()).map((entry) => entry.query),
            );
            if (process.env.NODE_ENV !== "production") {
              /* eslint-disable no-console */
              console.log("Deleting (some) redundant Query instances:");
              console.log(invalidQueries);
              /* eslint-enable no-console */
            }
            cleanupPromise = cleanupPromise

              .then(() => this.connection.deleteQueries(invalidQueries, invalidQueryIdAssociation))

              .then((emptyInvalidQueryIdAssociation) => {
                console.assert(!emptyInvalidQueryIdAssociation.size);
                return;
              });
          });
        }
        this.queryIdAssociation = queryIdAssociation;
        const querySet = Array.from(queryIdAssociation.values()).map(
          (entry: QueryIDStruct) => entry.query,
        );
        const queryTimestampsMap = buildQueryTimestampsMap(idQueryMap, idTimestampsMap);
        const {invalidInstances, resourceInstanceMap} = buildResourceInstanceMap(instanceArray);
        if (invalidInstances.length) {
          const dataDelete = new Set(invalidInstances.map((instance) => instance.url));
          if (process.env.NODE_ENV !== "production") {
            /* eslint-disable no-console */
            console.log("Deleting instances for invalid/unknown resource types:");
            console.log(dataDelete);
            /* eslint-enable no-console */
          }

          cleanupPromise = cleanupPromise.then(() => this.connection.deleteData(dataDelete));
        }

        return cleanupPromise.then(() => ({
          changesTimestamp,
          idFetch: Object.fromEntries(
            Array.from(idFetch.entries()).map(([resourceName, ids]) => [
              resourceName,
              Array.from(ids),
            ]),
          ),
          querySet,
          queryTimestampsMap,
          relatedFetch: Object.fromEntries(
            Array.from(relatedFetch.entries()).map(([resourceName, resourceRelations]) => [
              resourceName,
              Object.fromEntries(
                Array.from(resourceRelations.entries()).map(([memberName, ids]) => [
                  memberName,
                  Array.from(ids),
                ]),
              ),
            ]),
          ),
          resourceInstanceMap,
        }));
      });
    });
  }
  setChangesTimestamp(timestamp: string): Promise<void> {
    return this.trackActive("setChangesTimestamp", () => {
      return this.connection.setChangesTimestamp(timestamp);
    });
  }
  update(params: {
    dataDelete?: ReadonlySet<string>;
    dataMerge?: ReadonlyMap<string, ResourceInstance>;
    queryAdd?: ReadonlySet<Query>;
    queryDelete?: ReadonlySet<Query>;
    queryTimestamps?: readonly QueryTimestampsStruct[];
  }): Promise<void> {
    return this.trackActive("update", () => {
      if ((window as any).REQUIRED_INITIAL_DATA_IS_MISSING) {
        (window as any).CURRENT_INITIAL_DB_WRITES += 1;
      }
      this.updatePromise = this.updatePromise
        .then(() => this.realUpdate(params))
        .then((result) => {
          if ((window as any).REQUIRED_INITIAL_DATA_IS_MISSING) {
            (window as any).CURRENT_INITIAL_DB_WRITES -= 1;
          }
          return result;
        });
      return this.updatePromise;
    });
  }
  updateFetchBy(params: {
    idFetchDelete?: ReadonlyMap<ResourceName, ReadonlySet<string>>;
    idFetchMerge?: ReadonlyMap<ResourceName, ReadonlySet<string>>;
    relatedFetchDelete?: ReadonlyMap<ResourceName, ReadonlyMap<string, ReadonlySet<string>>>;
    relatedFetchMerge?: ReadonlyMap<ResourceName, ReadonlyMap<string, ReadonlySet<string>>>;
  }): Promise<void> {
    return this.trackActive("updateFetchBy", () => {
      const {idFetchDelete, idFetchMerge, relatedFetchDelete, relatedFetchMerge} = params;
      let promise: Promise<void> = Promise.resolve();
      if (idFetchMerge && idFetchMerge.size) {
        promise = promise.then(() => this.connection.mergeIdFetch(idFetchMerge));
      }
      if (relatedFetchMerge && relatedFetchMerge.size) {
        promise = promise.then(() => this.connection.mergeRelatedFetch(relatedFetchMerge));
      }
      if (idFetchDelete && idFetchDelete.size) {
        promise = promise.then(() => this.connection.deleteIdFetch(idFetchDelete));
      }
      if (relatedFetchDelete && relatedFetchDelete.size) {
        promise = promise.then(() => this.connection.deleteRelatedFetch(relatedFetchDelete));
      }
      return promise;
    });
  }
  private realUpdate(params: {
    dataDelete?: ReadonlySet<string>;
    dataMerge?: ReadonlyMap<string, ResourceInstance>;
    queryAdd?: ReadonlySet<Query>;
    queryDelete?: ReadonlySet<Query>;
    queryTimestamps?: readonly QueryTimestampsStruct[];
  }): Promise<void> {
    // Constraints:
    // * add before delete --- to avoid data loss on interruption
    // * avoid unreferenced data objects --- to simplify cleanup
    // * updates to relations depend on queries existing (but not on data object existing)

    // Strategy:
    // 1. add queries
    // 2. add/update data objects
    // 3. remove data objects
    // 4. remove queries
    // 5. update timestamps
    const {dataDelete, dataMerge, queryAdd, queryDelete, queryTimestamps} = params;
    let promise: Promise<void> = Promise.resolve();
    if (queryAdd && queryAdd.size) {
      // HACK: Loading from offline DB has as side effect that
      // setPersistedQueries() is dispatch, which has as side effect that
      // DB state is updated -- based on the change in state there; from no
      // queries known, to those stored offline known...
      const queryAddPart = Array.from(queryAdd).filter(
        (query) => !this.queryIdAssociation.has(query.keyString),
      );
      if (process.env.NODE_ENV !== "production") {
        /* eslint-disable no-console */
        if (queryAddPart.length < queryAdd.size) {
          console.warn("Not adding queries already stored in DB:");
          console.warn(
            Array.from(queryAdd).filter((query) => this.queryIdAssociation.has(query.keyString)),
          );
        }
        /* eslint-enable no-console */
      }
      if (queryAddPart.length) {
        promise = promise
          .then(() => this.connection.addQueries(new Set(queryAddPart), this.queryIdAssociation))
          .then(this.replaceQueryIdAssociation.bind(this));
      }
    }
    if (dataMerge && dataMerge.size) {
      promise = promise.then(() => this.connection.mergeData(dataMerge));
    }
    if (dataDelete && dataDelete.size) {
      promise = promise.then(() => this.connection.deleteData(dataDelete));
    }
    if (queryDelete && queryDelete.size) {
      // HACK: To parallel queryAdd logic; not sure that the relevant edge
      // case can currently happen here...
      const queryDeletePart = Array.from(queryDelete).filter((query) =>
        this.queryIdAssociation.has(query.keyString),
      );
      if (process.env.NODE_ENV !== "production") {
        /* eslint-disable no-console */
        if (queryDeletePart.length < queryDelete.size) {
          console.warn("Not deleting queries not present in DB:");
          console.warn(
            Array.from(queryDelete).filter(
              (query) => !this.queryIdAssociation.has(query.keyString),
            ),
          );
        }
        /* eslint-enable no-console */
      }
      if (queryDeletePart.length) {
        promise = promise
          .then(() =>
            this.connection.deleteQueries(new Set(queryDeletePart), this.queryIdAssociation),
          )
          .then(this.replaceQueryIdAssociation.bind(this));
      }
    }
    if (queryTimestamps && queryTimestamps.length) {
      promise = promise.then(() =>
        this.connection.setQueryTimestamps(queryTimestamps, this.queryIdAssociation),
      );
    }
    return promise;
  }
  private replaceQueryIdAssociation(
    newQueryIdAssociation: ReadonlyMap<string, QueryIDStruct>,
  ): void {
    this.queryIdAssociation = newQueryIdAssociation;
  }
  private async trackActive<T>(label: string, callback: () => Promise<T>): Promise<T> {
    if (this.closing) {
      throw new Error(`OfflineDB: closing; cannot perform: ${label}`);
    }
    this.activeCalls += 1;
    let result: Awaited<T>;
    try {
      result = await callback();
    } finally {
      this.activeCalls -= 1;
      if (this.closing && this.activeCalls === 0 && this.closeCallbacks) {
        const {reject, resolve} = this.closeCallbacks;
        // eslint-disable-next-line promise/catch-or-return
        this.connection.close().then(resolve, reject);
      }
    }
    return result;
  }
}

export const getOfflineDB = sharePromiseFactory(() =>
  getDBConnection().then((connection) => new OfflineDB(connection)),
);
