import {SettingID} from "@co-common-libs/config";
import {EntryData, GenericMultiSelectionSearchDialog} from "@co-frontend-libs/components";
import {
  actions,
  getCurrentUserURL,
  getSettingsEntryLookupByIdentifier,
} from "@co-frontend-libs/redux";
import {SyncedResourceHandleKey, syncedResourceHandleKeys} from "integration-constants";
import React, {useCallback, useMemo} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

interface SettingDialogProps {
  onClose: () => void;
  open: boolean;
  settingID: SettingID;
}

export function SelectSyncedResources(props: SettingDialogProps): JSX.Element {
  const {onClose, open, settingID} = props;
  const settingsEntryLookupByIdentifier = useSelector(getSettingsEntryLookupByIdentifier);
  const settingEntry = settingsEntryLookupByIdentifier(settingID);
  const selectedResources: SyncedResourceHandleKey[] = useMemo(
    () => settingEntry?.data || [],
    [settingEntry?.data],
  );

  const intl = useIntl();
  const dispatch = useDispatch();
  const currentUserURL = useSelector(getCurrentUserURL);
  const handleProductDialogOk = useCallback(
    (newlySelectedResources: ReadonlySet<SyncedResourceHandleKey>) => {
      if (settingEntry) {
        dispatch(
          actions.update(settingEntry.url, [
            {member: "changedBy", value: currentUserURL},
            {member: "data", value: [...newlySelectedResources]},
          ]),
        );
      }
      onClose();
    },
    [currentUserURL, dispatch, onClose, settingEntry],
  );

  const translateResource = useCallback(
    // eslint-disable-next-line consistent-return
    (resource: SyncedResourceHandleKey) => {
      switch (resource) {
        case "contact":
          return intl.formatMessage({defaultMessage: "Kontaktpersoner"});
        case "customer":
          return intl.formatMessage({defaultMessage: "Kunder"});
        case "externalWorkType":
          return intl.formatMessage({defaultMessage: "Arbejdsområder - ekstern"});
        case "internalWorkType":
          return intl.formatMessage({defaultMessage: "Arbejdsområder - intern"});
        case "location":
          return intl.formatMessage({defaultMessage: "Steder"});
        case "machine":
          return intl.formatMessage({defaultMessage: "Maskiner"});
        case "priceItem":
          return intl.formatMessage({defaultMessage: "Prislinjer"});
        case "product":
          return intl.formatMessage({defaultMessage: "Produkter"});
        case "productGroup":
          return intl.formatMessage({defaultMessage: "Produkt grupper"});
        case "project":
          return intl.formatMessage({defaultMessage: "Projekter"});
        case "puller":
          return intl.formatMessage({defaultMessage: "Trækkere"});
        case "unit":
          return intl.formatMessage({defaultMessage: "Enheder"});
        case "userProfile":
          return intl.formatMessage({
            defaultMessage: "Medarbejdere (kræver opsætning på medarbejder)",
          });
      }
    },
    [intl],
  );
  const baseChoices: EntryData<SyncedResourceHandleKey>[] = useMemo(() => {
    return syncedResourceHandleKeys.map((resource) => {
      return {
        category: "standard",
        identifier: resource,
        primaryText: translateResource(resource),
        readonly: resource !== "project", // TODO(mr): add translations
        searchFields: [
          {
            label: translateResource(resource),
            priority: 10,
            text: resource,
          },
        ],
      };
    });
  }, [translateResource]);

  return (
    <GenericMultiSelectionSearchDialog<SyncedResourceHandleKey>
      data={baseChoices}
      includeSelectAll={false}
      mobilePrimaryLines={1}
      mobileSearchPrimaryLines={1}
      mobileSearchSecondaryLines={1}
      mobileSecondaryLines={1}
      onCancel={onClose}
      onOk={handleProductDialogOk}
      open={open}
      searchTitle={intl.formatMessage({defaultMessage: "Søg"})}
      selected={new Set(selectedResources)}
      sorting="PRIMARY_TEXT"
      title={intl.formatMessage({defaultMessage: "Vælg resurser der synkroniseres"})}
    />
  );
}
