import {Config} from "@co-common-libs/config";
import {Order, OrderUrl, Role, Task, TaskUrl, UserProfile} from "@co-common-libs/resources";
import {
  actions,
  AppState,
  getCurrentRole,
  getCurrentUserProfile,
  getCurrentUserURL,
  getCustomerSettings,
  getMatchingPathTemplate,
  getOrderLookup,
  getPathName,
  getPathParameters,
  getQueryParameters,
  getTaskArray,
  getTaskLookup,
  PathTemplate,
} from "@co-frontend-libs/redux";
import {
  PartialNavigationKind,
  PathParameters,
  QueryParameters,
} from "@co-frontend-libs/routing-sync-history";
import {PageWithMenu} from "app-components";
import {isLegalToAutoDelete, isOrderEmpty} from "app-utils";
import {instanceURL} from "frontend-global-config";
import React from "react";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";
import {AccountPage} from "./pages/account";
import {Appendices} from "./pages/appendices";
import {Archive} from "./pages/archive";
import {Bookkeeping} from "./pages/bookkeeping";
import {BookkeepingDay} from "./pages/bookkeeping-day";
import BrugerdataSync from "./pages/brugerdata-sync";
import CheckOut from "./pages/check-out";
import ComputedSalaryVoucher from "./pages/computed-salary-voucher";
import {CsvImportAndExportPage} from "./pages/csv-import-export";
import {CultureEntry} from "./pages/culture-entry";
import {CultureList} from "./pages/culture-list";
import {CustomerEntry} from "./pages/customer-entry";
import CustomerInstance from "./pages/customer-instance";
import CustomerList from "./pages/customer-list";
import DeviceSetup from "./pages/device-setup";
import DinnerBookingList from "./pages/dinner-booking-list";
import {EconomicSync} from "./pages/economic-sync";
import {FullScreenAbsenceCalendar} from "./pages/fullscreen-absence-calendar";
import FullscreenGeolocationMap from "./pages/fullscreen-geolocation-map";
import {FullscreenOrderCalendar} from "./pages/fullscreen-order-calendar";
import {FullscreenTaskCalendar} from "./pages/fullscreen-task-calendar";
import GeolocationMapPage from "./pages/geolocation-map-page";
import {InformationEntry} from "./pages/information-entry";
import {InformationListPage} from "./pages/information-list";
import InternalTask from "./pages/internal-task";
import {JournalEntry} from "./pages/journal-entry";
import {LocationStorage} from "./pages/location-storage";
import MachineProfile from "./pages/machine-profile";
import {MinimalFullscreenOrderCalendar} from "./pages/minimal-fullscreen-order-calendar";
import {Mirror} from "./pages/mirror";
import {NavisionSync} from "./pages/navision-sync";
import {OrderEntryPage} from "./pages/order-entry";
import OrderInstance from "./pages/order-instance";
import {OrderList} from "./pages/order-list";
import {PositionList} from "./pages/position-list";
import {getProjectListPageWithDetailsUrl, ProjectPage} from "./pages/project";
import RemunerationReport from "./pages/remuneration-report";
import RemunerationReportList from "./pages/remuneration-report-list";
import {ReportPage} from "./pages/reports";
import SalaryVoucher from "./pages/salary-voucher";
import SalaryVoucherList from "./pages/salary-voucher-list";
import {
  AnniversarySettingsPage,
  CropsSettingsPage,
  EditExternalWorkTypePage,
  EditMachinePage,
  EditPriceGroupPage,
  EditPriceItemPage,
  FavoriteMachinesSettingsPage,
  FavoriteProductsSettingsPage,
  FieldsSettingsPage,
  FoodBookingSettingsPage,
  FuelSurchargesSettingsPage,
  KrPerLiterFuelSurchargeSpecificationEdit,
  LocationsSettingsPage,
  LocationTypeEditPage,
  LocationTypesSettingsPage,
  MachineAnalysisSettingsPage,
  MachinesSettingsPage,
  OverviewSettingsPage,
  PricePercentFuelSurchargeSpecificationEdit,
  ProductGroupsSettingsPage,
  ProductsSettingsPage,
  RoutePlan,
  RoutePlanTask,
  RoutesSettingsPage,
  UnitsSettingsPage,
  WorkshopChecklistsSettingsPage,
  WorkTypeEditPage,
  WorkTypesSettingsPage,
} from "./pages/settings";
import {EmployeeGroupSettingsPage} from "./pages/settings/employee-group-page";
import {SimpleStorageJournal} from "./pages/simple-storage-journal";
import {Sync} from "./pages/sync";
import {SystemSetup} from "./pages/system-setup";
import {ReportingSpecificationEntry} from "./pages/system-setup/reporting/entry";
import {TimerEntry} from "./pages/system-setup/timers/entry";
import TaskCalendar from "./pages/task-calendar";
import TaskDetails from "./pages/task-details";
import {TaskEdit} from "./pages/task-edit";
import TaskInstance from "./pages/task-instance";
import {TaskList} from "./pages/task-list";
import {TasksPrintoutList} from "./pages/tasks-printout";
import {TimeOverview} from "./pages/time-overview";
import UserList from "./pages/user-list";
import {UserProfile as UserProfilePage} from "./pages/user-profile";
import Workshop from "./pages/workshop";
import {WorkTypeStatus} from "./pages/worktype-status";
import {WorkTypeStatusList} from "./pages/worktype-status-list";

function NoMatch(): React.JSX.Element {
  return <div>...</div>;
}

const fullscreenPaths: ReadonlySet<PathTemplate> = new Set([
  "/checkOut",
  "/fullscreenAbsenceCalendar",
  "/fullscreenGeolocationMap",
  "/fullscreenOrderCalendar",
  "/fullscreenTaskCalendar",
  "/minimalFullscreenOrderCalendar",
]);

const pathMapping: {
  [key in PathTemplate]:
    | React.ComponentType<{onMenuButton: (event: React.MouseEvent) => void}>
    | React.ComponentType<undefined>;
} = {
  "/": NoMatch,
  "/account": AccountPage,
  "/appendices": Appendices,
  "/bookkeeping": Bookkeeping,
  "/bookkeepingDay/:date/:machineOperatorId": BookkeepingDay,
  "/brugerdataSync": BrugerdataSync,
  "/checkOut": CheckOut,
  "/computeSalaryVoucher": ComputedSalaryVoucher,
  "/culture": CultureList,
  "/culture/:id": CultureEntry,
  "/customer": CustomerList,
  "/customer/:id": CustomerInstance,
  "/customer/entry": CustomerEntry,
  "/customer/entry/:id": CustomerEntry,
  "/deviceSetup": DeviceSetup,
  "/dinnerBookings": DinnerBookingList,
  "/economicSync": EconomicSync,
  "/files": Archive,
  "/files-task": Archive,
  "/fullscreenAbsenceCalendar": FullScreenAbsenceCalendar,
  "/fullscreenGeolocationMap": FullscreenGeolocationMap,
  "/fullscreenOrderCalendar": FullscreenOrderCalendar,
  "/fullscreenTaskCalendar": FullscreenTaskCalendar,
  "/geolocationMap": GeolocationMapPage,
  "/information": InformationListPage,
  "/information/:id": InformationEntry,
  "/internalTask/:id": InternalTask,
  "/journal/:id": JournalEntry,
  "/locationStorage": LocationStorage,
  "/machine/:id": MachineProfile,
  "/minimalFullscreenOrderCalendar": MinimalFullscreenOrderCalendar,
  "/mirror": Mirror,
  "/navSync": NavisionSync,
  "/order": OrderList,
  "/order/:id": OrderInstance,
  "/order/:id/:taskID": OrderInstance,
  "/orderEntry/:id": OrderEntryPage,
  "/positions/:machineOperatorId": PositionList,
  "/projects": getProjectListPageWithDetailsUrl("/projects/:id"),
  "/projects/:id": ProjectPage,
  "/remunerationReport": RemunerationReportList,
  "/remunerationReport/:id": RemunerationReport,
  "/report": ReportPage,
  "/salaryVoucher": SalaryVoucherList,
  "/salaryVoucher/:id": SalaryVoucher,
  "/settings/anniversary": AnniversarySettingsPage,
  "/settings/crops": CropsSettingsPage,
  "/settings/employeeGroup": EmployeeGroupSettingsPage,
  "/settings/externalWorktype/:id": EditExternalWorkTypePage,
  "/settings/favoriteMachines": FavoriteMachinesSettingsPage,
  "/settings/favoriteProducts": FavoriteProductsSettingsPage,
  "/settings/fields": FieldsSettingsPage,
  "/settings/foodBooking": FoodBookingSettingsPage,
  "/settings/fuelSurcharge": FuelSurchargesSettingsPage,
  "/settings/importAndExport": CsvImportAndExportPage,
  "/settings/krPerLiterFuelSurcharge/:id": KrPerLiterFuelSurchargeSpecificationEdit,
  "/settings/locations": LocationsSettingsPage,
  "/settings/locationType/:id": LocationTypeEditPage,
  "/settings/locationTypes": LocationTypesSettingsPage,
  "/settings/machine/:id": EditMachinePage,
  "/settings/machineAnalysis": MachineAnalysisSettingsPage,
  "/settings/machines": MachinesSettingsPage,
  "/settings/overview": OverviewSettingsPage,
  "/settings/pricegroup/:id": EditPriceGroupPage,
  "/settings/priceitem/:id": EditPriceItemPage,
  "/settings/pricePercentFuelSurcharge/:id": PricePercentFuelSurchargeSpecificationEdit,
  "/settings/productGroups": ProductGroupsSettingsPage,
  "/settings/products": ProductsSettingsPage,
  "/settings/projects": getProjectListPageWithDetailsUrl("/settings/projects/:id"),
  "/settings/projects/:id": ProjectPage,
  "/settings/route/:id": RoutePlan,
  "/settings/route/:planID/:id": RoutePlanTask,
  "/settings/routes": RoutesSettingsPage,
  "/settings/units": UnitsSettingsPage,
  "/settings/workshopChecklists": WorkshopChecklistsSettingsPage,
  "/settings/worktype/:id": WorkTypeEditPage,
  "/settings/workTypes": WorkTypesSettingsPage,
  "/storageJournal/:locationId": SimpleStorageJournal,
  "/sync": Sync,
  "/systemSetup": SystemSetup,
  "/systemSetup/reportingSpecification/:id": ReportingSpecificationEntry,
  "/systemSetup/timer/:id": TimerEntry,
  "/task": TaskList,
  "/task/:id": TaskInstance,
  "/taskCalendar": TaskCalendar,
  "/taskDetails/:id": TaskDetails,
  "/taskEdit/:id": TaskEdit,
  "/tasksPrintout": TasksPrintoutList,
  "/timeOverview": TimeOverview,
  "/user": UserList,
  "/userProfile/:id": UserProfilePage,
  "/workshop": Workshop,
  "/worktypeStatus": WorkTypeStatus,
  "/worktypeStatus/:id/:unit": WorkTypeStatusList,
};

interface SimpleRouterStateProps {
  readonly currentRole: Role | null;
  readonly currentUserProfile: UserProfile | null;
  readonly currentUserURL: string | null;
  readonly customerSettings: Config;
  readonly matchingPathTemplate: PathTemplate | null;
  readonly orderLookup: (url: OrderUrl) => Order | undefined;
  readonly pathname: string;
  readonly pathParameters: PathParameters;
  readonly queryParameters: QueryParameters;
  readonly taskArray: readonly Task[];
  readonly taskLookup: (url: TaskUrl) => Task | undefined;
}

interface SimpleRouterActionProps {
  go: (
    pathTemplate: PathTemplate,
    pathParameters?: PathParameters,
    queryParameters?: QueryParameters,
    navigationKind?: PartialNavigationKind,
  ) => void;
  remove: (url: string) => void;
  startOnlineSaves: () => void;
}

class SimpleRouter extends React.Component<SimpleRouterActionProps & SimpleRouterStateProps> {
  componentDidMount(): void {
    const {currentRole, go, matchingPathTemplate} = this.props;
    if (matchingPathTemplate === null) {
      go("/", {}, {}, "REPLACE");
    } else if (currentRole && currentRole.breakRoom) {
      const startPage = this.props.currentUserProfile?.startPage;
      if (!startPage || startPage === "CHECKOUT") {
        go("/checkOut", {}, {}, "REPLACE");
      } else if (startPage === "MAP") {
        go("/fullscreenGeolocationMap", {}, {}, "REPLACE");
      } else if (startPage === "CALENDAR") {
        go("/fullscreenTaskCalendar", {}, {}, "REPLACE");
      } else if (startPage === "CALENDAR_PAGE_ONE") {
        go("/fullscreenTaskCalendar", {}, {screen: "1"}, "REPLACE");
      } else if (startPage === "CALENDAR_PAGE_TWO") {
        go("/fullscreenTaskCalendar", {}, {screen: "2"}, "REPLACE");
      } else if (startPage === "ORDER_CALENDAR") {
        go("/fullscreenOrderCalendar", {}, {}, "REPLACE");
      } else if (startPage === "MINIMAL_ORDER_CALENDAR") {
        go("/minimalFullscreenOrderCalendar", {}, {}, "REPLACE");
      } else if (startPage === "ABSENCE_CALENDAR") {
        go("/fullscreenAbsenceCalendar", {}, {}, "REPLACE");
      }
    } else if (matchingPathTemplate === "/" || matchingPathTemplate === "/checkOut") {
      this.redirectToDefault();
    }
  }
  componentDidUpdate(prevProps: Readonly<SimpleRouterActionProps & SimpleRouterStateProps>): void {
    const {go, matchingPathTemplate} = this.props;
    if (matchingPathTemplate === null) {
      go("/", {}, {}, "REPLACE");
    } else if (matchingPathTemplate === "/") {
      this.redirectToDefault();
    }
    if (matchingPathTemplate !== prevProps.matchingPathTemplate) {
      if (
        (prevProps.matchingPathTemplate === "/order/:id" ||
          prevProps.matchingPathTemplate === "/orderEntry/:id") &&
        matchingPathTemplate !== "/task/:id" &&
        matchingPathTemplate !== "/taskDetails/:id"
      ) {
        const {id} = prevProps.pathParameters;
        this.deleteEmptyOrder(id);
      } else if (prevProps.matchingPathTemplate === "/internalTask/:id") {
        const {id} = prevProps.pathParameters;
        this.deleteEmptyInternalTask(id);
      }
      window.scrollTo(0, 0);
      this.props.startOnlineSaves();
    }
  }
  deleteEmptyInternalTask(taskId: string): void {
    if (!taskId) {
      return;
    }
    const {currentUserURL: userURL} = this.props;
    if (!userURL) {
      return;
    }
    const taskURL = instanceURL("task", taskId);
    const task = this.props.taskLookup(taskURL);
    if (task && task.createdBy === userURL && isLegalToAutoDelete(task, userURL)) {
      this.props.remove(task.url);
    }
  }
  deleteEmptyOrder(orderId: string): void {
    if (!orderId) {
      return;
    }
    const {currentUserURL, customerSettings} = this.props;
    if (!currentUserURL) {
      return;
    }
    const orderURL = instanceURL("order", orderId);
    const order = this.props.orderLookup(orderURL);
    if (order && order.createdBy === currentUserURL && isOrderEmpty(customerSettings, order)) {
      const orderTasks = this.props.taskArray.filter((task) => task.order === orderURL);
      if (orderTasks.length > 1) {
        return;
      } else if (orderTasks.length === 0) {
        this.props.remove(order.url);
      } else {
        const task = orderTasks[0];
        if (isLegalToAutoDelete(task, currentUserURL)) {
          this.props.remove(task.url);
          this.props.remove(order.url);
        }
      }
    }
  }
  redirectToDefault(): void {
    const {currentRole, go} = this.props;
    if (currentRole && currentRole.breakRoom) {
      go("/checkOut", {}, {}, "REPLACE");
    } else if (currentRole && currentRole.manager) {
      go("/taskCalendar", {}, {}, "REPLACE");
    } else {
      go("/task", {}, {}, "REPLACE");
    }
  }
  render(): React.JSX.Element | null {
    const {matchingPathTemplate, pathname} = this.props;
    if (matchingPathTemplate) {
      const Component = pathMapping[matchingPathTemplate];
      if (fullscreenPaths.has(matchingPathTemplate)) {
        const FullscreenComponent = Component as React.ComponentType;
        return <FullscreenComponent />;
      } else {
        const PageComponent = Component as React.ComponentType<{
          onMenuButton: (event: React.MouseEvent) => void;
        }>;
        return (
          <PageWithMenu
            menu={pathname.startsWith("/settings") ? "settings" : "main"}
            Page={PageComponent}
          />
        );
      }
    }
    // render some "not found" ...
    return <NoMatch />;
  }
}

const wrappedSimpleRouter = connect(
  createStructuredSelector<AppState, SimpleRouterStateProps>({
    currentRole: getCurrentRole,
    currentUserProfile: getCurrentUserProfile,
    currentUserURL: getCurrentUserURL,
    customerSettings: getCustomerSettings,
    matchingPathTemplate: getMatchingPathTemplate,
    orderLookup: getOrderLookup,
    pathname: getPathName,
    pathParameters: getPathParameters,
    queryParameters: getQueryParameters,
    taskArray: getTaskArray,
    taskLookup: getTaskLookup,
  }),
  {
    go: actions.go,
    remove: actions.remove,
    startOnlineSaves: actions.startOnlineSaves,
  },
)(SimpleRouter);

export {wrappedSimpleRouter as SimpleRouter};
