import {ProductGroup, ProductGroupUrl, urlToId} from "@co-common-libs/resources";
import {
  AwaitingBackendRequest,
  IntegerField,
  ResponsiveDialog,
  TrimTextField,
} from "@co-frontend-libs/components";
import {
  actions,
  diffResourceInstanceProperties,
  getCurrentlyFetchingChanges,
  getCustomerSettings,
  getProductGroupArray,
  getWorkTypeArray,
} from "@co-frontend-libs/redux";
import {
  jsonFetch,
  ResponseWithData,
  translateNetworkError,
  useCallWithFalse,
  useCallWithTrue,
  useResettingState,
} from "@co-frontend-libs/utils";
import {
  Button,
  CircularProgress,
  DialogContent,
  FormControlLabel,
  Switch,
  useTheme,
} from "@material-ui/core";
import {productGroupPhotoUrl, productGroupUrl} from "api-endpoint-urls";
import {PhotoUrlField} from "app-components";
import {createProductGroup, useEventTargetCheckedCallback} from "app-utils";
import {ImportAccountDialog} from "feat-import-resources";
import {globalConfig} from "frontend-global-config";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";

const DISPLAY_SPINNER_MIN_MS = 1000;

export const ProductGroupCreateEditDialog = React.memo(
  function ProductGroupCreateEditDialog(props: {
    onCancel: () => void;
    onOk: (productGroupUrl: string) => void;
    open: boolean;
    productGroup?: ProductGroup;
  }): React.JSX.Element {
    const {onOk, open, productGroup} = props;
    const [name, setName] = useResettingState(productGroup?.name || "", open);
    const [active, setActive] = useResettingState(productGroup ? productGroup.active : true, open);
    const [salesAccount, setSalesAccount] = useResettingState<number | null>(
      productGroup?.salesAccount ?? null,
      open,
    );
    const [photoUrl, setPhotoUrl] = useResettingState(productGroup?.photoUrl, open);
    const {baseURL} = globalConfig.resources;
    const [photoFile, setPhotoFile] = useResettingState<File | null>(null, open);
    const [photoFormDataAwaitingUpload, setPhotoFormDataAwaitingUpload] = useResettingState<
      FormData | undefined
    >(undefined, open);
    const [identifier, setIdentifier] = useResettingState(productGroup?.identifier || "", open);
    const [newProductGroupAwaitingCreation, setNewProductGroupAwaitingCreation] = useResettingState<
      ProductGroup | undefined
    >(undefined, open);

    const [saving, setSaving] = useState(false);

    const [accountDialogOpen, setAccountDialogOpen] = useState(false);
    const setAccountDialogOpenTrue = useCallWithTrue(setAccountDialogOpen);
    const setAccountDialogOpenFalse = useCallWithFalse(setAccountDialogOpen);

    const handleIdentifierChange = useCallback(
      (value: number | null) => {
        setIdentifier(value?.toString() || "");
      },
      [setIdentifier],
    );

    const workTypeArray = useSelector(getWorkTypeArray);

    const workTypeWithSameRemote = useMemo(
      () =>
        productGroup?.remoteUrl
          ? workTypeArray.find((w) => w.remoteUrl === productGroup.remoteUrl)
          : null,
      [workTypeArray, productGroup?.remoteUrl],
    );
    const customerSettings = useSelector(getCustomerSettings);

    const productGroupArray = useSelector(getProductGroupArray);

    const productGroupNumberDuplicate: boolean = useMemo(() => {
      if (!identifier) {
        return false;
      } else {
        return productGroupArray.some(
          (group) => group.identifier === identifier && group.url !== productGroup?.url,
        );
      }
    }, [identifier, productGroup?.url, productGroupArray]);

    const workTypeNumberDuplicate: boolean = useMemo(() => {
      if (!customerSettings.economicSync || !identifier) {
        return false;
      } else {
        return workTypeArray.some(
          (workType) =>
            workType.identifier === identifier && workType.remoteUrl !== productGroup?.remoteUrl,
        );
      }
    }, [customerSettings.economicSync, identifier, productGroup?.remoteUrl, workTypeArray]);

    const handleActiveChange = useEventTargetCheckedCallback(setActive, [setActive]);

    const createPhotoFormData = useCallback(
      (productGroupData: ProductGroupUrl) => {
        if (photoFile) {
          const data = new FormData();
          const id = urlToId(productGroupData);
          data.append("productGroupId", id);
          data.append("photoUrl", photoFile);
          return data;
        }
        return undefined;
      },
      [photoFile],
    );

    const [errorMessage, setErrorMessage] = useResettingState<string | null>(null, open);
    const intl = useIntl();

    const dispatch = useDispatch();

    const currentlyFetching = useSelector(getCurrentlyFetchingChanges);
    const savedUrl = useRef<ProductGroupUrl | null>(null);

    const [refreshPendingTimeout, setRefreshPendingTimeout] = useState(false);

    const handleCreateProductGroupSuccess = useCallback(
      (response: ResponseWithData | undefined) => {
        if (response && response.data) {
          dispatch(actions.addToOffline(response.data));
        }
      },
      [dispatch],
    );

    const handleUploadPhotoSuccess = useCallback(
      (response: ResponseWithData | undefined) => {
        if (response && response.data) {
          dispatch(actions.addToOffline(response.data));
        }
      },
      [dispatch],
    );

    const handleProductGroupSaved = useCallback(
      (savedProductGroupUrl: ProductGroupUrl) => {
        if (photoFile) {
          const formdata = createPhotoFormData(savedProductGroupUrl);
          setPhotoFormDataAwaitingUpload(formdata);
        }
        onOk(savedProductGroupUrl);
      },
      [createPhotoFormData, photoFile, setPhotoFormDataAwaitingUpload, onOk],
    );
    useEffect(() => {
      if (!currentlyFetching && !refreshPendingTimeout && saving && savedUrl.current) {
        handleProductGroupSaved(savedUrl.current);
        setSaving(false);
        savedUrl.current = null;
      }
    }, [
      currentlyFetching,
      handleProductGroupSaved,
      onOk,
      productGroup,
      refreshPendingTimeout,
      saving,
    ]);

    const handleOk = useCallback(async () => {
      if (customerSettings.economicSync) {
        let savingError = false;
        if (productGroup) {
          if (
            name !== productGroup.name ||
            active !== productGroup.active ||
            salesAccount !== productGroup.salesAccount ||
            photoUrl !== productGroup.photoUrl
          ) {
            setSaving(true);

            try {
              const url = `${baseURL}economic/productgroup/${urlToId(productGroup.url)}`;
              const response = await jsonFetch(url, "POST", {
                active,
                name,
                photoUrl,
                salesAccount,
              });

              dispatch(actions.addToOffline(response.data));
              savedUrl.current = response.data.url;
            } catch (error) {
              savingError = true;
              setSaving(false);
              setErrorMessage(translateNetworkError(error, intl));
            }
            if (!savingError) {
              setRefreshPendingTimeout(true);
              window.setTimeout(() => {
                setRefreshPendingTimeout(false);
              }, DISPLAY_SPINNER_MIN_MS);
              dispatch(actions.requestChangesFetch());
            } else {
              setSaving(false);
            }
          } else {
            handleProductGroupSaved(productGroup.url);
          }
        } else {
          setSaving(true);

          const url = `${baseURL}economic/productgroup/create`;
          try {
            const response = await jsonFetch(url, "POST", {
              active,
              identifier,
              name,
              salesAccount,
            });
            dispatch(actions.addToOffline(response.data));
            handleProductGroupSaved(response.data.url);
          } catch (error) {
            savingError = true;
            setSaving(false);
            setErrorMessage(translateNetworkError(error, intl));
          }
          setSaving(false);
        }
      } else {
        if (productGroup) {
          const patch = diffResourceInstanceProperties(
            {active, identifier, name, photoUrl, salesAccount},
            productGroup,
          );

          if (patch.length) {
            dispatch(actions.update(productGroup.url, patch));
          }

          handleProductGroupSaved(productGroup.url);
        } else {
          const createdProductGroup = createProductGroup({
            identifier,
            name,
          });

          setNewProductGroupAwaitingCreation(createdProductGroup);
          handleProductGroupSaved(createdProductGroup.url);
        }
      }
    }, [
      active,
      baseURL,
      customerSettings.economicSync,
      dispatch,
      handleProductGroupSaved,
      identifier,
      intl,
      name,
      photoUrl,
      productGroup,
      salesAccount,
      setErrorMessage,
      setNewProductGroupAwaitingCreation,
    ]);

    const theme = useTheme();
    const {economicSync} = customerSettings;

    const duplicateNumberError = productGroupNumberDuplicate
      ? intl.formatMessage({
          defaultMessage: "Der eksisterer allerede en varegruppe med det ID",
        })
      : workTypeNumberDuplicate
        ? intl.formatMessage({
            defaultMessage:
              "Der eksisterer allerede et område (Varegruppe i e-conomic) med det ID. Du kan importere varegruppen istedet.",
          })
        : undefined;

    const handleAccountDialogOk = useCallback(
      (account: string) => {
        setSalesAccount(parseInt(account));
        setAccountDialogOpen(false);
      },
      [setSalesAccount],
    );

    const duplicateNumber = productGroupNumberDuplicate || workTypeNumberDuplicate;

    return (
      <>
        <AwaitingBackendRequest
          apiUrl={productGroupUrl}
          data={newProductGroupAwaitingCreation}
          errorTitle={intl.formatMessage({defaultMessage: "Oprettelse af varegruppe fejlede"})}
          execute={!!newProductGroupAwaitingCreation}
          loadingTitle={intl.formatMessage({defaultMessage: "Opretter varegruppe"})}
          method="POST"
          onSuccess={handleCreateProductGroupSuccess}
        />
        <AwaitingBackendRequest
          apiUrl={productGroupPhotoUrl}
          data={photoFormDataAwaitingUpload}
          errorTitle={intl.formatMessage({defaultMessage: "Upload fejlede"})}
          execute={!!photoFormDataAwaitingUpload}
          loadingTitle={intl.formatMessage({defaultMessage: "Uploader billede"})}
          method="POST"
          onSuccess={handleUploadPhotoSuccess}
        />
        <ResponsiveDialog
          okDisabled={
            !name || !identifier || saving || duplicateNumber || (economicSync && !salesAccount)
          }
          onCancel={props.onCancel}
          onOk={handleOk}
          open={open && !accountDialogOpen}
          title={
            props.productGroup ? (
              <FormattedMessage defaultMessage="Redigér varegruppe" />
            ) : (
              <FormattedMessage defaultMessage="Opret varegruppe" />
            )
          }
        >
          <DialogContent>
            {economicSync ? (
              <>
                {productGroup?.barred ? (
                  <div style={{color: theme.palette.warning.main}}>
                    <FormattedMessage defaultMessage="Varegruppen er blevet slettet i e-conomic" />
                  </div>
                ) : null}
                {workTypeWithSameRemote ? (
                  <FormattedMessage
                    defaultMessage="Hvis du ændre navn her vil det også blive ændret på
                    området {workTypeWithSameRemote}"
                    values={{
                      workTypeWithSameRemote: `${workTypeWithSameRemote.identifier}: ${workTypeWithSameRemote.name}`,
                    }}
                  />
                ) : null}
                <IntegerField
                  autoFocus={!props.productGroup}
                  disabled={saving || !!productGroup?.remoteUrl}
                  error={duplicateNumber}
                  fullWidth
                  helperText={!saving ? duplicateNumberError : null}
                  label={intl.formatMessage({
                    defaultMessage: "ID *",
                  })}
                  margin="dense"
                  onChange={handleIdentifierChange}
                  value={identifier ? parseInt(identifier) : null}
                />
              </>
            ) : (
              <TrimTextField
                autoFocus={!props.productGroup}
                error={productGroupNumberDuplicate}
                fullWidth
                helperText={duplicateNumberError}
                label={intl.formatMessage({
                  defaultMessage: "ID *",
                })}
                margin="dense"
                onChange={setIdentifier}
                value={identifier}
                variant="outlined"
              />
            )}

            <TrimTextField
              autoFocus={!!props.productGroup}
              disabled={saving}
              fullWidth
              label={intl.formatMessage({
                defaultMessage: "Navn *",
              })}
              margin="dense"
              onChange={setName}
              value={name}
              variant="outlined"
            />
            {economicSync && (
              <>
                <Button
                  color="secondary"
                  onClick={setAccountDialogOpenTrue}
                  style={{marginTop: 2}}
                  variant="contained"
                >
                  <FormattedMessage defaultMessage="Vælg konto" />
                </Button>
                <div style={{whiteSpace: "pre-line"}}>{salesAccount}</div>
                <FormControlLabel
                  control={<Switch checked={active} onChange={handleActiveChange} />}
                  disabled={!!productGroup?.barred}
                  label={intl.formatMessage({defaultMessage: "Aktiv"})}
                />
              </>
            )}
            {customerSettings.productImageSelection && (
              <PhotoUrlField
                onChange={setPhotoFile}
                photoUrl={photoUrl}
                updatePhotoUrl={setPhotoUrl}
              />
            )}
            <FormattedMessage defaultMessage="* Skal udfyldes" tagName="div" />
            {errorMessage ? (
              <h3
                style={{
                  color: theme.palette.error.main,
                  whiteSpace: "pre-line",
                }}
              >
                {errorMessage}
              </h3>
            ) : null}
            {saving ? (
              <div style={{textAlign: "center"}}>
                <CircularProgress />
              </div>
            ) : null}
          </DialogContent>
        </ResponsiveDialog>
        <ImportAccountDialog
          onCancel={setAccountDialogOpenFalse}
          onOk={handleAccountDialogOk}
          open={accountDialogOpen}
        />
      </>
    );
  },
);
