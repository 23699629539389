import {
  Location,
  Machine,
  MachineUrl,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  Product,
  ProductGroup,
  ProductGroupUrl,
  ProductUrl,
  Project,
  RouteTask,
  RouteTaskActivityOption,
  RouteTaskActivityOptionUrl,
  RouteTaskResult,
  RouteTaskUrl,
  TaskFile,
  TaskPhoto,
  Timer,
  Unit,
  UnitUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {
  getMachineString,
  getTransportedSumsPerProduct,
  getUnitString,
  priceItemIsManualDistributionTime,
  priceItemIsTime,
  priceItemIsVisible,
} from "@co-common-libs/resources-utils";
import {notNull, notUndefined, setFind} from "@co-common-libs/utils";
import {ExtendedConfig} from "extended-config";
import _ from "lodash";
import {IntlShape} from "react-intl";
import {InlinedTask, InlinedTransportLog} from "../inline-data";
import {mapSum} from "../iterable";
import {computeIntervalSums} from "../task-timers";
import {adjustMinutes} from "../time-helpers";
import {hasSecondaryInvoicedTime} from "../timers";
import {getLogIssues} from "./get-issues/get-log-issues";
import {getMaterialsIssues} from "./get-issues/get-materials-issues";
import {getTransportlogIssues} from "./get-issues/get-transportlog-issues";
import {logLocationMissingRequired} from "./log-location-missing-required";
import {
  ADD_FIELD_ACTION,
  ADD_MACHINE_ACTION,
  ADD_PHOTO_ACTION,
  ADD_PROJECT_ACTION,
  ADD_TIME_ACTION,
  ADD_WORKPLACE_ACTION,
  APPROVE_CUSTOMER_ACTION,
  CHANGE_CUSTOMER_ACTION,
  CHANGE_DEPARTMENT_ACTION,
  CHANGE_EMPLOYEE_ACTION,
  CHANGE_SMALL_MACHINE_ACTION,
  COMPLETE_ROUTE_ACTION,
  EDIT_LOG_ACTION,
  EDIT_MACHINE_ACTION,
  EDIT_NOTE_ACTION,
  EDIT_PROJECT_ACTION,
  ErrorEntry,
  SELECT_WORK_TYPE_ACTION,
} from "./types";

const computeEffectiveMinutes = (
  genericPrimaryTimer: Timer,
  finalSums: ReadonlyMap<string, number>,
): number => {
  const genericTimerURL = genericPrimaryTimer.url;
  const minutesEffective = finalSums.get(genericTimerURL) || 0;
  return minutesEffective;
};

const primaryTimeEffectiveAbsenceAllowed = (task: InlinedTask): boolean => {
  const priceGroups = task.machineuseSet
    .map((mu) => mu.priceGroup)
    .concat([task.priceGroup])
    .filter(notNull);

  /* If worktype doesn't require effective time and
    all pricegroups are not set or explicitly not requiring effective time
    or
    pricegroups are present and all pricegroups explicitly not requiring effective time
    */
  return (
    (task.workType?.requireEffectiveTime === false &&
      priceGroups.every((pg) => !pg.requireEffectiveTime)) ||
    (!!priceGroups.length && priceGroups.every((pg) => pg.requireEffectiveTime === false))
  );
};

export const getErrors = (
  data: {
    genericPrimaryTimer?: Timer | undefined;
    locationArray: readonly Location[];
    machineArray: readonly Machine[];
    priceGroup?: PriceGroup;
    priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
    priceItemArray: readonly PriceItem[];
    priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
    productGroupLookup: (url: ProductGroupUrl) => ProductGroup | undefined;
    productLookup: (url: ProductUrl) => Product | undefined;
    productsWithLogData: Set<ProductUrl> | undefined;
    projectArray: readonly Project[];
    readonlyProducts: Set<ProductUrl>;
    routeTaskActivityOptionLookup?: (
      url: RouteTaskActivityOptionUrl,
    ) => RouteTaskActivityOption | undefined;
    routeTaskArray?: readonly RouteTask[];
    routeTaskLookup?: (url: RouteTaskUrl) => RouteTask | undefined;
    routeTaskResultArray?: readonly RouteTaskResult[];
    secondaryTimers: ReadonlySet<Timer>;
    task: InlinedTask;
    taskFileArray: readonly TaskFile[];
    taskPhotoArray: readonly TaskPhoto[];
    transportLog?: InlinedTransportLog | undefined;
    unitLookup: (url: UnitUrl) => Unit | undefined;
    userIsManager: boolean;
    workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
  },
  customerSettings: ExtendedConfig,
  intl: IntlShape,
  onlyCompletedChecks: boolean, // = false,
  completedAsInternal: boolean, // = false,
): ErrorEntry[] => {
  const {
    adjustBilledMinutes,
    allowMoreThanTwoMachinesForDepartments,
    allowTransportlogAmountMismatch,
    alwaysAllowMoreThanTwoMachines,
    customers,
    enableExternalTaskDepartmentField,
    enableInternalTaskDepartmentField,
    enableProjects,
    errorOnOnlyPuller,
    machineOperatorsCanChooseProject,
    materialIssuesErrors,
    materialUseAlternativeText,
    missingEffectiveTimeIsError,
    noExternalTaskWorkType,
    onlyEnableProjectsForDepartments,
    projectLabelVariant,
    projectMissingCompleteError,
    projectMissingValidateError,
    requireWorkplaceIfExists,
    treatTransportTimeAsEffectiveForDepartments,
  } = customerSettings;
  const {
    genericPrimaryTimer,
    locationArray,
    machineArray,
    priceItemArray,
    priceItemLookup,
    projectArray,
    routeTaskActivityOptionLookup,
    routeTaskArray,
    routeTaskLookup,
    routeTaskResultArray,
    secondaryTimers,
    task,
    taskFileArray,
    taskPhotoArray,
    unitLookup,
    userIsManager,
    workTypeLookup,
  } = data;
  console.assert(secondaryTimers instanceof Set, "provided secondaryTimers not a set");
  console.assert(secondaryTimers instanceof Set, JSON.stringify(secondaryTimers));
  const {department, machineOperator, order, relatedWorkplace, workType} = task;

  const cancelled = !!task.cancelled;
  const customer = order ? order.customer : undefined;
  const customerURL = customer ? customer.url : undefined;
  const culture = order ? order.culture : undefined;
  const enableTaskDepartmentField = order
    ? enableExternalTaskDepartmentField
    : enableInternalTaskDepartmentField;
  const machineUseList = task.machineuseSet;

  const taskPriceGroup = task.priceGroup;
  const finalIntervals = task._intervals;
  const errors: ErrorEntry[] = [];

  if (
    requireWorkplaceIfExists &&
    customer &&
    !relatedWorkplace &&
    locationArray.some(
      (l) => l.active && l.customer === customerURL && !l.logOnlyLocation && !l.geojson,
    )
  ) {
    errors.push({
      action: ADD_WORKPLACE_ACTION,
      text: intl.formatMessage({defaultMessage: "Arbejdssted mangler"}),
    });
  }

  if (enableTaskDepartmentField) {
    if (!department && !(customerSettings.onlyAdminCanChangeDepartment && onlyCompletedChecks)) {
      errors.push({
        action: CHANGE_DEPARTMENT_ACTION,
        text: intl.formatMessage({defaultMessage: "Afdeling er ikke valgt."}),
      });
    }
  }
  if (
    !task.notesFromMachineOperator &&
    ((workType &&
      (taskPriceGroup?.requireNotesFromMachineOperator ??
        workType.requireNotesFromMachineOperator)) ||
      task.machineuseSet
        .map((mu) => mu.priceGroup)
        .filter(notNull)
        .some((pg) => pg.requireNotesFromMachineOperator))
  ) {
    errors.push({
      action: EDIT_NOTE_ACTION,
      text: intl.formatMessage({
        defaultMessage: "Notefeltet skal udfyldes på opgaven.",
      }),
    });
  }

  if (
    enableProjects &&
    customer &&
    (!onlyEnableProjectsForDepartments.length ||
      onlyEnableProjectsForDepartments.includes(task.department)) &&
    ((projectMissingCompleteError && (machineOperatorsCanChooseProject || userIsManager)) ||
      (projectMissingValidateError && task.completed))
  ) {
    if (
      !task.project &&
      projectArray.some((p) => p.customer === customerURL && p.access === "open")
    ) {
      errors.push({
        action: ADD_PROJECT_ACTION,
        text:
          projectLabelVariant === "PROJECT"
            ? intl.formatMessage({defaultMessage: "Projekt mangler"})
            : intl.formatMessage({defaultMessage: "Sag mangler"}),
      });
    }

    const project = projectArray.find((p) => p.url === task.project?.url);
    if (project && (project.access === "barred" || project.access === "closed")) {
      errors.push({
        action: EDIT_PROJECT_ACTION,
        text:
          projectLabelVariant === "PROJECT"
            ? intl.formatMessage({defaultMessage: "Opgavens projekt er lukket eller spærret"})
            : intl.formatMessage({defaultMessage: "Opgavens sag er lukket eller spærret"}),
      });
    }
  }

  const allowMoreThanTwoMachinesForDepartment =
    enableTaskDepartmentField &&
    department &&
    allowMoreThanTwoMachinesForDepartments.includes(department);
  const allowMoreThanTwoMachines =
    alwaysAllowMoreThanTwoMachines || allowMoreThanTwoMachinesForDepartment;
  if (allowMoreThanTwoMachines) {
    // FIXME: optimise: check whether computation is required *before*
    // rather than *after* performing it?
    let priceItemTimeCount = 0;
    let priceItemTimeTotalMinutes = 0;
    (task.priceitemuseSet || []).forEach((priceItemUse) => {
      if (
        priceItemUse.priceItem &&
        priceItemIsManualDistributionTime(unitLookup, priceItemUse.priceItem)
      ) {
        priceItemTimeCount += 1;
        priceItemTimeTotalMinutes += priceItemUse.count || 0;
      }
    });
    if (priceItemTimeCount > 1) {
      const now = new Date();
      const finalSums = computeIntervalSums(finalIntervals, now);
      const minutesEffective = genericPrimaryTimer
        ? computeEffectiveMinutes(genericPrimaryTimer, finalSums)
        : 0;
      if (minutesEffective !== priceItemTimeTotalMinutes) {
        errors.push(
          customerSettings.machineLabelVariant === "MACHINE"
            ? intl.formatMessage({
                defaultMessage:
                  "Den totale effektive tid fordelt over maskiner/arbejdsområder er forskellig fra den totale effektive tid på opgaven.",
              })
            : intl.formatMessage({
                defaultMessage:
                  "Den totale effektive tid fordelt over køretøjer/arbejdsområder er forskellig fra den totale effektive tid på opgaven.",
              }),
        );
      }
      if (treatTransportTimeAsEffectiveForDepartments.includes(department)) {
        const transportTimer = setFind(
          secondaryTimers,
          (t) => t.label.toLowerCase() === "transport",
        );
        const transportMinutes = transportTimer ? finalSums.get(transportTimer.url) || 0 : 0;
        if (transportMinutes && !machineUseList.some((u) => u.transporter)) {
          errors.push(
            customerSettings.machineLabelVariant === "MACHINE"
              ? intl.formatMessage({
                  defaultMessage: "Maskine for transport mangler for fordelingstabel",
                })
              : intl.formatMessage({
                  defaultMessage: "Køretøj for transport mangler for fordelingstabel",
                }),
          );
        }
      }
    }
  } else if (
    !enableTaskDepartmentField ||
    (task.department && !customerSettings.onlyAdminCanChangeDepartment)
  ) {
    const effectiveTimerTargetPriceItemURLList = task.priceitemuseSet
      .map((entry) => entry.priceItem)
      .filter(notNull)
      .filter(notUndefined)
      .filter((priceItem) => priceItem.genericEffectiveTimerTarget)
      .map((priceItem) => priceItem.url);

    const priceGroupList = task.machineuseSet.map((entry) => entry.priceGroup).filter(notNull);
    if (task.priceGroup) {
      priceGroupList.push(task.priceGroup);
    }

    const effetiveTimerTargetPriceGroupURLSet = new Set<string>();
    priceGroupList.forEach((priceGroup) => {
      priceGroup.priceGroupItemSet.forEach((entry) => {
        if (effectiveTimerTargetPriceItemURLList.includes(entry.priceItem)) {
          effetiveTimerTargetPriceGroupURLSet.add(priceGroup.url);
        }
      });
    });

    if (effetiveTimerTargetPriceGroupURLSet.size > 1) {
      errors.push(
        customerSettings.machineLabelVariant === "MACHINE"
          ? intl.formatMessage({
              defaultMessage:
                "Den effektive tid kan ikke fordeles over flere køretøjer/arbejdsområder.",
            })
          : intl.formatMessage({
              defaultMessage:
                "Den effektive tid kan ikke fordeles over flere køretøjer/arbejdsområder.",
            }),
      );
    }
  }

  const now = new Date();
  const finalSums = computeIntervalSums(finalIntervals, now);

  const {timernotesSet} = task;
  if (onlyCompletedChecks) {
    secondaryTimers.forEach((timer) => {
      if (timer.requiresNotes && finalSums.get(timer.url) && timernotesSet) {
        const timerNotes = timernotesSet.find((entry) => entry.timer === timer.url);
        if (!timerNotes || !timerNotes.notes) {
          errors.push({
            action: ADD_TIME_ACTION,
            text: intl.formatMessage(
              {defaultMessage: "Der mangler en note til timeren: {timer} "},
              {
                timer: timer.label,
              },
            ),
          });
        }
      }
    });
  }

  const minutesTotal = mapSum(finalSums);
  if (!minutesTotal) {
    errors.push({
      action: ADD_TIME_ACTION,
      text: intl.formatMessage({
        defaultMessage: "Der er ingen tid registreret.",
      }),
    });
  }
  if (
    !cancelled &&
    order &&
    missingEffectiveTimeIsError &&
    !completedAsInternal &&
    !task.continuationTask &&
    !task.completedAsInternal
  ) {
    const minutesPrimary = genericPrimaryTimer
      ? computeEffectiveMinutes(genericPrimaryTimer, finalSums)
      : 0;

    const hasInvoicedTime = hasSecondaryInvoicedTime(
      secondaryTimers,
      finalSums,
      workTypeLookup,
      customerSettings.timersContributingToEffectiveTime,
      priceItemLookup,
      task.priceitemuseSet.map((use) => {
        console.assert(use.priceItem);
        return {
          priceItem: use.priceItem?.url as PriceItemUrl,
          timer: use.timer,
        };
      }),
    );
    if (!hasInvoicedTime && !primaryTimeEffectiveAbsenceAllowed(task)) {
      const transportTimer = setFind(secondaryTimers, (t) => t.label.toLowerCase() === "transport");
      const transportMinutes = transportTimer ? finalSums.get(transportTimer.url) || 0 : 0;
      const adjustedMinutes = adjustMinutes(adjustBilledMinutes, minutesPrimary);

      if (
        minutesPrimary === 0 &&
        (!department ||
          !treatTransportTimeAsEffectiveForDepartments.includes(department) ||
          !transportMinutes)
      ) {
        errors.push({
          action: ADD_TIME_ACTION,
          text: intl.formatMessage({
            defaultMessage: "Der er ikke registreret effektiv tid.",
          }),
        });
      } else if (
        adjustedMinutes === 0 &&
        (!department ||
          !treatTransportTimeAsEffectiveForDepartments.includes(department) ||
          !transportMinutes)
      ) {
        errors.push(
          intl.formatMessage({
            defaultMessage: "Den registrerede effektive tid er under nedrundingsgrænsen.",
          }),
        );
      }
    }
  }
  if (cancelled) {
    const minutesPrimary = genericPrimaryTimer
      ? computeEffectiveMinutes(genericPrimaryTimer, finalSums)
      : 0;
    if (minutesPrimary) {
      errors.push(
        intl.formatMessage({
          defaultMessage: "Opgaven kan ikke afsluttes som aflyst da den har effektiv tid.",
        }),
      );
    }
  }
  if (
    order &&
    !customer &&
    !order.routePlan &&
    customerSettings.externalTaskCustomer &&
    !customerSettings.externalTaskCulture
  ) {
    errors.push({
      action: CHANGE_CUSTOMER_ACTION,
      text: intl.formatMessage({defaultMessage: "Kunde mangler."}),
    });
  }
  if (task.completed && customer && customer.c5_account && customer.disallowTaskValidation) {
    errors.push({
      action: CHANGE_CUSTOMER_ACTION,
      text: intl.formatMessage(
        {
          defaultMessage:
            "Kunden {customerName} — med kontonr. {customerAccount} — skal ændres til en reel kunde før opgaven kan godkendes.",
        },
        {
          customerAccount: customer.c5_account,
          customerName: customer.name,
        },
      ),
    });
  }
  if (
    order &&
    !culture &&
    customerSettings.externalTaskCulture &&
    !customerSettings.externalTaskCustomer
  ) {
    errors.push(intl.formatMessage({defaultMessage: "Kunde eller kultur mangler."}));
  }
  if (
    order &&
    !customer &&
    !culture &&
    !order.routePlan &&
    customerSettings.externalTaskCustomer &&
    customerSettings.externalTaskCulture
  ) {
    errors.push(intl.formatMessage({defaultMessage: "Kunde eller kultur mangler."}));
  }
  if (culture && customerSettings.requireFieldForCultureTasks) {
    const {fielduseSet} = task;
    if (!fielduseSet || !fielduseSet.length) {
      errors.push(intl.formatMessage({defaultMessage: "Mark mangler"}));
    }
  }
  if (!machineOperator) {
    const errorText =
      customerSettings.employeeLabelVariant === "MACHINEOPERATOR"
        ? intl.formatMessage({defaultMessage: "Maskinfører mangler."})
        : customerSettings.employeeLabelVariant === "EMPLOYEE"
          ? intl.formatMessage({defaultMessage: "Medarbejder mangler."})
          : intl.formatMessage({defaultMessage: "Chauffør mangler."});
    if (customerSettings.allowMachineOperatorChangeOnTaskInfoPage) {
      errors.push({
        action: CHANGE_EMPLOYEE_ACTION,
        text: errorText,
      });
    } else {
      errors.push(errorText);
    }
  }
  if (!noExternalTaskWorkType && !workType) {
    errors.push({
      action: SELECT_WORK_TYPE_ACTION,
      text: intl.formatMessage({
        defaultMessage: "Der er ikke angivet arbejdsområde.",
      }),
    });
  }
  if (workType && workType.requireWorkplace && !task.relatedWorkplace) {
    errors.push({
      action: ADD_WORKPLACE_ACTION,
      text: intl.formatMessage({defaultMessage: "Arbejdssted mangler"}),
    });
  }
  if (workType && workType.requireField && !task.fielduseSet.length) {
    errors.push({
      action: ADD_FIELD_ACTION,
      text: intl.formatMessage({defaultMessage: "Mark mangler"}),
    });
  }
  let hasPriceGroup = true;
  if (
    !customerSettings.externalTaskWithoutTaskWorkTypeVariantLegal &&
    !onlyCompletedChecks &&
    !customerSettings.noExternalTaskWorkType &&
    workType &&
    order &&
    !order.routePlan &&
    !task.priceGroup
  ) {
    errors.push(
      intl.formatMessage({
        defaultMessage: "Opgaven mangler variant - kontakt administrationen.",
      }),
    );
    hasPriceGroup = false;
  }
  if (
    !onlyCompletedChecks &&
    customerSettings.noExternalTaskWorkType &&
    order &&
    !order.routePlan
  ) {
    if (!machineUseList.some((mu) => mu.priceGroup)) {
      if (!task.completedAsInternal) {
        errors.push(
          intl.formatMessage({
            defaultMessage: "Opgaven mangler variant - kontakt administrationen.",
          }),
        );
      }
      hasPriceGroup = false;
    } else if (
      machineUseList.some((mu) => !mu.priceGroup && _.get(mu, ["machine", "pricegroups"])?.length)
    ) {
      errors.push(
        customerSettings.machineLabelVariant === "MACHINE"
          ? intl.formatMessage({defaultMessage: "Maskine mangler variant."})
          : intl.formatMessage({defaultMessage: "Køretøj mangler variant."}),
      );
    }
  }
  if (
    !customerSettings.externalTaskWithoutPriceLinesLegal &&
    hasPriceGroup &&
    !onlyCompletedChecks &&
    !task.completedAsInternal &&
    workType &&
    order &&
    !order.routePlan &&
    !task.priceitemuseSet.length &&
    taskPriceGroup &&
    taskPriceGroup.requirePriceLines
  ) {
    errors.push(
      intl.formatMessage({
        defaultMessage: "Opgaven mangler bagvedliggende prislinier fra økonimisystemet.",
      }),
    );
  }

  const allFavoriteMachines: ReadonlySet<MachineUrl> = new Set<MachineUrl>(
    (task.workType?.machines || []).concat(task.priceGroup?.machines || []),
  );
  if (
    task.order &&
    task.completed &&
    customerSettings.requireFavoriteMachines &&
    allFavoriteMachines.size
  ) {
    const illegalMachines: Machine[] = [];
    task.machineuseSet.forEach((machineUse) => {
      if (machineUse.machine?.url && !allFavoriteMachines.has(machineUse.machine.url)) {
        illegalMachines.push(machineUse.machine);
      }
    });
    if (illegalMachines.length) {
      errors.push({
        text: intl.formatMessage(
          {
            defaultMessage: "Følgende maskine(r) må ikke anvendes på området: {machines}",
          },
          {
            machines: illegalMachines.map((machine) => getMachineString(machine)).join(", "),
          },
        ),
      });
    }
  }
  if (
    order &&
    !machineUseList.some((machineUse) => !machineUse.machine?.smallMachine) &&
    (!workType ||
      (!workType.disallowMachineUse &&
        (taskPriceGroup?.requireMachineUse != null
          ? taskPriceGroup.requireMachineUse
          : workType.requireMachineUse !== false)))
  ) {
    errors.push({
      action: ADD_MACHINE_ACTION,
      text:
        customerSettings.machineLabelVariant === "MACHINE"
          ? intl.formatMessage({defaultMessage: "Der er ikke angivet maskine."})
          : intl.formatMessage({
              defaultMessage: "Der er ikke angivet køretøj.",
            }),
    });
  }
  const twoMachines = 2;
  if (order && machineUseList.length > twoMachines && !allowMoreThanTwoMachines) {
    errors.push(
      customerSettings.machineLabelVariant === "MACHINE"
        ? intl.formatMessage({
            defaultMessage: "Du har angivet mere end 2 maskiner.",
          })
        : intl.formatMessage({
            defaultMessage: "Du har angivet mere end 2 køretøjer.",
          }),
    );
  }
  const machineList = machineUseList.map((mu) => mu.machine).filter(notNull);
  // !allowMoreThanTwoMachinesForDepartments.includes(department) below is just
  // to support VAMA and their weird rules. Find a better way around this
  // later.
  if (
    order &&
    machineUseList.length &&
    !allowMoreThanTwoMachinesForDepartment &&
    machineList.some((machine) => !machine.selfPropelled) &&
    !machineList.some((machine) => machine.canPull)
  ) {
    errors.push({
      action: ADD_MACHINE_ACTION,
      text:
        customerSettings.machineLabelVariant === "MACHINE"
          ? intl.formatMessage({
              defaultMessage:
                "Du har angivet en maskine uden trækker, men maskinen er ikke selvkørende.",
            })
          : intl.formatMessage({
              defaultMessage:
                "Du har angivet et køretøj uden trækker, men køretøjet er ikke selvkørende.",
            }),
    });
  }
  if (
    order &&
    !alwaysAllowMoreThanTwoMachines &&
    !allowMoreThanTwoMachines &&
    machineList.length === twoMachines
  ) {
    const [machineA, machineB] = machineList;
    // disregard the "anything goes"-departments; and other numbers of machines than 2 is either OK or caught somewhere else
    // pullers canPull set; whether they have selfPropelled set varies...
    const selfPropelledA = machineA.selfPropelled;
    const selfPropelledB = machineB.selfPropelled;
    const canPullA = machineA.canPull;
    const canPullB = machineB.canPull;
    if (canPullA && canPullB) {
      errors.push(intl.formatMessage({defaultMessage: "Der er angivet to trækkere"}));
    } else if (selfPropelledA && selfPropelledB) {
      errors.push(
        customerSettings.machineLabelVariant === "MACHINE"
          ? intl.formatMessage({
              defaultMessage: "Der er angivet to selvkørende køretøjer",
            })
          : intl.formatMessage({
              defaultMessage: "Der er angivet to selvkørende køretøjer",
            }),
      );
    } else if ((canPullA && selfPropelledB) || (canPullB && selfPropelledA)) {
      errors.push(
        customerSettings.machineLabelVariant === "MACHINE"
          ? intl.formatMessage({
              defaultMessage: "Der er angivet en trækker, men maskinen er selvkørende",
            })
          : intl.formatMessage({
              defaultMessage: "Der er angivet en trækker, men køretøjet er selvkørende",
            }),
      );
    }
  }
  if (
    order &&
    errorOnOnlyPuller &&
    machineList.length &&
    machineList.every((machine) => machine.canPull && !machine.selfPropelled)
  ) {
    errors.push({
      action: ADD_MACHINE_ACTION,
      text: intl.formatMessage({defaultMessage: "Der er kun angivet trækkere"}),
    });
  }
  const requireAtLeastOneOptionalPriceItemUseGreaterThanZero =
    workType?.requireAtLeastOneOptionalPriceItemUseGreaterThanZero;
  const requireAtLeastOneOptionalPriceItemUseGreaterThanZeroItemsByGroup = new Map<
    PriceGroupUrl,
    readonly PriceItemUrl[]
  >();
  if (!cancelled) {
    machineUseList
      .map((mu) => mu.priceGroup)
      .concat([taskPriceGroup])
      .filter(notNull)
      .filter((pg) => pg.requireAtLeastOneOptionalPriceItemUseGreaterThanZero)
      .forEach((priceGroup) => {
        const {url} = priceGroup;
        requireAtLeastOneOptionalPriceItemUseGreaterThanZeroItemsByGroup.set(
          url,
          priceItemArray
            .filter(
              (priceItem) =>
                priceItem.required === false &&
                !priceItem.requiredGreaterThanZero &&
                !priceItem.genericEffectiveTimerTarget &&
                priceItem.priceGroups &&
                priceItem.priceGroups.includes(url),
            )
            .map((p) => p.url),
        );
      });

    if (
      materialIssuesErrors &&
      !completedAsInternal &&
      !task.continuationTask &&
      !task.completedAsInternal
    ) {
      const materialError = getMaterialsIssues(
        data,
        customerSettings,
        intl,
        undefined,
        undefined,
        requireAtLeastOneOptionalPriceItemUseGreaterThanZero,
        requireAtLeastOneOptionalPriceItemUseGreaterThanZeroItemsByGroup,
      );
      if (materialError) {
        errors.push(materialError);
      }
    } else if (
      customerSettings.materialZeroCausesError &&
      customerSettings.materialZeroCausesError.length &&
      !completedAsInternal &&
      !task.continuationTask &&
      !task.completedAsInternal
    ) {
      const materialError = getMaterialsIssues(
        data,
        customerSettings,
        intl,
        new Set(customerSettings.materialZeroCausesError),
        undefined,
        requireAtLeastOneOptionalPriceItemUseGreaterThanZero,
        requireAtLeastOneOptionalPriceItemUseGreaterThanZeroItemsByGroup,
      );
      if (materialError) {
        errors.push(materialError);
      }
    }
  }
  if (!allowTransportlogAmountMismatch) {
    const transportLogError = data.transportLog
      ? getTransportlogIssues(data.transportLog, intl)
      : null;
    if (transportLogError) {
      errors.push(transportLogError);
    }
  }
  if (order) {
    const danglingErrors: string[] = [];
    const danglingDistributionErrors: string[] = [];
    const priceItemUseList = task.priceitemuseSet;
    priceItemUseList.forEach((instance) => {
      const {count} = instance;
      if (!count) {
        return;
      }
      const {priceItem} = instance;
      if (!priceItem) {
        return;
      }
      if (!instance.dangling && priceItem.active) {
        return;
      }
      if (!priceItemIsVisible(priceItem, !onlyCompletedChecks, priceItemUseList, unitLookup)) {
        if (priceItemIsTime(unitLookup, priceItem)) {
          danglingDistributionErrors.push(priceItem.name);
        }
        return;
      }
      const {name} = priceItem;
      const unit = getUnitString(priceItem, unitLookup);
      const error = `${name}: ${count} ${unit}`;
      danglingErrors.push(error);
    });
    if (danglingErrors.length) {
      const danglingErrorsHeader = materialUseAlternativeText
        ? intl.formatMessage({
            defaultMessage: "Materialer ugyldige efter skift af variant:",
          })
        : intl.formatMessage({
            defaultMessage: "Materiel ugyldigt efter skift af variant:",
          });

      danglingErrors.forEach((error) => {
        errors.push(`${danglingErrorsHeader} ${error}`);
      });
    }
    if (danglingDistributionErrors.length) {
      const danglingDistributionErrorsHeader =
        customerSettings.machineLabelVariant === "MACHINE"
          ? intl.formatMessage({
              defaultMessage: "Punkter i fordelingstabel ugyldige efter skift af maskiner:",
            })
          : intl.formatMessage({
              defaultMessage: "Punkter i fordelingstabel ugyldige efter skift af køretøjer:",
            });

      danglingDistributionErrors.forEach((error) => {
        errors.push(`${danglingDistributionErrorsHeader} ${error}`);
      });
    }
  }
  if (
    order &&
    !cancelled &&
    !order.routePlan &&
    customerSettings.enableSmallMachines &&
    workType?.enableSmallMachines !== false &&
    machineArray.some((machine) => machine.active && machine.smallMachine) &&
    !task.withoutSmallMachines
  ) {
    const hasSmallMachine =
      !!machineUseList &&
      machineUseList.some((machineUse) => {
        const {machine} = machineUse;
        if (!machine) {
          return false;
        }
        return machine.smallMachine;
      });
    if (!hasSmallMachine) {
      errors.push({
        action: CHANGE_SMALL_MACHINE_ACTION,
        text: intl.formatMessage({
          defaultMessage: "Småmaskiner mangler at blive udfyldt.",
        }),
      });
    }
  }
  if (routeTaskArray && order && order.routePlan) {
    const taskURL = task.url;
    const someCompleted = routeTaskArray.some(
      (routeTask) => routeTask.route === taskURL && !!routeTask.completed,
    );
    if (!someCompleted) {
      errors.push({
        action: COMPLETE_ROUTE_ACTION,
        text: intl.formatMessage({
          defaultMessage: "Ingen af rutens opgaver er udført",
        }),
      });
    }
    if (
      routeTaskArray.some(
        (routeTask) => routeTask.route === taskURL && routeTask.started && !routeTask.completed,
      )
    ) {
      errors.push({
        action: COMPLETE_ROUTE_ACTION,
        text: intl.formatMessage({
          defaultMessage: "Du har startet et rutepunkt men ikke udført det endnu",
        }),
      });
    }
  }
  if (
    (workType && workType.requirePhotoOnTaskCompletion) ||
    (machineUseList.length &&
      machineUseList.some((u) => u.machine && u.machine.requirePhotoOnTaskCompletion)) ||
    (workType && taskPriceGroup && taskPriceGroup.requirePhotoOnTaskCompletion) ||
    task.machineuseSet.some((machineUse) => machineUse.priceGroup?.requirePhotoOnTaskCompletion)
  ) {
    const taskURL = task.url;
    const photoOnTask = taskPhotoArray.some((instance) => instance.task === taskURL);
    if (!photoOnTask) {
      errors.push({
        action: ADD_PHOTO_ACTION,
        text: intl.formatMessage({
          defaultMessage: "Der mangler foto på opgaven.",
        }),
      });
    }
  }
  if (
    (workType && workType.requireAttachment) ||
    (workType && taskPriceGroup && taskPriceGroup.requireAttachment) ||
    task.machineuseSet.some((machineUse) => machineUse.priceGroup?.requireAttachment)
  ) {
    const taskURL = task.url;
    const photoOnTask = taskPhotoArray.some((instance) => instance.task === taskURL);
    const fileOnTask = taskFileArray.some((instance) => instance.task === taskURL);
    if (!photoOnTask && !fileOnTask) {
      errors.push({
        action: ADD_PHOTO_ACTION,
        text: intl.formatMessage({
          defaultMessage: "Der mangler foto eller PDF på opgaven.",
        }),
      });
    }
  }
  if (workType && workType.allowMaxOneMachine && machineUseList.length > 1) {
    errors.push({
      action: EDIT_MACHINE_ACTION,
      text: intl.formatMessage({
        defaultMessage: "Der må kun være én maskine på opgaven",
      }),
    });
  }
  if (
    !onlyCompletedChecks &&
    routeTaskArray &&
    routeTaskLookup &&
    routeTaskResultArray &&
    order &&
    order.routePlan
  ) {
    const taskURL = task.url;
    const routeTaskURLSet = new Set(
      routeTaskArray
        .filter((routeTask) => routeTask.route === taskURL && routeTask.completed)
        .map((routeTask) => routeTask.url),
    );
    const someMissingResults = routeTaskResultArray.some((routeTaskResult) => {
      if (!routeTaskURLSet.has(routeTaskResult.routeTask)) {
        return false;
      }
      const activityOption =
        routeTaskActivityOptionLookup &&
        routeTaskActivityOptionLookup(routeTaskResult.activityOption);
      const routeTask = routeTaskLookup(routeTaskResult.routeTask);
      return !!(
        activityOption &&
        routeTask &&
        activityOption.activity === routeTask.activity &&
        routeTaskResult.quantity == null &&
        routeTaskResult.corrected == null
      );
    });
    if (someMissingResults) {
      errors.push(intl.formatMessage({defaultMessage: "Resultater mangler at udfyldes"}));
    }
  }

  if (!task.logSkipped && task.reportingSpecification && !cancelled && !completedAsInternal) {
    const logSpecification = task.reportingSpecification;
    if (
      logSpecification.taskData.inputs &&
      logSpecification.taskData.inputs.some(
        ({identifier, required}) => required && task.reportingData[identifier] === undefined,
      )
    ) {
      errors.push({
        action: EDIT_LOG_ACTION,
        text: intl.formatMessage({
          defaultMessage: "Påkrævet stamdata for loggen mangler at blive udfyldt.",
        }),
      });
    }

    if (!onlyCompletedChecks) {
      const {workplaceData} = task.reportingSpecification;
      const locationMissingRequired = Object.values(task.reportingLocations).some(
        logLocationMissingRequired.bind(null, workplaceData),
      );
      if (locationMissingRequired) {
        errors.push({
          action: EDIT_LOG_ACTION,
          text: intl.formatMessage({
            defaultMessage: "Påkrævede felter for logsted mangler at blive udfyldt.",
          }),
        });
      }
    }

    const logErrors = getLogIssues(
      data,
      customerSettings,
      intl,
      requireAtLeastOneOptionalPriceItemUseGreaterThanZero,
      requireAtLeastOneOptionalPriceItemUseGreaterThanZeroItemsByGroup,
    );

    if (logErrors) {
      errors.push(logErrors);
    } else if (
      (logSpecification.requireAtLeastOneLogEntry ||
        (logSpecification.allowSkip && !task.logSkipped)) &&
      _.isEmpty(task.reportingLog)
    ) {
      errors.push({
        action: EDIT_LOG_ACTION,
        text: intl.formatMessage({
          defaultMessage: "Loggen mangler at blive udfyldt.",
        }),
      });
    }

    if (logSpecification.allowTransportedMaterialCountMismatch === false) {
      let transportMismatch = false;
      const {deliveryCounts, pickupCounts} = getTransportedSumsPerProduct(
        task.reportingLog || {},
        priceItemLookup,
      );

      pickupCounts.forEach((count, item) => {
        const deliveryCount = deliveryCounts.get(item) || 0;
        if (
          _.round(deliveryCount, customerSettings.materialDecimals) !==
          _.round(count, customerSettings.materialDecimals)
        ) {
          transportMismatch = true;
        }
      });
      deliveryCounts.forEach((count, item) => {
        const pickupCount = pickupCounts.get(item) || 0;
        if (
          _.round(pickupCount, customerSettings.materialDecimals) !==
          _.round(count, customerSettings.materialDecimals)
        ) {
          transportMismatch = true;
        }
      });
      if (transportMismatch) {
        errors.push(
          intl.formatMessage({
            defaultMessage: "Der er forskel på den afhentede og leverede mængde.",
          }),
        );
      }
    }
  }

  if (
    customerSettings.customerApprovalGate === "TASK_APPROVE" &&
    customers.canManage &&
    customers.liveSyncWithThirdParty &&
    customer &&
    !customer.remoteUrl &&
    !onlyCompletedChecks
  ) {
    errors.push({
      action: APPROVE_CUSTOMER_ACTION,
      text: intl.formatMessage({
        defaultMessage: "Ny kunde. Kræver godkendelse",
      }),
    });
  }

  return errors;
};
