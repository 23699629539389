import {getOfflineDB, SerializableError} from "@co-frontend-libs/db-resources";
import {clearAuthToken, jsonFetch, setAuthToken} from "@co-frontend-libs/utils";
import {createAsyncThunk} from "@reduxjs/toolkit";
import {buildErrorObject} from "../types";
import {clearSession, loadSession as loadSessionFromDB, putSession} from "./local-db";
import {SessionData} from "./types";

export const login = createAsyncThunk<
  SessionData,
  {loginURL: string; password: string; username: string},
  {
    rejectValue: SerializableError;
  }
>("authentication/login", async ({loginURL, password, username}, thunkAPI) => {
  try {
    const response = await jsonFetch(loginURL, "post", {password, username});
    const sessionData = response.data as SessionData;
    await putSession(sessionData);
    setAuthToken(sessionData.token);
    return sessionData;
  } catch (error) {
    return thunkAPI.rejectWithValue(buildErrorObject(error));
  }
});

export const logout = createAsyncThunk<boolean, boolean, {rejectValue: SerializableError}>(
  "authentication/logout",
  async (explicit) => {
    await clearSession();
    const offlineDB = await getOfflineDB();
    // eslint-disable-next-line no-console
    console.log("closing offline database");
    offlineDB._closeConnection();
    // eslint-disable-next-line no-console
    console.log("deleting offline database");
    await offlineDB._deleteDatabase();
    clearAuthToken();
    window.location.href = window.location.href.split("#")[0];
    window.location.reload();
    return explicit;
  },
);

export const loadSession = createAsyncThunk<SessionData, void, {rejectValue: SerializableError}>(
  "authentication/loadSession",
  (_ignored, thunkAPI) =>
    loadSessionFromDB()
      .then((sessionData) => {
        setAuthToken(sessionData.token);
        return sessionData;
      })
      .catch((reason) => thunkAPI.rejectWithValue(buildErrorObject(reason))),
);

export const refreshToken = createAsyncThunk<
  SessionData,
  {refreshURL: string},
  {
    rejectValue: SerializableError;
  }
>("authentication/refreshToken", async ({refreshURL}, thunkAPI) => {
  try {
    const response = await jsonFetch(refreshURL, "post", {});
    const sessionData = response.data as SessionData;
    await putSession(sessionData);
    setAuthToken(sessionData.token);
    return sessionData;
  } catch (error) {
    return thunkAPI.rejectWithValue(buildErrorObject(error));
  }
});
