import {ProjectUrl, ResourceTypeUnion} from "@co-common-libs/resources";
import {
  actions,
  getCurrentRole,
  getCurrentUserURL,
  getCustomerSettings,
  getProjectFileArray,
} from "@co-frontend-libs/redux";
import {Card, CardHeader, CardMedia} from "@material-ui/core";
import {uploadFile} from "app-utils";
import CollectionsIcon from "mdi-react/CollectionsIcon";
import FilePdfBoxIcon from "mdi-react/FilePdfBoxIcon";
import PhotoCameraIcon from "mdi-react/PhotoCameraIcon";
import PlusIcon from "mdi-react/PlusIcon";
import React, {useCallback, useMemo} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {DragAndDropUploadOverlay} from "../drag-and-drop-upload-overlay";
import {FileTable} from "../file-table/file-table";
import {appPhotoHelper, SourceType, useFileInputChangeHandler} from "../files";
import {FloatingActionButtonData, FloatingActionButtons} from "../floating-action-buttons";

interface ProjectFilesCardProps {
  editable: boolean;
  projectUrl: ProjectUrl;
}

export const ProjectFilesCard = (props: ProjectFilesCardProps): React.JSX.Element => {
  const intl = useIntl();
  const projectFileArray = useSelector(getProjectFileArray);
  const {projectLabelVariant} = useSelector(getCustomerSettings);

  const {projectUrl} = props;
  const currentRole = useSelector(getCurrentRole);
  const canManageProjectFiles =
    (currentRole?.consultant || currentRole?.projectManager || currentRole?.manager) ?? false;
  const canEditProjectFiles = canManageProjectFiles && props.editable;
  const filteredProjectFilesArray = projectFileArray.filter(
    (file) => file.project === projectUrl && (file.visibleToAll || canManageProjectFiles),
  );

  const dispatch = useDispatch();
  const fileDropRejected = useCallback(
    (file: File): void => {
      const message = intl.formatMessage(
        {defaultMessage: "{name} er ikke en gyldig pdf eller billedfil"},
        {name: file.name},
      );
      window.setTimeout(() => {
        dispatch(actions.setMessage(message, new Date()));
      }, 0);
    },
    [dispatch, intl],
  );

  const currentUserURL = useSelector(getCurrentUserURL);
  const fileDropAccepted = useCallback(
    (files: File[]): void => {
      const boundCreate = (instance: ResourceTypeUnion): void => {
        dispatch(actions.create(instance));
      };
      const boundAddToOffline = (instance: ResourceTypeUnion): void => {
        dispatch(actions.addToOffline(instance));
      };
      const commonExtraData = {
        created: new Date().toISOString(),
        createdBy: currentUserURL || undefined,
        project: projectUrl,
      };
      files.forEach((file) => {
        const name = file.name.replace(/\.[^.]*$/, "");
        const extraData = {name, ...commonExtraData};
        uploadFile(file, "projectFile", extraData, "data", boundCreate, boundAddToOffline);
      });
    },
    [currentUserURL, dispatch, projectUrl],
  );

  const handleFileInputChange = useFileInputChangeHandler(
    fileDropAccepted,
    fileDropRejected,
    "image/jpeg,image/png,application/pdf",
  );

  const handleAppCameraButton = useCallback((): void => {
    appPhotoHelper(SourceType.CAMERA, fileDropAccepted);
  }, [fileDropAccepted]);

  const handleFiles = useCallback(
    (files: File[]): void => {
      const invalidFile = files.find(
        (file) => !["application/pdf", "image/jpeg", "image/png"].includes(file.type),
      );

      if (invalidFile) {
        fileDropRejected(invalidFile);
      } else {
        fileDropAccepted(files);
      }
    },
    [fileDropAccepted, fileDropRejected],
  );

  const buttonData: FloatingActionButtonData[] = useMemo(() => {
    if (canEditProjectFiles) {
      if (window.cordova) {
        return [
          {
            buttonIcon: <PhotoCameraIcon />,
            name: "camera-button",
            onClick: handleAppCameraButton,
            tooltipTitle: intl.formatMessage({defaultMessage: "Kamera"}),
          },
          {
            accept: "application/pdf",
            buttonIcon: <FilePdfBoxIcon />,
            id: "project-pdf-input",
            name: "pdf-button",
            onChange: handleFileInputChange,
            tooltipTitle: intl.formatMessage({defaultMessage: "Pdf"}),
          },
          {
            accept: "image/jpeg,image/png",
            buttonIcon: <CollectionsIcon />,
            id: "project-photos-input",
            name: "photo-button",
            onChange: handleFileInputChange,
            tooltipTitle: intl.formatMessage({defaultMessage: "Foto"}),
          },
        ];
      } else {
        return [
          {
            accept: "image/jpeg,image/png,application/pdf",
            buttonIcon: <PlusIcon />,
            id: "project-file-input",
            name: "file-button",
            onChange: handleFileInputChange,
            tooltipTitle: intl.formatMessage({defaultMessage: "Fil"}),
          },
        ];
      }
    }
    return [];
  }, [canEditProjectFiles, handleAppCameraButton, intl, handleFileInputChange]);

  const title =
    projectLabelVariant === "PROJECT"
      ? intl.formatMessage({defaultMessage: "Filer, projekt"})
      : intl.formatMessage({defaultMessage: "Filer, sag"});

  const content = (
    <FileTable
      currentUserCanManageFiles={canManageProjectFiles}
      editMode={canEditProjectFiles}
      files={filteredProjectFilesArray}
    />
  );

  if (canManageProjectFiles) {
    return (
      <Card style={{overflow: "visible"}}>
        <DragAndDropUploadOverlay onFiles={handleFiles}>
          <FloatingActionButtons buttons={buttonData} name="project files" variant="component" />
          <CardHeader title={title} />
          <CardMedia>{content}</CardMedia>
        </DragAndDropUploadOverlay>
      </Card>
    );
  } else {
    return (
      <Card>
        <CardHeader title={title} />
        <CardMedia>{content}</CardMedia>
        <FileTable
          currentUserCanManageFiles={canManageProjectFiles}
          editMode={canEditProjectFiles}
          files={filteredProjectFilesArray}
        />
      </Card>
    );
  }
};
