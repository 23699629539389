import {urlToId, User} from "@co-common-libs/resources";
import {getFrontendSentry} from "@co-frontend-libs/utils";

export function setSentryUser(user: User): void {
  if (user) {
    const sentry = getFrontendSentry();
    sentry.getCurrentScope().setUser({
      id: urlToId(user.url),
      username: user.loginIdentifier,
    });
  }
}
