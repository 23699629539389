import {DialogContent, IconButton} from "@material-ui/core";
import {green, red} from "@material-ui/core/colors";
import {bind} from "bind-decorator";
import bowser from "bowser";
import keycode from "keycode";
import ThumbDownIcon from "mdi-react/ThumbDownIcon";
import ThumbDownOutlineIcon from "mdi-react/ThumbDownOutlineIcon";
import ThumbUpIcon from "mdi-react/ThumbUpIcon";
import ThumbUpOutlineIcon from "mdi-react/ThumbUpOutlineIcon";
import memoizeOne from "memoize-one";
import React from "react";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";
import {ResponsiveDialog} from "./responsive-dialog";

const messages = defineMessages({
  availability: {
    defaultMessage: "Rådighed",
    id: "availability-dialog.title.availability",
  },
  friday: {
    defaultMessage: "Fredag",
    id: "availability-dialog.label.friday",
  },
  monday: {
    defaultMessage: "Mandag",
    id: "availability-dialog.label.monday",
  },
  ok: {
    defaultMessage: "OK",
    id: "dialog.label.ok",
  },
  saturday: {
    defaultMessage: "Lørdag",
    id: "availability-dialog.label.saturday",
  },
  sunday: {
    defaultMessage: "Søndag",
    id: "availability-dialog.label.sunday",
  },
  thursday: {
    defaultMessage: "Torsdag",
    id: "availability-dialog.label.thursday",
  },
  tuesday: {
    defaultMessage: "Tirsdag",
    id: "availability-dialog.label.tuesday",
  },
  wednesday: {
    defaultMessage: "Onsdag",
    id: "availability-dialog.label.wednesday",
  },
});

const MOBILE = bowser.ios || bowser.android;

export type WeekDay =
  | "friday"
  | "monday"
  | "saturday"
  | "sunday"
  | "thursday"
  | "tuesday"
  | "wednesday";

const WEEKEND_DAYS = ["saturday", "sunday"] as const;

interface DayBlockProps {
  day: WeekDay;
  initialValue?: boolean | undefined;
  onChange: (day: WeekDay, value: boolean) => void;
  value: boolean;
}

class DayBlock extends React.PureComponent<DayBlockProps> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  @bind
  handleNoClick(): void {
    this.props.onChange(this.props.day, false);
  }
  @bind
  handleYesClick(): void {
    this.props.onChange(this.props.day, true);
  }
  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {day, initialValue, value} = this.props;
    const label = formatMessage(messages[day]);
    const baseSize = 48;
    const buttonStyle: React.CSSProperties = {
      height: 2 * baseSize,

      padding: baseSize / 2,

      width: 2 * baseSize,
    };
    let thumbDownIcon, thumbUpIcon;
    if (value) {
      thumbUpIcon = <ThumbUpIcon color={green[300]} size={baseSize} />;
      thumbDownIcon = <ThumbDownOutlineIcon size={baseSize} />;
    } else {
      thumbUpIcon = <ThumbUpOutlineIcon size={baseSize} />;
      thumbDownIcon = <ThumbDownIcon color={red[300]} size={baseSize} />;
    }
    const labelStyle = {
      display: "inline-block",
      width: "7ex",
    };
    return (
      <div>
        <div style={labelStyle}>{label}</div>
        <IconButton onClick={this.handleYesClick} style={buttonStyle}>
          {thumbUpIcon}
        </IconButton>
        <IconButton disabled={!!initialValue} onClick={this.handleNoClick} style={buttonStyle}>
          {thumbDownIcon}
        </IconButton>
      </div>
    );
  }
}

function makeDaysSet(
  weekdays: readonly WeekDay[] | undefined,
  weekends: boolean,
): ReadonlySet<WeekDay> {
  if (weekdays && weekdays.length && weekends) {
    return new Set([...weekdays, ...WEEKEND_DAYS]);
  } else if (weekdays && weekdays.length) {
    return new Set(weekdays);
  } else if (weekends) {
    return new Set(WEEKEND_DAYS);
  } else {
    return new Set();
  }
}

interface AvailabilityDialogContentProps {
  days: ReadonlySet<WeekDay>;
  fullscreenLayout: boolean;
  initialValues?: Partial<{[day in WeekDay]: boolean | null}> | undefined;
  onOk: (values: ReadonlyMap<WeekDay, boolean>) => void;
  weekdays: boolean;
  weekends: boolean;
}

interface AvailabilityDialogContentState {
  values: ReadonlyMap<WeekDay, boolean>;
}

class AvailabilityDialogContent extends React.PureComponent<
  AvailabilityDialogContentProps,
  AvailabilityDialogContentState
> {
  state: AvailabilityDialogContentState = {
    values: new Map(
      Array.from(this.props.days).map((day) => {
        const initialValue = this.props.initialValues && this.props.initialValues[day];
        const value = initialValue != null ? initialValue : true;
        return [day, value];
      }),
    ),
  };
  @bind
  handleChange(day: WeekDay, value: boolean): void {
    const oldValues = this.state.values;
    const newValues = new Map(oldValues);
    newValues.set(day, value);
    this.setState({values: newValues});
  }
  @bind
  handleOk(): void {
    this.props.onOk(this.state.values);
  }
  render(): React.JSX.Element {
    const {days, initialValues, weekdays, weekends} = this.props;
    const {values} = this.state;
    const dayBlockList = Array.from(days).map((day) => {
      const value = initialValues && initialValues[day];
      return (
        <DayBlock
          day={day}
          initialValue={value != null ? value : undefined}
          key={day}
          onChange={this.handleChange}
          value={values.get(day) as boolean}
        />
      );
    });
    let message;
    if (weekends && !weekdays) {
      message = (
        <FormattedMessage
          defaultMessage="Jeg kan arbejde i weekenden:"
          id="availability-dialog.header.can-work-weekend"
        />
      );
    } else {
      message = (
        <FormattedMessage
          defaultMessage="Jeg kan arbejde ekstra følgende dage:"
          id="availability-dialog.header.can-work-extra"
        />
      );
    }
    return (
      <div>
        {message}
        {dayBlockList}
      </div>
    );
  }
}

export interface AvailabilityDialogProps {
  fullscreen?: boolean;
  initialValues?: Partial<{[day in WeekDay]: boolean | null}>;
  onCancel?: () => void;
  onOk: (values: ReadonlyMap<WeekDay, boolean>) => void;
  open: boolean;
  weekdays?: readonly WeekDay[] | undefined;
  weekends: boolean;
}

export class AvailabilityDialog extends React.PureComponent<AvailabilityDialogProps> {
  constructor(props: AvailabilityDialogProps) {
    super(props);
    this.makeDaysSet = memoizeOne(makeDaysSet);
  }

  static contextType = IntlContext;
  private _content = React.createRef<AvailabilityDialogContent>();
  context!: React.ContextType<typeof IntlContext>;
  makeDaysSet: (weekdays: readonly WeekDay[], weekends: boolean) => ReadonlySet<WeekDay>;

  @bind
  handleKeyPress(event: KeyboardEvent): void {
    if (keycode(event) === "enter" && (event.target as any).type !== "button") {
      this.handleOk();
    }
  }
  @bind
  handleOk(): void {
    if (this._content.current) {
      this._content.current.handleOk();
    }
  }
  @bind
  handleRealOk(values: ReadonlyMap<WeekDay, boolean>): void {
    this.props.onOk(values);
  }
  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {fullscreen, initialValues, onCancel, open, weekdays, weekends} = this.props;
    const fullscreenLayout = fullscreen ?? !!MOBILE;
    let dialogContent;
    if (open) {
      const days = makeDaysSet(weekdays, weekends);
      dialogContent = (
        <AvailabilityDialogContent
          days={days}
          fullscreenLayout={fullscreenLayout}
          initialValues={initialValues}
          onOk={this.handleRealOk}
          ref={this._content}
          weekdays={!!weekdays && !!weekdays.length}
          weekends={weekends}
        />
      );
    }
    return (
      <ResponsiveDialog
        fullscreen={fullscreen}
        onCancel={onCancel}
        onOk={this.handleOk}
        open={open}
        title={formatMessage(messages.availability)}
      >
        <DialogContent>{dialogContent}</DialogContent>
      </ResponsiveDialog>
    );
  }
}
