import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {DialogContent} from "@material-ui/core";
import React, {useCallback, useState} from "react";
import {useIntl} from "react-intl";

interface PriceGroupDialogProps {
  onCancel: () => void;
  onOk: (name: string, identifier: string) => void;
  open: boolean;
}

export const PriceGroupDialog = React.memo(function PriceGroupDialog(
  props: PriceGroupDialogProps,
): React.JSX.Element {
  const intl = useIntl();
  const [identifier, setIdentifier] = useState<string>("");
  const [name, setName] = useState<string>("");
  const {onCancel, onOk, open} = props;

  const handleOk = useCallback((): void => {
    onOk(name, identifier);
    setName("");
    setIdentifier("");
  }, [identifier, name, onOk]);

  const handleCancel = useCallback((): void => {
    onCancel();
    setName("");
    setIdentifier("");
  }, [onCancel]);

  const handleNameChange = useCallback((value: string): void => {
    setName(value || "");
  }, []);

  const handleIdentifierChange = useCallback((value: string): void => {
    setIdentifier(value || "");
  }, []);

  return (
    <ResponsiveDialog
      okDisabled={!name || !identifier}
      onCancel={handleCancel}
      onOk={handleOk}
      open={open}
      title={intl.formatMessage({defaultMessage: "Opret variant"})}
    >
      <DialogContent>
        <TrimTextField
          autoFocus
          fullWidth
          key="pricegroup-identifier-field"
          label={intl.formatMessage({defaultMessage: "ID-Nummer/Kode"})}
          margin="dense"
          onChange={handleIdentifierChange}
          value={identifier}
          variant="outlined"
        />
        <TrimTextField
          fullWidth
          key="pricegroup-name-field"
          label={intl.formatMessage({defaultMessage: "Navn"})}
          margin="dense"
          onChange={handleNameChange}
          value={name}
          variant="outlined"
        />
      </DialogContent>
    </ResponsiveDialog>
  );
});
