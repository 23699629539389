import {
  CustomerUrl,
  MachineUrl,
  PriceGroupUrl,
  PriceItemUrl,
  ProductUrl,
  ReportingSpecificationUrl,
} from "../resource-urls";
import {ResourceInstance} from "./base";

// changed from interface to type due to
// https://github.com/microsoft/TypeScript/issues/15300
// causing PriceGroupItem to be incompatible with generated `validatePriceGroupItemHelper`
// that expect data param to be of type `{[key: string]: unknown}`
export type PriceGroupItem = {
  readonly order: number;
  readonly priceItem: PriceItemUrl;
};

export type TimeAfterMinutesEffect = "on_sum" | "per_interval" | "unused" | undefined;

export interface PriceGroup extends ResourceInstance<PriceGroupUrl> {
  readonly active: boolean;
  readonly c5_recid: number | null;
  readonly customers: readonly CustomerUrl[];
  readonly identifier: string;
  readonly machines: readonly MachineUrl[] | undefined;
  readonly name: string;
  readonly noProductUsesOrAtLeastOneProductUseGreaterThanZero: boolean | null | undefined;
  readonly onlyForExtraTimers: boolean | undefined;
  readonly priceGroupItemSet: readonly PriceGroupItem[];
  readonly products: readonly ProductUrl[];
  /** Identifier in synced billing system; not necessarily an URL. */
  readonly remoteUrl: string;
  readonly reportingSpecification: ReportingSpecificationUrl | null;
  readonly requireAtLeastOneOptionalPriceItemUseGreaterThanZero: boolean;
  readonly requireAtLeastOneProductUseGreaterThanZero: boolean | null;
  readonly requireAttachment: boolean | undefined;
  readonly requireCompletionConfirmationIfNoProducts: boolean;
  readonly requireEffectiveTime: boolean | null | undefined;
  readonly requireMachineUse: boolean | null | undefined;
  readonly requireNotesFromMachineOperator: boolean | null;
  readonly requirePhotoOnTaskCompletion: boolean | undefined;
  readonly requirePriceLines: boolean | undefined;
  readonly standardListing: boolean;
  readonly timeAfterMinutesEffect: TimeAfterMinutesEffect;
}

export const emptyPriceGroup: Omit<PriceGroup, "id" | "url"> = {
  active: true,

  c5_recid: null,
  customers: [],
  identifier: "",
  machines: [],
  name: "",
  noProductUsesOrAtLeastOneProductUseGreaterThanZero: null,
  onlyForExtraTimers: false,
  priceGroupItemSet: [],
  products: [],
  remoteUrl: "",
  reportingSpecification: null,
  requireAtLeastOneOptionalPriceItemUseGreaterThanZero: false,
  requireAtLeastOneProductUseGreaterThanZero: null,
  requireAttachment: false,
  requireCompletionConfirmationIfNoProducts: false,
  requireEffectiveTime: null,
  requireMachineUse: null,
  requireNotesFromMachineOperator: false,
  requirePhotoOnTaskCompletion: false,
  requirePriceLines: true,
  standardListing: true,
  timeAfterMinutesEffect: "unused",
};
