// polyfill
// for shapefile-stuff
import {Buffer} from "buffer";
import "core-js";
// import "core-js/stable";
import "reflect-metadata";
// import "regenerator-runtime/runtime";
// import "@babel/polyfill";
import "whatwg-fetch";

(window as any).Buffer = (window as any).Buffer || Buffer;
