import {Config} from "@co-common-libs/config";
import {
  Contact,
  ContactUrl,
  Customer,
  CustomerUrl,
  Delivery,
  DeliveryLocation,
  Location,
  LocationStorageAdjustment,
  LocationStorageChange,
  LocationStorageStatus,
  LocationType,
  LocationTypeUrl,
  LocationUrl,
  Machine,
  MachineUrl,
  MachineUse,
  Order,
  OrderUrl,
  Pickup,
  PickupLocation,
  PickupLocationUrl,
  PriceGroup,
  PriceGroupUrl,
  PriceItem,
  PriceItemUrl,
  Product,
  ProductGroup,
  ProductGroupUrl,
  ProductUrl,
  Project,
  ProjectUrl,
  ReportingSpecification,
  ReportingSpecificationUrl,
  Role,
  RouteTask,
  RouteTaskActivityOption,
  RouteTaskActivityOptionUrl,
  RouteTaskResult,
  RouteTaskUrl,
  Task,
  TaskFile,
  TaskPhoto,
  Timer,
  TimerStart,
  TimerUrl,
  TransportLog,
  Unit,
  UnitUrl,
  UserProfile,
  UserUrl,
  WorkType,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {getMachineString, getProductsWithLogData} from "@co-common-libs/resources-utils";
import {dateToString} from "@co-common-libs/utils";
import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {
  AppState,
  getContactLookup,
  getCurrentRole,
  getCustomerLookup,
  getDeliveryArray,
  getDeliveryLocationArray,
  getExtendedCustomerSettings,
  getLocationArray,
  getLocationLookup,
  getLocationStorageAdjustmentArray,
  getLocationStorageChangeArray,
  getLocationStorageStatusArray,
  getLocationTypeLookup,
  getMachineArray,
  getMachineLookup,
  getOrderLookup,
  getPickupArray,
  getPickupLocationArray,
  getPickupLocationLookup,
  getPriceGroupLookup,
  getPriceItemArray,
  getPriceItemLookup,
  getProductGroupLookup,
  getProductLookup,
  getProjectArray,
  getProjectLookup,
  getReportingSpecificationLookup,
  getRouteTaskActivityOptionLookup,
  getRouteTaskArray,
  getRouteTaskLookup,
  getRouteTaskResultArray,
  getSortedTimerStartArray,
  getTaskArray,
  getTaskFileArray,
  getTaskPhotoArray,
  getTimerArray,
  getTimerLookup,
  getTransportLogArray,
  getUnitLookup,
  getUserUserProfileLookup,
  getWorkTypeArray,
  getWorkTypeLookup,
} from "@co-frontend-libs/redux";
import {colorMap} from "@co-frontend-libs/utils";
import {Checkbox, DialogContent, FormControlLabel, Radio, RadioGroup} from "@material-ui/core";
import {AccommodationAllowanceCheckbox, ErrorList, TaskInformation} from "app-components";
import {
  computeIntervalsTruncated,
  ErrorAction,
  ErrorEntry,
  getBreakTimer,
  getErrors,
  getGenericEffectiveTimer,
  getReadonlyProductsFromTask,
  getTaskSecondaryTimerList,
  getWarnings,
  InlinedMachineUse,
  inlineTaskData,
  inlineTransportLogData,
  isErrorEntryObjectWithAction,
  PureComponent,
} from "app-utils";
import {bind} from "bind-decorator";
import {ExtendedConfig} from "extended-config";
import React from "react";
import {defineMessages, FormattedMessage, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  minutes: {
    defaultMessage: "{count} min",
    id: "wizard-completed-dialog.label.minutes",
  },
  no: {defaultMessage: "Nej", id: "wizard-completed-dialog.label.no"},
  repairNote: {
    defaultMessage: "Note omkring reparation",
    id: "wizard-completed-dialog.label.repair-note",
  },
  title: {
    defaultMessage: "Fuldført",
    id: "wizard-completed-dialog.title.completed",
  },
  yes: {defaultMessage: "Ja", id: "wizard-completed-dialog.label.yes"},
});

interface MachineCheckboxProps {
  checked: boolean;
  customerSettings: Config;
  disabled?: boolean;
  machineUse: InlinedMachineUse;
  onChange: (checked: boolean, machineURL: MachineUrl) => void;
}

export class MachineCheckbox extends PureComponent<MachineCheckboxProps> {
  @bind
  handleCheck(event: React.ChangeEvent<HTMLInputElement>): void {
    const {checked} = event.target;
    const {machineUse, onChange} = this.props;
    onChange(checked, machineUse.machine.url);
  }
  render(): React.JSX.Element {
    const {checked, disabled, machineUse} = this.props;
    const {machine} = machineUse;
    const machineID = machine.c5_machine;
    const machineName = machine.name;
    const {priceGroup} = machineUse;
    let priceGroupName;
    if (priceGroup) {
      priceGroupName = priceGroup.name;
    }

    const text =
      machineName +
      (machineID ? ` (${machineID})` : "") +
      (priceGroupName ? ` (${priceGroupName})` : "");

    return (
      <FormControlLabel
        control={<Checkbox checked={checked} onChange={this.handleCheck} />}
        disabled={!!disabled}
        label={text}
      />
    );
  }
}

interface WizardCompletedDialogStateProps {
  contactLookup: (url: ContactUrl) => Contact | undefined;
  currentRole: Role | null;
  customerLookup: (url: CustomerUrl) => Customer | undefined;
  customerSettings: ExtendedConfig;
  deliveryArray: readonly Delivery[];
  deliveryLocationArray: readonly DeliveryLocation[];
  locationArray: readonly Location[];
  locationLookup: (url: LocationUrl) => Location | undefined;
  locationStorageAdjustmentArray: readonly LocationStorageAdjustment[];
  locationStorageChangeArray: readonly LocationStorageChange[];
  locationStorageStatusArray: readonly LocationStorageStatus[];
  locationTypeLookup: (url: LocationTypeUrl) => LocationType | undefined;
  machineArray: readonly Machine[];
  machineLookup: (url: MachineUrl) => Machine | undefined;
  orderLookup: (url: OrderUrl) => Order | undefined;
  pickupArray: readonly Pickup[];
  pickupLocationArray: readonly PickupLocation[];
  pickupLocationLookup: (url: PickupLocationUrl) => PickupLocation | undefined;
  priceGroupLookup: (url: PriceGroupUrl) => PriceGroup | undefined;
  priceItemArray: readonly PriceItem[];
  priceItemLookup: (url: PriceItemUrl) => PriceItem | undefined;
  productGroupLookup: (url: ProductGroupUrl) => ProductGroup | undefined;
  productLookup: (url: ProductUrl) => Product | undefined;
  projectArray: readonly Project[];
  projectLookup: (url: ProjectUrl) => Project | undefined;
  reportingSpecificationLookup: (
    url: ReportingSpecificationUrl,
  ) => ReportingSpecification | undefined;
  routeTaskActivityOptionLookup: (
    url: RouteTaskActivityOptionUrl,
  ) => RouteTaskActivityOption | undefined;
  routeTaskArray: readonly RouteTask[];
  routeTaskLookup: (url: RouteTaskUrl) => RouteTask | undefined;
  routeTaskResultArray: readonly RouteTaskResult[];
  sortedTimerStartArray: readonly TimerStart[];
  taskArray: readonly Task[];
  taskFileArray: readonly TaskFile[];
  taskPhotoArray: readonly TaskPhoto[];
  timerArray: readonly Timer[];
  timerLookup: (url: TimerUrl) => Timer | undefined;
  transportLogArray: readonly TransportLog[];
  unitLookup: (url: UnitUrl) => Unit | undefined;
  userUserProfileLookup: (userURL: UserUrl) => UserProfile | undefined;
  workTypeArray: readonly WorkType[];
  workTypeLookup: (url: WorkTypeUrl) => WorkType | undefined;
}

interface WizardCompletedDialogOwnProps {
  accommodationAllowanceRegistratedOnTaskDate?: boolean;
  accommodationRemunerationGroup?: string | undefined;
  cancelled: boolean;
  canRegisterAccommodation?: boolean;
  completedAsInternal?: boolean;
  errorHeaderText?: string;
  finalIntervals: readonly {
    readonly fromTimestamp: string;
    readonly timer: Timer | null;
    readonly toTimestamp: string;
  }[];
  finalStartTime: string | undefined;
  headerText?: string;
  okHeaderText?: string;
  onAction?: ((action: ErrorAction) => void) | undefined;
  onCancel: () => void;
  onOk: (
    event: unknown,
    data: {
      machineUse: readonly MachineUse[] | null;
      needsPreparation: boolean;
      needsPreparationMinutes: number;
      needsRepair: boolean;
      repairNote: string;
      selectedMachines: readonly MachineUrl[];
      selectedPreparationMachines: readonly MachineUrl[];
    },
  ) => void;
  onRegisterAccommodationChanged: (
    registerAccommodation: boolean,
    remunerationGroup: string,
  ) => void;
  open: boolean;
  registerAccommodation?: boolean;
  secondaryTimers: ReadonlySet<Timer>;
  task: Task;
  title?: string;
}

type WizardCompletedDialogProps = WizardCompletedDialogOwnProps & WizardCompletedDialogStateProps;

interface WizardCompletedDialogState {
  machineUse: readonly MachineUse[] | null;
  needsPreparation: boolean;
  needsPreparationMinutes: number;
  needsRepair: boolean;
  repairNote: string;
  selectedMachines: readonly MachineUrl[];
  selectedPreparationMachines: readonly MachineUrl[];
}

class WizardCompletedDialog extends PureComponent<
  WizardCompletedDialogProps,
  WizardCompletedDialogState
> {
  static contextType = IntlContext;
  static defaultProps = {
    accommodationAllowanceRegistratedOnTaskDate: false,
    canRegisterAccommodation: false,
    completedAsInternal: false,
    registerAccommodation: false,
  };
  context!: React.ContextType<typeof IntlContext>;
  state: WizardCompletedDialogState = {
    machineUse: null,
    needsPreparation: false,
    needsPreparationMinutes: 15,
    needsRepair: false,
    repairNote: "",
    selectedMachines: [],
    selectedPreparationMachines: [],
  };

  @bind
  handleMachineCheckedChanged(checked: boolean, machineURL: MachineUrl): void {
    let newMachines: MachineUrl[];
    if (checked) {
      newMachines = [...this.state.selectedMachines, machineURL];
    } else {
      newMachines = this.state.selectedMachines.filter((m) => m !== machineURL);
    }
    this.setState({
      selectedMachines: newMachines,
    });
  }
  @bind
  handleMachinePreparationCheckedChanged(checked: boolean, machineURL: MachineUrl): void {
    let newMachines: MachineUrl[];
    if (checked) {
      newMachines = [...this.state.selectedPreparationMachines, machineURL];
    } else {
      newMachines = this.state.selectedPreparationMachines.filter((m) => m !== machineURL);
    }
    this.setState({
      selectedPreparationMachines: newMachines,
    });
  }
  @bind
  handleNeedsPreparationMinutesChange(event: React.ChangeEvent<HTMLInputElement>): void {
    const {value} = event.target;
    const minutes = value ? parseInt(value) : 0;
    this.setState({needsPreparationMinutes: minutes});
  }

  @bind
  handleOk(event?: unknown): void {
    this.props.onOk(event, this.state);
  }

  @bind
  handleRepairNoteChange(value: string): void {
    this.setState({repairNote: value});
  }

  render(): React.JSX.Element {
    const {formatMessage} = this.context;
    const {
      accommodationRemunerationGroup,
      cancelled,
      canRegisterAccommodation,
      completedAsInternal,
      contactLookup,
      customerLookup,
      customerSettings,
      deliveryArray,
      deliveryLocationArray,
      errorHeaderText,
      finalStartTime,
      headerText,
      locationArray,
      locationLookup,
      locationStorageAdjustmentArray,
      locationStorageChangeArray,
      locationStorageStatusArray,
      locationTypeLookup,
      machineArray,
      machineLookup,
      okHeaderText,
      onRegisterAccommodationChanged,
      open,
      orderLookup,
      pickupArray,
      pickupLocationArray,
      priceGroupLookup,
      priceItemArray,
      priceItemLookup,
      productGroupLookup,
      productLookup,
      projectArray,
      projectLookup,
      reportingSpecificationLookup,
      routeTaskActivityOptionLookup,
      routeTaskArray,
      routeTaskLookup,
      routeTaskResultArray,
      task,
      taskArray,
      taskFileArray,
      taskPhotoArray,
      timerArray,
      timerLookup,
      transportLogArray,
      unitLookup,
      userUserProfileLookup,
      workTypeArray,
      workTypeLookup,
    } = this.props;
    let dialogContent;
    let warningList: ErrorEntry[] = [];
    let errorList: ErrorEntry[] = [];
    let autofocusWarningAction = false;
    let autofocusErrorAction = false;
    if (open && task) {
      let taskWithData = inlineTaskData(task, {
        contactLookup,
        customerLookup,
        machineLookup,
        orderLookup,
        priceGroupLookup,
        priceItemLookup,
        productLookup,
        projectLookup,
        reportingSpecificationLookup,
        timerLookup,
        workTypeLookup,
      });
      const transportLog = transportLogArray.find((log) => log.task === task.url);

      const transportLogWithData = transportLog
        ? inlineTransportLogData(transportLog, {
            deliveryArray,
            deliveryLocationArray,
            pickupArray,
            pickupLocationArray,
          })
        : null;
      const genericPrimaryTimer = getGenericEffectiveTimer(timerArray);
      const secondaryTimers = new Set(
        getTaskSecondaryTimerList(task, customerSettings, {
          machineLookup,
          priceGroupLookup,
          timerArray,
          workTypeLookup,
        }),
      );
      if (this.props.finalIntervals && this.props.finalIntervals.length) {
        taskWithData = {...taskWithData, _intervals: this.props.finalIntervals};
      }
      const role = this.props.currentRole;
      const userIsManager = !!(role && role.manager);

      const productsWithLogData = !task.logSkipped ? getProductsWithLogData(task) : undefined;

      const onlyCompletedChecks = true;

      const readonlyProducts: Set<ProductUrl> = getReadonlyProductsFromTask(
        task,
        productLookup,
        unitLookup,
        reportingSpecificationLookup,
      );

      warningList = getWarnings(
        {
          genericPrimaryTimer,
          locationArray,
          locationLookup,
          locationStorageAdjustmentArray,
          locationStorageChangeArray,
          locationStorageStatusArray,
          locationTypeLookup,
          machineArray,
          machineLookup,
          priceGroupLookup,
          priceItemArray,
          priceItemLookup,
          productGroupLookup,
          productLookup,
          productsWithLogData,
          projectArray,
          readonlyProducts,
          routeTaskArray,
          secondaryTimers,
          task: taskWithData,
          timerArray,
          transportLog: transportLogWithData || undefined,
          unitLookup,
          userIsManager,
          workTypeLookup,
        },
        this.props.customerSettings,
        this.context,
      );
      errorList = getErrors(
        {
          genericPrimaryTimer,
          locationArray,

          machineArray,
          // priceGroup,
          priceGroupLookup,
          priceItemArray,
          priceItemLookup,
          productGroupLookup,
          productLookup,
          productsWithLogData,
          projectArray,
          readonlyProducts,
          routeTaskActivityOptionLookup,
          routeTaskArray,
          routeTaskLookup,
          routeTaskResultArray,
          secondaryTimers,
          task: taskWithData,
          taskFileArray,
          taskPhotoArray,
          transportLog: transportLogWithData || undefined,
          unitLookup,
          userIsManager,
          workTypeLookup,
        },
        this.props.customerSettings,
        this.context,
        onlyCompletedChecks,
        completedAsInternal || false,
      );

      const specificHeaderText = !errorList.length ? okHeaderText : errorHeaderText;
      const realHeaderText = specificHeaderText || headerText;

      let headerBlock;
      if (realHeaderText) {
        if (cancelled) {
          headerBlock = <h3 style={{color: colorMap.ERROR}}>{realHeaderText}</h3>;
        } else {
          headerBlock = <h3>{realHeaderText}</h3>;
        }
      }

      const finalStartDate = finalStartTime ? dateToString(new Date(finalStartTime)) : null;
      let registerAccommodationBlock;
      if (
        canRegisterAccommodation &&
        !!onRegisterAccommodationChanged &&
        task.machineOperator &&
        finalStartDate
      ) {
        registerAccommodationBlock = (
          <div style={{paddingBottom: "1em", paddingTop: "1em"}}>
            <AccommodationAllowanceCheckbox
              date={finalStartDate}
              employeeUrl={task.machineOperator}
              onChange={onRegisterAccommodationChanged}
              remunerationGroup={accommodationRemunerationGroup || ""}
            />
          </div>
        );
      }
      let warningBlock: React.JSX.Element | undefined;
      let errorBlock: React.JSX.Element | undefined;
      autofocusWarningAction = warningList.some(isErrorEntryObjectWithAction);
      if (warningList.length) {
        warningBlock = (
          <div style={{color: colorMap.WARNING}}>
            <FormattedMessage
              defaultMessage="Advarsler:"
              id="wizard-completed-dialog.header.warnings"
              tagName="h4"
            />
            <ErrorList
              errors={warningList}
              onAction={autofocusWarningAction ? this.props.onAction : undefined}
            />
          </div>
        );
      }
      autofocusErrorAction =
        !autofocusWarningAction && errorList.some(isErrorEntryObjectWithAction);
      if (errorList.length) {
        errorBlock = (
          <div style={{color: colorMap.ERROR}}>
            <FormattedMessage
              defaultMessage="Fejl:"
              id="wizard-completed-dialog.header.errors"
              tagName="h4"
            />
            <ErrorList
              errors={errorList}
              onAction={autofocusErrorAction ? this.props.onAction : undefined}
            />
          </div>
        );
      }
      let preparationBlock;
      if (customerSettings.taskCompletePrepareQuery) {
        const prepTimeDisabled = this.state.selectedPreparationMachines.length < 1;
        preparationBlock = (
          <div>
            <FormattedMessage
              defaultMessage="Skal der klargøres?"
              id="wizard-completed-dialog.label.prepare-question"
            />
            <div style={{paddingLeft: 20}}>
              {taskWithData.machineuseSet.map((machineUse) => {
                if (!machineUse.machine) {
                  return null;
                }
                const selectedMachines = this.state.selectedPreparationMachines;
                const machineURL = machineUse.machine.url;
                return (
                  <MachineCheckbox
                    checked={selectedMachines.includes(machineURL)}
                    customerSettings={this.props.customerSettings}
                    key={machineURL}
                    machineUse={
                      machineUse as {
                        machine: Machine;
                        priceGroup: PriceGroup | null;
                        transporter: boolean;
                      }
                    }
                    onChange={this.handleMachinePreparationCheckedChanged}
                  />
                );
              })}
              <RadioGroup
                name="preparation-duration"
                onChange={this.handleNeedsPreparationMinutesChange}
                value={this.state.needsPreparationMinutes}
              >
                <FormControlLabel
                  control={<Radio />}
                  disabled={prepTimeDisabled}
                  label={formatMessage(messages.minutes, {count: 15})}
                  value={15}
                />
                <FormControlLabel
                  control={<Radio />}
                  disabled={prepTimeDisabled}
                  label={formatMessage(messages.minutes, {count: 30})}
                  value={30}
                />
                <FormControlLabel
                  control={<Radio />}
                  disabled={prepTimeDisabled}
                  label={formatMessage(messages.minutes, {count: 45})}
                  value={45}
                />
              </RadioGroup>
            </div>
          </div>
        );
      }
      const existingRepairTasksBlocks: React.JSX.Element[] = [];
      let repairBlock: React.JSX.Element | undefined;
      if (customerSettings.taskCompleteRepairQuery && taskWithData.machineuseSet.length) {
        const repairWorkType = workTypeArray.find(
          (workType) => workType.identifier === customerSettings.repairWorkType,
        );
        const repairWorkTypeURL = repairWorkType ? repairWorkType.url : null;

        const openRepairTasksWithNotes = taskArray.filter(
          (entry) =>
            entry.workType === repairWorkTypeURL &&
            entry.notesFromMachineOperator &&
            !entry.completed,
        );
        taskWithData.machineuseSet.forEach((machineUse) => {
          const repairNotes: string[] = [];
          const {machine} = machineUse;
          if (!machine) {
            return;
          }
          const machineURL = machine.url;

          openRepairTasksWithNotes.forEach((repairTask) => {
            const foundTask = repairTask.machineuseSet.some((mu) => mu.machine === machineURL);
            if (foundTask) {
              repairNotes.push(repairTask.notesFromMachineOperator);
            }
          });
          if (repairNotes.length) {
            existingRepairTasksBlocks.push(
              <div key={machineURL}>
                <h4>{getMachineString(machine)}</h4>
                <ul>
                  {repairNotes.map((note, index) => (
                    <li key={index}>{note}</li>
                  ))}
                </ul>
              </div>,
            );
          }
        });

        const {selectedMachines} = this.state;
        repairBlock = (
          <div>
            <FormattedMessage
              defaultMessage="Er der noget der skal repareres?"
              id="wizard-completed-dialog.label.repair-question"
            />
            <div style={{paddingLeft: 20}}>
              <div>
                <FormattedMessage
                  defaultMessage="Allerede indmeldte fejl"
                  id="wizard-completed-dialog.label.existing-errors"
                />
              </div>
              <div>
                {existingRepairTasksBlocks.length ? (
                  existingRepairTasksBlocks
                ) : (
                  <FormattedMessage
                    defaultMessage="Ingen"
                    id="wizard-completed-dialog.label.none"
                  />
                )}
              </div>

              {taskWithData.machineuseSet.map((machineUse) => {
                if (!machineUse.machine) {
                  return null;
                }
                const machineURL = machineUse.machine.url;
                return (
                  <div key={machineURL}>
                    <MachineCheckbox
                      checked={selectedMachines.includes(machineURL)}
                      customerSettings={this.props.customerSettings}
                      machineUse={
                        machineUse as {
                          machine: Machine;
                          priceGroup: PriceGroup | null;
                          transporter: boolean;
                        }
                      }
                      onChange={this.handleMachineCheckedChanged}
                    />
                  </div>
                );
              })}
              <TrimTextField
                disabled={selectedMachines.length < 1}
                fullWidth
                label={formatMessage(messages.repairNote)}
                margin="dense"
                onChange={this.handleRepairNoteChange}
                value={this.state.repairNote}
                variant="outlined"
              />
            </div>
          </div>
        );
      }

      const breakTimer = getBreakTimer(timerArray);
      const machineOperatorURL = task ? task.machineOperator : null;
      const machineOperatorProfile = machineOperatorURL
        ? userUserProfileLookup(machineOperatorURL)
        : undefined;
      let info;
      if (customerSettings.taskCompleteOverview || !task.order) {
        const taskURL = task.url;
        const computedIntervals = task.archivable
          ? task.computedTimeSet
          : computeIntervalsTruncated(
              this.props.sortedTimerStartArray.filter((timerStart) => timerStart.task === taskURL),
            );
        info = (
          <div style={{marginBottom: 10, marginTop: 10}}>
            <TaskInformation
              breakTimer={breakTimer}
              cancelled={this.props.cancelled}
              computedIntervals={computedIntervals}
              customer={(taskWithData.order && taskWithData.order.customer) || undefined}
              finalIntervals={this.props.finalIntervals}
              genericPrimaryTimer={genericPrimaryTimer}
              machineOperatorProfile={machineOperatorProfile}
              onlyCompletedChecks={onlyCompletedChecks}
              project={taskWithData.project || undefined}
              secondaryTimers={this.props.secondaryTimers}
              task={task}
              workType={taskWithData.workType || undefined}
            />
          </div>
        );
      }

      dialogContent = (
        <div>
          {headerBlock}
          {warningBlock}
          {errorBlock}
          {info}
          {registerAccommodationBlock}
          {preparationBlock}
          {repairBlock}
        </div>
      );
    }

    const dialogTitle = this.props.title ? this.props.title : formatMessage(messages.title);

    return (
      <ResponsiveDialog
        autoFocusOk={!autofocusWarningAction && !autofocusErrorAction}
        okDisabled={
          !!errorList.length ||
          (customerSettings.taskCompleteRepairQueryRequireNote &&
            !!this.state.selectedMachines.length &&
            !this.state.repairNote)
        }
        onCancel={this.props.onCancel}
        onOk={this.handleOk}
        open={open}
        title={dialogTitle}
      >
        <DialogContent>{dialogContent}</DialogContent>
      </ResponsiveDialog>
    );
  }
}

const ConnectedWizardCompletedDialog: React.ComponentType<WizardCompletedDialogOwnProps> = connect<
  WizardCompletedDialogStateProps,
  object,
  WizardCompletedDialogOwnProps,
  AppState
>(
  createStructuredSelector<AppState, WizardCompletedDialogStateProps>({
    contactLookup: getContactLookup,
    currentRole: getCurrentRole,
    customerLookup: getCustomerLookup,
    customerSettings: getExtendedCustomerSettings,
    deliveryArray: getDeliveryArray,
    deliveryLocationArray: getDeliveryLocationArray,
    locationArray: getLocationArray,
    locationLookup: getLocationLookup,
    locationStorageAdjustmentArray: getLocationStorageAdjustmentArray,
    locationStorageChangeArray: getLocationStorageChangeArray,
    locationStorageStatusArray: getLocationStorageStatusArray,
    locationTypeLookup: getLocationTypeLookup,
    machineArray: getMachineArray,
    machineLookup: getMachineLookup,
    orderLookup: getOrderLookup,
    pickupArray: getPickupArray,
    pickupLocationArray: getPickupLocationArray,
    pickupLocationLookup: getPickupLocationLookup,
    priceGroupLookup: getPriceGroupLookup,
    priceItemArray: getPriceItemArray,
    priceItemLookup: getPriceItemLookup,
    productGroupLookup: getProductGroupLookup,
    productLookup: getProductLookup,
    projectArray: getProjectArray,
    projectLookup: getProjectLookup,
    reportingSpecificationLookup: getReportingSpecificationLookup,
    routeTaskActivityOptionLookup: getRouteTaskActivityOptionLookup,
    routeTaskArray: getRouteTaskArray,
    routeTaskLookup: getRouteTaskLookup,
    routeTaskResultArray: getRouteTaskResultArray,
    sortedTimerStartArray: getSortedTimerStartArray,
    taskArray: getTaskArray,
    taskFileArray: getTaskFileArray,
    taskPhotoArray: getTaskPhotoArray,
    timerArray: getTimerArray,
    timerLookup: getTimerLookup,
    transportLogArray: getTransportLogArray,
    unitLookup: getUnitLookup,
    userUserProfileLookup: getUserUserProfileLookup,
    workTypeArray: getWorkTypeArray,
    workTypeLookup: getWorkTypeLookup,
  }),
  {},
)(WizardCompletedDialog);

export {ConnectedWizardCompletedDialog as WizardCompletedDialog};
