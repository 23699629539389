import type {Writable} from "ts-essentials";
import {Config} from "@co-common-libs/config";
import {
  CustomerUrl,
  EmployeeGroupUrl,
  emptyTask,
  FieldUse,
  LocationUrl,
  MachineUrl,
  MachineUse,
  Order,
  PriceGroupUrl,
  ProjectUrl,
  ResourceTypeUnion,
  Role,
  RoutePlanUrl,
  Task,
  urlToId,
  UserUrl,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {dateToString, formatAddress, notUndefined} from "@co-common-libs/utils";
import {AppbarSearchField, useModal} from "@co-frontend-libs/components";
import {
  ConnectedInternalWorkshopWorkTypeDialog,
  ConnectedInternalWorkTypeDialog,
  ConnectedProjectDialog,
  ConnectedRoutePlanDialog,
  ConnectedUserDialog,
} from "@co-frontend-libs/connected-components";
import {
  actions,
  getContactArray,
  getCurrentRole,
  getCurrentUser,
  getCurrentUserCanManageProjects,
  getCurrentUserProfile,
  getCurrentUserURL,
  getCustomerLookup,
  getCustomerSettings,
  getDaysAbsenceArray,
  getDefaultTaskEmployeeUrl,
  getEmployeeGroupLookup,
  getExtraHalfHolidaysPerRemunerationGroup,
  getExtraHolidaysPerRemunerationGroup,
  getLocationLookup,
  getRoutePlanArray,
  getRoutePlanLookup,
  getUserUserProfileLookup,
  isEmployeeGroupsActivated,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue} from "@co-frontend-libs/utils";
import {Tab, Tabs} from "@material-ui/core";
import {
  ConnectedTaskTable,
  CustomerSelectCreateDialog,
  CustomerTaskCreationWrapper,
  FilterBar,
  FilterButton,
  FloatingActionButtonData,
  FloatingActionButtons,
  MenuToolbar,
  PageLayout,
  RegisterAbsenceDialog,
  TaskTableDataType,
} from "app-components";
import {
  BatchOperation,
  computeDepartment,
  createNewRouteTask,
  createOrder,
  currentUserMayRegisterOwnAbsence,
  registerAbsence,
  useBatchCheckboxRenderer,
  useBoundActions,
  useDeviceConfig,
  useEventTargetValueCallback,
  useQueryParameterState,
} from "app-utils";
import bowser from "bowser";
import {instanceURL} from "frontend-global-config";
import AccountAlertIcon from "mdi-react/AccountAlertIcon";
import FolderOpenIcon from "mdi-react/FolderOpenIcon";
import HomeIcon from "mdi-react/HomeIcon";
import RoutesIcon from "mdi-react/RoutesIcon";
import TagMultipleIcon from "mdi-react/TagMultipleIcon";
import TrashCanIcon from "mdi-react/TrashCanIcon";
import React, {useCallback, useMemo, useRef, useState} from "react";
import {defineMessages, FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {useBatchOperationFloatingActionButtons} from "../use-batch-operation-fabs";
import {useBatchOperationState} from "../use-batch-operation-state";
import {DeleteConfirmationDialog} from "./delete-confirmation-dialog/delete-confirmation-dialog";
import {TaskArchive} from "./task-archive";

const messages = defineMessages({
  archive: {
    defaultMessage: "Arkiv",
    id: "task-list.tab-label.archive",
  },
  contractorWork: {
    defaultMessage: "Entreprenørarbejde",
    id: "task-list.contractor-work",
  },
  culturesTasks: {
    defaultMessage: "Kulturer",
    id: "task-list.tab-header.cultures-tasks",
  },
  customersTasks: {
    defaultMessage: "Kunder",
    id: "task-list.tab-header.customers-tasks",
  },
  internalTasks: {
    defaultMessage: "Interne",
  },
  internalTasksShort: {
    defaultMessage: "Int.",
  },
  myTasks: {
    defaultMessage: "Egne",
    id: "task-list.tab-header.my-tasks",
  },
  noDate: {
    defaultMessage: "Uden dato",
    id: "task-list.label.no-date",
  },
  readyForBilling: {
    defaultMessage: "Fakturering",
  },
  readyForBillingShort: {
    defaultMessage: "Fak.",
  },
  title: {
    defaultMessage: "Opgaver",
    id: "task-list.title",
  },
});

const TAB_NAMES = [
  "readyForBilling",
  "mine",
  "customers",
  "cultures",
  "internal",
  "archive",
] as const;

type TabName = (typeof TAB_NAMES)[number];

function getTabDefault(role: Role | null, customerSettings: Config): TabName {
  const isManager = role && role.manager;
  const isMachineOperator = role && role.machineOperator;
  if (isManager || !isMachineOperator) {
    if (customerSettings.externalTaskCulture) {
      return "cultures";
    }
    if (customerSettings.externalTaskCustomer) {
      return "customers";
    }
    return "internal";
  }
  return "mine";
}

export const TaskList = React.memo(function TaskList({
  onMenuButton,
}: {
  onMenuButton: (event: React.MouseEvent) => void;
}): React.JSX.Element {
  const [deviceMachine] = useDeviceConfig<MachineUrl>("machine");
  const [taskListVisibleWorkTypes, setTaskListVisibleWorkTypes] = useDeviceConfig<WorkTypeUrl[]>(
    "taskListVisibleWorkTypes",
  );
  const [taskListEmployeeGroup, setTaskListVisibleEmployeeGroups] = useDeviceConfig<
    EmployeeGroupUrl[]
  >("taskListVisibleEmployeeGroups");

  const [taskListVisibleDepartments, setTaskListVisibleDepartments] = useDeviceConfig<string[]>(
    "taskListVisibleDepartments",
  );

  const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
  const setCustomerDialogOpenFalse = useCallWithFalse(setCustomerDialogOpen);

  const [routePlanDialogOpen, setRoutePlanDialogOpen] = useState(false);
  const setRoutePlanDialogOpenTrue = useCallWithTrue(setRoutePlanDialogOpen);
  const setRoutePlanDialogOpenFalse = useCallWithFalse(setRoutePlanDialogOpen);

  const [selectedWorkTypeURLSet, setSelectedWorkTypeURLSet] = useState<ReadonlySet<WorkTypeUrl>>(
    new Set(taskListVisibleWorkTypes || []),
  );

  const [selectedEmployeeGroupUrlSet, setSelectedEmployeeGroupURLSet] = useState<
    ReadonlySet<EmployeeGroupUrl>
  >(new Set(taskListEmployeeGroup || []));

  const [selectedDepartmentIdentifierSet, setSelectedDepartmentIdentifierSet] = useState<
    ReadonlySet<string>
  >(new Set(taskListVisibleDepartments || []));

  const [workTypeDialogOpen, setWorkTypeDialogOpen] = useState(false);
  const setWorkTypeDialogOpenTrue = useCallWithTrue(setWorkTypeDialogOpen);
  const setWorkTypeDialogOpenFalse = useCallWithFalse(setWorkTypeDialogOpen);

  const currentRole = useSelector(getCurrentRole);
  const currentUserURL = useSelector(getCurrentUserURL);
  const currentUserProfile = useSelector(getCurrentUserProfile);
  const currentUser = useSelector(getCurrentUser);
  const currentUserCanManageProjects = useSelector(getCurrentUserCanManageProjects);
  const defaultTaskEmployeeUrl = useSelector(getDefaultTaskEmployeeUrl);
  const customerSettings = useSelector(getCustomerSettings);
  const employeeGroupLookup = useSelector(getEmployeeGroupLookup);
  const contactArray = useSelector(getContactArray);
  const locationLookup = useSelector(getLocationLookup);
  const customerLookup = useSelector(getCustomerLookup);
  const routePlanArray = useSelector(getRoutePlanArray);
  const routePlanLookup = useSelector(getRoutePlanLookup);
  const dispatch = useDispatch();
  const [q, setQ] = useQueryParameterState<string>("q", "");

  const [activeTab, setActiveTab] = useQueryParameterState<TabName>(
    "tab",
    getTabDefault(currentRole, customerSettings),
  );
  const {formatMessage} = useIntl();

  const taskCreationWizardControl = useRef<{
    start: (data?: {defaultDepartment?: string | null}) => void;
  } | null>(null);

  const handleFilterStringChange = useEventTargetValueCallback(setQ, [setQ]);

  const handleTabChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: string) => {
      console.assert(TAB_NAMES.includes(value as TabName));
      setActiveTab(value as TabName);
    },
    [setActiveTab],
  );

  const newExternalTask = useCallback(
    (
      customerURL?: CustomerUrl | null,
      project?: ProjectUrl | null,
      workType?: WorkTypeUrl | null,
      priceGroup?: PriceGroupUrl | null,
      machineuseSet?: readonly MachineUse[],
      workPlace?: LocationUrl | null,
      fielduseSet?: readonly FieldUse[],
      department?: string | null,
    ) => {
      const today = dateToString(new Date());
      const userURL = currentUserURL;
      const role = currentRole;
      const userIsManager = role && role.manager;
      const userIsSeniorMachineOperator = role && role.seniorMachineOperator;
      let contactURL = null;
      if (customerURL) {
        const contact = contactArray.find((instance) => {
          return instance.customer === customerURL && instance.defaultContact && instance.active;
        });
        contactURL = contact ? contact.url : null;
      }
      const workPlaceInstance = (workPlace && locationLookup(workPlace)) || undefined;
      const address = formatAddress(workPlaceInstance);
      let orderReferenceNumber = "";
      if (
        customerSettings.enableOrderReferenceNumber &&
        customerSettings.autoFillReferenceNumberWithCustomerAccount &&
        customerURL
      ) {
        const customerInstance = customerLookup(customerURL);
        orderReferenceNumber = (customerInstance && customerInstance.c5_account) || "";
      }

      const newOrderPart: Writable<Partial<Order>> = {
        address,
        contact: contactURL,
        createdBy: userURL,
        durationDays: 1,
        project,
        referenceNumber: orderReferenceNumber,
      };
      if (customerURL) {
        newOrderPart.customer = customerURL;
      }
      if (workPlace) {
        newOrderPart.relatedWorkplace = workPlace;
      }

      const newOrder: Writable<Order> = createOrder(newOrderPart);
      if (!userIsManager && !userIsSeniorMachineOperator) {
        if (customerSettings.ownCompanyId && !customerURL) {
          newOrder.customer = instanceURL("customer", customerSettings.ownCompanyId);
        }
        newOrder.date = today;
      }
      dispatch(actions.create(newOrder));

      const employeeGroupURL = currentUserProfile ? currentUserProfile.employeeGroup : null;
      const employeeGroup = employeeGroupURL ? employeeGroupLookup(employeeGroupURL) : null;

      const taskID = uuid();
      const taskURL = instanceURL("task", taskID);
      const newTask: Writable<Task> = {
        ...emptyTask,
        address,
        created: new Date().toISOString(),
        createdBy: userURL,
        department:
          department ||
          (currentUser
            ? computeDepartment(currentUserProfile, employeeGroup || null, customerSettings)
            : ""),
        id: taskID,
        order: newOrder.url,

        priority: Math.pow(2, 28), // for local ordering until set by server
        relatedWorkplace: workPlace || null,
        url: taskURL,
      };
      if (project) {
        newTask.project = project;
      }
      if (workType) {
        newTask.workType = workType;
      }

      if (priceGroup) {
        newTask.priceGroup = priceGroup;
      }

      if (machineuseSet) {
        newTask.machineuseSet = machineuseSet;
      }

      if (fielduseSet) {
        newTask.fielduseSet = fielduseSet;
      }

      if (!userIsManager && !userIsSeniorMachineOperator) {
        newTask.date = today;
      }

      if (activeTab === "mine") {
        newTask.machineOperator = userURL;
      } else if (!userIsManager && !userIsSeniorMachineOperator) {
        newTask.machineOperator = userURL;
      } else {
        newTask.machineOperator = customerSettings.defaultTaskEmployee
          ? instanceURL("user", customerSettings.defaultTaskEmployee)
          : null;
      }

      dispatch(actions.create(newTask));

      if (
        customerSettings.customerTaskCreationWizard &&
        newTask.machineOperator &&
        (!customerSettings.defaultTaskEmployee ||
          newTask.machineOperator !== instanceURL("user", customerSettings.defaultTaskEmployee))
      ) {
        dispatch(actions.go("/task/:id", {id: taskID}));
      } else {
        if (newOrder.id) {
          dispatch(actions.go("/order/:id", {id: newOrder.id}));
        }
      }
    },
    [
      activeTab,
      contactArray,
      currentRole,
      currentUser,
      currentUserProfile,
      currentUserURL,
      customerLookup,
      customerSettings,
      dispatch,
      employeeGroupLookup,
      locationLookup,
    ],
  );

  const handleCustomerFabButton = useCallback(() => {
    const userIsManager = currentRole && currentRole.manager;
    const userIsSeniorMachineOperator = currentRole && currentRole.seniorMachineOperator;
    if (
      customerSettings.customerTaskCreationWizard &&
      (activeTab === "mine" || (!userIsManager && !userIsSeniorMachineOperator))
    ) {
      const employeeGroupURL = currentUserProfile && currentUserProfile.employeeGroup;
      const employeeGroup = employeeGroupURL && employeeGroupLookup(employeeGroupURL);
      const defaultDepartment =
        (currentUser &&
          computeDepartment(currentUserProfile, employeeGroup || null, customerSettings)) ||
        null;
      if (taskCreationWizardControl.current) {
        taskCreationWizardControl.current.start({defaultDepartment});
      }
    } else if (customerSettings.orderEntryAutoOpenCustomerSelection) {
      setCustomerDialogOpen(true);
    } else {
      newExternalTask();
    }
  }, [
    activeTab,
    currentRole,
    currentUser,
    currentUserProfile,
    customerSettings,
    employeeGroupLookup,
    newExternalTask,
  ]);

  const handleInternalButton = useCallback(() => {
    const userIsManager = currentRole && currentRole.manager;
    const userIsSeniorMachineOperator = currentRole && currentRole.seniorMachineOperator;
    if (!userIsManager && !userIsSeniorMachineOperator) {
      setWorkTypeDialogOpen(true);
    } else {
      const employeeGroupURL = currentUserProfile ? currentUserProfile.employeeGroup : null;
      const employeeGroup = employeeGroupURL ? employeeGroupLookup(employeeGroupURL) : null;
      const id = uuid();
      const url = instanceURL("task", id);
      const params: Writable<Task> = {
        ...emptyTask,
        createdBy: currentUserURL,
        department: currentUser
          ? computeDepartment(currentUserProfile, employeeGroup || null, customerSettings)
          : "",
        id,
        machineOperator: customerSettings.defaultTaskEmployee
          ? instanceURL("user", customerSettings.defaultTaskEmployee)
          : null,
        url,
      };
      if (deviceMachine) {
        // FIXME: support machines with pricegroups?
        const machineEntry = {
          machine: deviceMachine,
          priceGroup: null,
          transporter: false,
        };
        params.machineuseSet = [machineEntry];
      }
      dispatch(actions.create(params));
      dispatch(actions.go("/internalTask/:id", {id}));
    }
  }, [
    currentRole,
    currentUser,
    currentUserProfile,
    currentUserURL,
    customerSettings,
    deviceMachine,
    dispatch,
    employeeGroupLookup,
  ]);

  const create = useCallback(
    (instance: ResourceTypeUnion) => dispatch(actions.create(instance)),
    [dispatch],
  );

  const newRoute = useCallback(
    (routePlanURL: RoutePlanUrl) => {
      const tabIsMine = activeTab === "mine";
      const task = createNewRouteTask(
        routePlanURL,
        currentUserURL,
        currentRole,
        create,
        tabIsMine,
        routePlanLookup,
        customerSettings.defaultTaskEmployee,
      );
      window.setTimeout(() => {
        if (!task.id) {
          return;
        }
        const {machineOperator: machineOperatorUrl} = task;
        if (machineOperatorUrl && machineOperatorUrl !== defaultTaskEmployeeUrl) {
          dispatch(actions.go("/task/:id", {id: task.id}));
        } else {
          dispatch(actions.go("/taskEdit/:id", {id: task.id}));
        }
      }, 0);
    },
    [
      activeTab,
      create,
      currentRole,
      currentUserURL,
      customerSettings.defaultTaskEmployee,
      defaultTaskEmployeeUrl,
      dispatch,
      routePlanLookup,
    ],
  );

  const handleWorkTypeDialogOk = useCallback(
    (workTypeURL: WorkTypeUrl) => {
      // NOTE: This only supports "internal" work --- no pricegroup/priceitem logic

      const id = uuid();
      const url = instanceURL("task", id);
      const employeeGroupURL = currentUserProfile ? currentUserProfile.employeeGroup : null;
      const employeeGroup = employeeGroupURL ? employeeGroupLookup(employeeGroupURL) : null;
      const task: Task = {
        ...emptyTask,
        createdBy: currentUserURL,
        date: dateToString(new Date()),
        department: currentUser
          ? computeDepartment(currentUserProfile, employeeGroup || null, customerSettings)
          : "",
        id,
        machineOperator: currentUserURL,
        url,
        workType: workTypeURL,
      };

      create(task);
      setWorkTypeDialogOpen(false);
      dispatch(actions.go("/task/:id", {id}));
    },
    [
      create,
      currentUser,
      currentUserProfile,
      currentUserURL,
      customerSettings,
      dispatch,
      employeeGroupLookup,
    ],
  );

  const handleCustomerDialogOk = useCallback(
    (url: CustomerUrl) => {
      setCustomerDialogOpen(false);
      newExternalTask(url);
    },
    [newExternalTask],
  );

  const handleRoutePlanDialogOk = useCallback(
    (url: RoutePlanUrl) => {
      setRoutePlanDialogOpen(false);
      newRoute(url);
    },
    [newRoute],
  );

  const handleCustomerTaskCreationWizardOk = useCallback(
    (data: {
      customer: CustomerUrl;
      department: string | null;
      fields: readonly LocationUrl[];
      machines: readonly {
        readonly machine: MachineUrl;
        readonly priceGroup: PriceGroupUrl | null;
      }[];
      priceGroup: PriceGroupUrl | null;
      project: ProjectUrl | null;
      workPlace: LocationUrl | null;
      workType: WorkTypeUrl | null;
    }) => {
      const {customer, department, fields, machines, priceGroup, project, workPlace, workType} =
        data;
      const machineuseSet = machines.map(
        ({machine, priceGroup: machinePriceGroup}): MachineUse => ({
          machine,
          priceGroup: machinePriceGroup,
          transporter: false,
        }),
      );
      const fielduseSet = fields
        .map(locationLookup)
        .filter(notUndefined)
        .map(
          (field): FieldUse => ({
            fieldAreaHa: field.fieldAreaHa,
            fieldCrop: field.fieldCrop,
            geojson: field.geojson,
            notes: "",
            relatedField: field.url,
          }),
        );
      newExternalTask(
        customer,
        project,
        workType,
        priceGroup,
        machineuseSet,
        workPlace,
        fielduseSet,
        department,
      );
    },
    [locationLookup, newExternalTask],
  );

  const showRouteFabButton = useMemo(() => {
    return customerSettings.routesEnabled && !!routePlanArray.find((routePlan) => routePlan.active);
  }, [customerSettings.routesEnabled, routePlanArray]);

  const handleTaskTableClick = useCallback(
    (taskURL: string) => {
      const id = urlToId(taskURL);
      dispatch(actions.go("/task/:id", {id}));
    },
    [dispatch],
  );

  const handleTaskTablePhotoClick = useCallback(
    (taskURL: string) => {
      const id = urlToId(taskURL);
      dispatch(actions.go("/task/:id", {id}, {tab: "photos"}));
    },
    [dispatch],
  );

  const handleWorkTypeFilterChange = useCallback(
    (selected: ReadonlySet<WorkTypeUrl>) => {
      setSelectedWorkTypeURLSet(selected);
      setTaskListVisibleWorkTypes([...selected]);
    },
    [setTaskListVisibleWorkTypes],
  );

  const handleEmployeeGroupFilterChange = useCallback(
    (selected: ReadonlySet<EmployeeGroupUrl>) => {
      setSelectedEmployeeGroupURLSet(selected);
      setTaskListVisibleEmployeeGroups([...selected]);
    },
    [setTaskListVisibleEmployeeGroups],
  );

  const handleDepartmentFilterChange = useCallback(
    (selected: ReadonlySet<string>) => {
      setSelectedDepartmentIdentifierSet(selected);
      setTaskListVisibleDepartments([...selected]);
    },
    [setTaskListVisibleDepartments],
  );

  const handleFilterClear = useCallback(() => {
    setSelectedWorkTypeURLSet(new Set());
    setSelectedEmployeeGroupURLSet(new Set());
    setSelectedDepartmentIdentifierSet(new Set());
    setTaskListVisibleWorkTypes([]);
    setTaskListVisibleEmployeeGroups([]);
    setTaskListVisibleDepartments([]);
  }, [
    setTaskListVisibleDepartments,
    setTaskListVisibleEmployeeGroups,
    setTaskListVisibleWorkTypes,
  ]);

  const handleRegisterAbsenceButton = useCallback(() => {
    const userIsOnlyMachineOperator = !currentRole || !currentRole.manager;

    setRegisterAbsenceDialogOpen(true);
    setRegisterAbsenceEmployee(
      !userIsOnlyMachineOperator && !(activeTab === "mine") ? null : currentUserURL,
    );
  }, [activeTab, currentRole, currentUserURL]);

  const userIsManager = currentRole && currentRole.manager;
  const userIsJobber = currentRole && currentRole.jobber;
  const userIsMechanic = currentRole && currentRole.mechanic;
  const userIsConsultant = currentRole && currentRole.consultant;

  const activateEmployeeGroups = useSelector(isEmployeeGroupsActivated);

  let filterButton;
  if (
    !bowser.mobile &&
    (customerSettings.enableOrderTaskDepartmentFilter ||
      customerSettings.enableOrderTaskWorkTypeFilter ||
      activateEmployeeGroups) &&
    activeTab !== "archive"
  ) {
    filterButton = (
      <span style={{display: "inline-block", verticalAlign: "top"}}>
        <FilterButton
          onSelectedDepartmentIdentifierSetChange={handleDepartmentFilterChange}
          onSelectedEmployeeGroupUrlSetChange={handleEmployeeGroupFilterChange}
          onSelectedWorkTypeURLSetChange={handleWorkTypeFilterChange}
          selectedDepartmentIdentifierSet={selectedDepartmentIdentifierSet}
          selectedEmployeeGroupUrlSet={selectedEmployeeGroupUrlSet}
          selectedWorkTypeURLSet={selectedWorkTypeURLSet}
        />
      </span>
    );
  }
  const right =
    activeTab !== "archive" ? (
      <>
        {filterButton}
        <AppbarSearchField onChange={handleFilterStringChange} value={q} />
      </>
    ) : undefined;

  const isMachineOperator = currentRole && currentRole.machineOperator;

  let speeddialButtons: FloatingActionButtonData[] = [];

  if (!userIsJobber) {
    if (activeTab === "mine") {
      speeddialButtons.push({
        buttonIcon: <HomeIcon />,
        name: "internal",
        onClick: setWorkTypeDialogOpenTrue,
        tooltipTitle: formatMessage({defaultMessage: "Intern"}),
      });
      if (!userIsMechanic) {
        if (
          customerSettings.adminCanCreateCustomerTask &&
          (customerSettings.machineOperatorCanCreateCustomerTask || userIsManager)
        ) {
          speeddialButtons.push({
            buttonIcon: <TagMultipleIcon />,
            name: "customer",
            onClick: handleCustomerFabButton,
            tooltipTitle: formatMessage({defaultMessage: "Kunde"}),
          });
        }
        if (showRouteFabButton) {
          speeddialButtons.push({
            buttonIcon: <RoutesIcon />,
            name: "route",
            onClick: setRoutePlanDialogOpenTrue,
            tooltipTitle: formatMessage({defaultMessage: "Rute"}),
          });
        }
      }
    } else if (activeTab === "customers") {
      if (
        customerSettings.adminCanCreateCustomerTask &&
        (customerSettings.machineOperatorCanCreateCustomerTask || userIsManager)
      ) {
        speeddialButtons.push({
          buttonIcon: <TagMultipleIcon />,
          name: "customer",
          onClick: handleCustomerFabButton,
          tooltipTitle: formatMessage({defaultMessage: "Kunde"}),
        });
      }
      if (showRouteFabButton) {
        speeddialButtons.push({
          buttonIcon: <RoutesIcon />,
          name: "route",
          onClick: setRoutePlanDialogOpenTrue,
          tooltipTitle: formatMessage({defaultMessage: "Rute"}),
        });
      }
    } else if (activeTab === "internal") {
      speeddialButtons.push({
        buttonIcon: <HomeIcon />,
        name: "add",
        onClick: handleInternalButton,
        tooltipTitle: formatMessage({defaultMessage: "Intern"}),
      });
    } else if (activeTab !== "archive" && customerSettings.adminCanCreateCustomerTask) {
      speeddialButtons.push({
        buttonIcon: <TagMultipleIcon />,
        name: "add",
        onClick: handleCustomerFabButton,
        tooltipTitle: formatMessage({defaultMessage: "Kultur"}),
      });
    }

    if (
      currentUserMayRegisterOwnAbsence(customerSettings, currentRole) &&
      activeTab !== "archive"
    ) {
      speeddialButtons.push({
        buttonIcon: <AccountAlertIcon />,
        name: "Absence",
        onClick: handleRegisterAbsenceButton,
        tooltipTitle: formatMessage({defaultMessage: "Fravær"}),
      });
    }
  } else if (customerSettings.jobberRoleCanCreateInternalTasks) {
    speeddialButtons.push({
      buttonIcon: <HomeIcon />,
      name: "internal",
      onClick: setWorkTypeDialogOpenTrue,
      tooltipTitle: formatMessage({defaultMessage: "Intern"}),
    });
  }

  let readyForBillingTab;
  if (
    userIsManager &&
    !customerSettings.hideTasklistBillingTab &&
    !customerSettings.useApproveReport
  ) {
    readyForBillingTab = (
      <Tab
        label={
          bowser.mobile
            ? formatMessage(messages.readyForBillingShort)
            : formatMessage(messages.readyForBilling)
        }
        value="readyForBilling"
      />
    );
  }
  let tabs: React.JSX.Element | undefined;
  if (
    (!userIsManager &&
      !customerSettings.showTaskArchiveToMachineOperators &&
      customerSettings.machineOperatorsCanOnlySeeTheirOwnTasks) ||
    userIsJobber ||
    userIsMechanic
  ) {
    tabs = undefined;
  } else {
    let myTasksTab;
    if (isMachineOperator) {
      myTasksTab = <Tab label={formatMessage(messages.myTasks)} value="mine" />;
    }
    let customersTab;
    let culturesTab;
    let internalTab;
    if (!customerSettings.machineOperatorsCanOnlySeeTheirOwnTasks || userIsManager) {
      if (customerSettings.externalTaskCustomer) {
        customersTab = <Tab label={formatMessage(messages.customersTasks)} value="customers" />;
      }
      if (customerSettings.externalTaskCulture) {
        culturesTab = <Tab label={formatMessage(messages.culturesTasks)} value="cultures" />;
      }
      internalTab = (
        <Tab
          label={
            bowser.mobile
              ? formatMessage(messages.internalTasksShort)
              : formatMessage(messages.internalTasks)
          }
          value="internal"
        />
      );
    }
    tabs = (
      <Tabs
        onChange={handleTabChange}
        value={activeTab}
        variant={bowser.mobile ? "fullWidth" : "standard"}
      >
        {myTasksTab}
        {internalTab}
        {customersTab}
        {culturesTab}
        {readyForBillingTab}
        {userIsManager || customerSettings.showTaskArchiveToMachineOperators ? (
          <Tab label={formatMessage(messages.archive)} value="archive" />
        ) : null}
      </Tabs>
    );
  }

  const dialogs = [
    userIsMechanic ? (
      <ConnectedInternalWorkshopWorkTypeDialog
        key="internal-work-type-dialog"
        onCancel={setWorkTypeDialogOpenFalse}
        onOk={handleWorkTypeDialogOk}
        open={workTypeDialogOpen}
      />
    ) : (
      <ConnectedInternalWorkTypeDialog
        key="internal-work-type-dialog"
        onCancel={setWorkTypeDialogOpenFalse}
        onOk={handleWorkTypeDialogOk}
        open={workTypeDialogOpen}
      />
    ),
  ];
  if (customerSettings.orderEntryAutoOpenCustomerSelection) {
    dialogs.push(
      <CustomerSelectCreateDialog
        key="customer-dialog"
        onCancel={setCustomerDialogOpenFalse}
        onOk={handleCustomerDialogOk}
        open={customerDialogOpen}
      />,
    );
  }
  if (customerSettings.customerTaskCreationWizard) {
    dialogs.push(
      <CustomerTaskCreationWrapper
        key="customer-task-creation-wizard"
        onCustomerTaskCreation={handleCustomerTaskCreationWizardOk}
        ref={taskCreationWizardControl}
      />,
    );
  }
  if (showRouteFabButton) {
    dialogs.push(
      <ConnectedRoutePlanDialog
        key="route-dialog"
        onCancel={setRoutePlanDialogOpenFalse}
        onOk={handleRoutePlanDialogOk}
        open={routePlanDialogOpen}
      />,
    );
  }

  const tab = TAB_NAMES.includes(activeTab) ? activeTab : "mine";
  let filteringBlock;
  if (
    (selectedWorkTypeURLSet.size ||
      selectedDepartmentIdentifierSet.size ||
      selectedEmployeeGroupUrlSet.size) &&
    activeTab !== "archive"
  ) {
    filteringBlock = (
      <FilterBar
        onRequestFilterClear={handleFilterClear}
        selectedDepartmentIdentifierSet={selectedDepartmentIdentifierSet}
        selectedEmployeeGroupURLSet={selectedEmployeeGroupUrlSet}
        selectedWorkTypeURLSet={selectedWorkTypeURLSet}
      />
    );
  }

  const [dataLoaded, setDataLoaded] = useState(activeTab !== "archive");
  const setDataLoadedTrue = useCallWithTrue(setDataLoaded);

  const [registerAbsenceDialogOpen, setRegisterAbsenceDialogOpen] = useState(false);
  const [registerAbsenceEmployee, setRegisterAbsenceEmployee] = useState<UserUrl | null>(null);

  const batchOperationState = useBatchOperationState<TaskTableDataType>();
  const {currentBatch, currentBatchOperation, showSelectBatchOperationMenu} = batchOperationState;
  const [projectSelectModal, promptForProject] = useModal(ConnectedProjectDialog);
  const [deleteConfirmModal, promptForDelete] = useModal(DeleteConfirmationDialog);

  const batchOperationChangeProject: BatchOperation<TaskTableDataType> = {
    accept: async (selectedTasks) => {
      const customerURL =
        selectedTasks.length > 0 && selectedTasks[0].customerId
          ? instanceURL("customer", selectedTasks[0].customerId)
          : null;
      const newProject = await promptForProject({customerURL, suggestRecentlyUsed: true});
      if (newProject) {
        selectedTasks.forEach((task) =>
          dispatch(
            actions.update(task.key, [
              {
                member: "project",
                value: newProject,
              },
            ]),
          ),
        );
      }
    },
    canCheck: (selectedTasks, task) =>
      !task.validatedAndRecorded &&
      (selectedTasks.length ? selectedTasks[0].customerId === task.customerId : true),
    icon: <FolderOpenIcon />,
    label:
      customerSettings.projectLabelVariant === "PROJECT"
        ? formatMessage({defaultMessage: "Skift projekt"})
        : formatMessage({defaultMessage: "Skift sag"}),
    name: "move",
  };

  const batchOperationDelete: BatchOperation<TaskTableDataType> = {
    accept: async (selectedTasks) => {
      const shouldDelete = await promptForDelete({tasks: selectedTasks});
      if (shouldDelete) {
        selectedTasks.forEach((task) => {
          dispatch(actions.remove(task.key));
        });
      }
    },
    canCheck: (_selectedTasks, task) => !task.validatedAndRecorded && !task.reportApproved,
    icon: <TrashCanIcon />,
    label: formatMessage({defaultMessage: "Slet opgaver"}),
    name: "delete",
  };

  const batchOperations: BatchOperation<TaskTableDataType>[] = [];
  if (currentUserCanManageProjects && customerSettings.enableProjects) {
    batchOperations.push(batchOperationChangeProject);
  }
  if (userIsConsultant) {
    batchOperations.push(batchOperationDelete);
  }

  const batchCheckboxRenderer = useBatchCheckboxRenderer(batchOperationState);
  const [
    startBatchButton,
    stopBatchOperationButton,
    acceptBatchOperationButton,
    batchOperationButtons,
  ] = useBatchOperationFloatingActionButtons(batchOperations, batchOperationState);

  if (activeTab !== "archive" && batchOperations.length) {
    speeddialButtons.push(startBatchButton);
  }

  if (showSelectBatchOperationMenu) {
    speeddialButtons = [...batchOperationButtons, stopBatchOperationButton];
  }

  if (currentBatchOperation) {
    speeddialButtons = [stopBatchOperationButton];
    if (currentBatch.length > 0) {
      speeddialButtons.unshift(acceptBatchOperationButton);
    }
  }
  const batchInProgress = showSelectBatchOperationMenu || !!currentBatchOperation;
  const speeddial = (
    <FloatingActionButtons
      buttons={speeddialButtons}
      disabled={batchInProgress && currentBatch.length > 0}
      forcedOpen={batchInProgress}
      name="task list"
      variant="page"
    />
  );

  const {boundCreate, boundRemove, boundUpdate} = useBoundActions();

  const daysAbsenceArray = useSelector(getDaysAbsenceArray);
  const extraHalfHolidaysPerRemunerationGroup = useSelector(
    getExtraHalfHolidaysPerRemunerationGroup,
  );
  const extraHolidaysPerRemunerationGroup = useSelector(getExtraHolidaysPerRemunerationGroup);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);

  const handleRegisterAbsenceDialogCancel = useCallback(() => {
    setRegisterAbsenceDialogOpen(false);
    setRegisterAbsenceEmployee(null);
  }, []);

  const handleRegisterAbsenceMachineOperatorDialogOk = useCallback((url: UserUrl) => {
    setRegisterAbsenceEmployee(url);
  }, []);

  const handleRegisterAbsenceDialogOk = useCallback(
    (data: {
      absenceType: string;
      employeeReason: string;
      fromDate: string;
      fromTime: string | null;
      note: string;
      onlyWeekdays: boolean | undefined;
      toDate: string;
      toTime: string | null;
    }) => {
      if (!registerAbsenceEmployee) {
        return;
      }
      const {absenceType, employeeReason, fromDate, fromTime, note, onlyWeekdays, toDate, toTime} =
        data;

      registerAbsence(
        {
          absenceType,
          employeeReason,
          fromDate,
          fromTime,
          note,
          onlyWeekdays,
          profile: userUserProfileLookup(registerAbsenceEmployee),
          toDate,
          toTime,
          user: registerAbsenceEmployee,
        },
        {
          create: boundCreate,
          extraHalfHolidaysPerRemunerationGroup,
          extraHolidaysPerRemunerationGroup,
          remove: boundRemove,
          update: boundUpdate,
        },
        daysAbsenceArray,
        customerSettings,
      );
      setRegisterAbsenceDialogOpen(false);
      setRegisterAbsenceEmployee(null);
    },
    [
      boundCreate,
      boundRemove,
      boundUpdate,
      customerSettings,
      daysAbsenceArray,
      extraHalfHolidaysPerRemunerationGroup,
      extraHolidaysPerRemunerationGroup,
      registerAbsenceEmployee,
      userUserProfileLookup,
    ],
  );

  return (
    <PageLayout
      dialogs={dialogs}
      performScrolling={dataLoaded}
      speedDial={speeddial}
      tabs={tabs}
      toolbar={
        <MenuToolbar
          onMenuButton={onMenuButton}
          rightElement={right}
          title={formatMessage(messages.title)}
        />
      }
      withBottomScrollPadding
    >
      {filteringBlock}
      {activeTab === "archive" ? (
        <TaskArchive onDataLoaded={setDataLoadedTrue} />
      ) : (
        <ConnectedTaskTable
          batchCheckboxRenderer={batchCheckboxRenderer}
          batchOperationActive={!!currentBatchOperation}
          filterString={q.trim()}
          onClick={handleTaskTableClick}
          onPhotoClick={handleTaskTablePhotoClick}
          selectedDepartmentIdentifierSet={selectedDepartmentIdentifierSet}
          selectedEmployeeGroupsURLSet={selectedEmployeeGroupUrlSet}
          selectedWorkTypeURLSet={selectedWorkTypeURLSet}
          tab={tab}
        />
      )}
      <ConnectedUserDialog
        onCancel={handleRegisterAbsenceDialogCancel}
        onOk={handleRegisterAbsenceMachineOperatorDialogOk}
        open={registerAbsenceDialogOpen && !registerAbsenceEmployee}
        title={
          <FormattedMessage
            defaultMessage="Indmeld fravær for ansat"
            id="register-absence-button.report-absence-employee"
          />
        }
      />

      <RegisterAbsenceDialog
        currentRole={currentRole || undefined}
        currentUserURL={currentUserURL || undefined}
        customerSettings={customerSettings}
        onCancel={handleRegisterAbsenceDialogCancel}
        onOk={handleRegisterAbsenceDialogOk}
        open={registerAbsenceDialogOpen && !!registerAbsenceEmployee}
        userProfile={
          registerAbsenceEmployee ? userUserProfileLookup(registerAbsenceEmployee) : undefined
        }
      />
      {projectSelectModal}
      {deleteConfirmModal}
    </PageLayout>
  );
});
