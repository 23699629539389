import {Role} from "@co-common-libs/resources";
import {TrimTextField} from "@co-frontend-libs/components";
import {AppState, getCurrentRole, getToken} from "@co-frontend-libs/redux";
import {Button} from "@material-ui/core";
import {red} from "@material-ui/core/colors";
import {PageLayout} from "app-components";
import {PureComponent} from "app-utils";
import {bind} from "bind-decorator";
import {globalConfig} from "frontend-global-config";
import React from "react";
import {defineMessages, IntlContext} from "react-intl";
import {connect} from "react-redux";
import {createStructuredSelector} from "reselect";

const messages = defineMessages({
  mirror: {
    defaultMessage: "Spejling",
    id: "mirror.title.mirror",
  },
  passwordHint: {defaultMessage: "Adgangskode", id: "mirror.label.password"},
  serverHint: {defaultMessage: "Server", id: "mirror.label.server"},
  submitLabel: {defaultMessage: "Send", id: "mirror.label.submit"},
});

interface MirrorStateProps {
  currentRole: Role | null;
  token: string | null;
}

type MirrorProps = MirrorStateProps;

interface MirrorState {
  error: string;
  password: string;
  response: string;
  server: string;
}

class Mirror extends PureComponent<MirrorProps, MirrorState> {
  static contextType = IntlContext;
  context!: React.ContextType<typeof IntlContext>;
  state: MirrorState = {
    error: "",
    password: "",
    response: "",
    server: "",
  };
  @bind
  handleKeyDown(event: React.KeyboardEvent<unknown>): void {
    if (event.key === "Enter") {
      this.handleSubmit(event);
    }
  }
  @bind
  handlePasswordChange(value: string): void {
    this.setState({error: "", password: value, response: ""});
  }
  @bind
  handleServerChange(value: string): void {
    this.setState({error: "", response: "", server: value});
  }
  @bind
  handleSubmit(_event: unknown): void {
    const {password, server} = this.state;
    if (!server || !password) {
      return;
    }
    this.setState({error: "", response: ""});
    const mirrorSubmitURL = `${globalConfig.baseURL}/api/mirror_send/`;
    fetch(mirrorSubmitURL, {
      body: JSON.stringify({password, server}),
      headers: {
        authorization: `Token ${this.props.token}`,
        "Content-Type": "application/json",
      },
      method: "post",
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        this.setState({response: data.message});
        return;
      })
      .catch((error) => {
        this.setState({
          error: `${error}`,
        });
      });
  }
  render(): React.JSX.Element | null {
    const {formatMessage} = this.context;
    const role = this.props.currentRole;
    const consultant = !!role && role.consultant;
    if (!consultant) {
      return null;
    }
    let errorElement;
    if (this.state.error) {
      errorElement = (
        <div style={{color: red[900]}}>
          <br />
          {this.state.error}
        </div>
      );
    }
    let responseElement;
    if (this.state.response) {
      responseElement = (
        <div>
          <br />
          {this.state.response}
        </div>
      );
    }
    return (
      <PageLayout toolbar={formatMessage(messages.mirror)} withPadding>
        <form onSubmit={this.handleSubmit}>
          <TrimTextField
            label={formatMessage(messages.serverHint)}
            margin="dense"
            onChange={this.handleServerChange}
            onKeyDown={this.handleKeyDown}
            type="text"
            value={this.state.server}
            variant="outlined"
          />
          <br />
          <TrimTextField
            label={formatMessage(messages.passwordHint)}
            margin="dense"
            onChange={this.handlePasswordChange}
            onKeyDown={this.handleKeyDown}
            type="password"
            value={this.state.password}
            variant="outlined"
          />
          <br />
          {errorElement}
          {responseElement}
          <Button
            color="secondary"
            disabled={!this.state.server || !this.state.password}
            onClick={this.handleSubmit}
            variant="contained"
          >
            {formatMessage(messages.submitLabel)}
          </Button>
        </form>
      </PageLayout>
    );
  }
}

const ConnectedMirror = connect<MirrorStateProps, object, object, AppState>(
  createStructuredSelector<AppState, MirrorStateProps>({
    currentRole: getCurrentRole,
    token: getToken,
  }),
  {},
)(Mirror);

export {ConnectedMirror as Mirror};
