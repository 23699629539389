import {ImportPreviewWorkType} from "@co-common-libs/resources-utils";
import {
  AwaitingBackendRequest,
  WorkTypeTypeChoices,
  WorkTypeTypeSelectionDialog,
} from "@co-frontend-libs/components";
import {actions, getCustomerSettings, getWorkTypeArray} from "@co-frontend-libs/redux";
import {ResponseWithData, useCallWithFalse} from "@co-frontend-libs/utils";
import {Fab} from "@material-ui/core";
import {importResourceUrl} from "api-endpoint-urls";
import {SyncedResourceHandleKey} from "integration-constants";
import CloudDownloadIcon from "mdi-react/CloudDownloadIcon";
import React, {useCallback, useMemo, useState} from "react";
import {useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {ImportFabProps} from "../import-fab-types";
import {ImportWorkTypesPreviewDialog} from "./import-work-types-preview-dialog";

export const ImportWorkTypesFab = React.memo(function ImportWorkTypesFab(
  props: ImportFabProps,
): React.JSX.Element {
  const {buttonStyle, size = "large"} = props;

  const {importExternalWorkTypesFromProductGroups, importInternalWorkTypesFromProducts} =
    useSelector(getCustomerSettings);

  const [workTypeImportSelectionDialogOpen, setWorkTypeImportSelectionDialogOpen] = useState(false);
  const setTypeSelectionDialogOpenFalse = useCallWithFalse(setWorkTypeImportSelectionDialogOpen);

  const [previewWorkTypesDialogOpen, setPreviewWorkTypesDialogOpen] = useState(false);
  const setPreviewWorkTypesDialogOpenFalse = useCallWithFalse(setPreviewWorkTypesDialogOpen);

  const [importType, setImportType] = useState<
    Extract<SyncedResourceHandleKey, "externalWorkType" | "internalWorkType">
  >(importInternalWorkTypesFromProducts ? "internalWorkType" : "externalWorkType");

  const intl = useIntl();

  const [executeImport, setExecuteImport] = useState(false);

  const dispatch = useDispatch();

  const typeSelectionDialogOk = useCallback((workTypeType: WorkTypeTypeChoices) => {
    setWorkTypeImportSelectionDialogOpen(false);
    setImportType(workTypeType === "external" ? "externalWorkType" : "internalWorkType");
    setPreviewWorkTypesDialogOpen(true);
  }, []);

  const [requestData, setRequestData] = useState<any>(null);

  const handleImportOk = useCallback((workTypes: ImportPreviewWorkType[]) => {
    setPreviewWorkTypesDialogOpen(false);
    setExecuteImport(true);

    setRequestData({
      workTypes: workTypes.map((wt) => ({
        identifier: wt.identifier,
        name: wt.name,
        remoteUrl: wt.remoteUrl,
        salesAccount: wt.salesAccount,
      })),
    });
  }, []);

  const handleImportSuccess = useCallback(
    (response: ResponseWithData | undefined) => {
      if (response && response.data) {
        dispatch(actions.addToOffline(response.data));
      }

      setExecuteImport(false);
    },
    [dispatch],
  );

  const handleFabClick = useCallback(() => {
    const askForImportType =
      importInternalWorkTypesFromProducts && importExternalWorkTypesFromProductGroups;
    if (askForImportType) {
      setWorkTypeImportSelectionDialogOpen(true);
    } else {
      setPreviewWorkTypesDialogOpen(true);
    }
  }, [importExternalWorkTypesFromProductGroups, importInternalWorkTypesFromProducts]);

  const workTypeArray = useSelector(getWorkTypeArray);

  const existingWorkTypeRemoteUrls = useMemo(
    () => new Set(workTypeArray.map((w) => w.remoteUrl)),
    [workTypeArray],
  );

  return (
    <>
      <Fab onClick={handleFabClick} size={size} style={buttonStyle}>
        <CloudDownloadIcon />
      </Fab>
      <ImportWorkTypesPreviewDialog
        filter={existingWorkTypeRemoteUrls}
        onCancel={setPreviewWorkTypesDialogOpenFalse}
        onOk={handleImportOk}
        open={previewWorkTypesDialogOpen}
        type={importType}
      />
      <WorkTypeTypeSelectionDialog
        onCancel={setTypeSelectionDialogOpenFalse}
        onOk={typeSelectionDialogOk}
        open={workTypeImportSelectionDialogOpen}
        title={intl.formatMessage({defaultMessage: "Hvilken type område vil du importere?"})}
      />
      {requestData && (
        <AwaitingBackendRequest
          apiUrl={importResourceUrl("workType")}
          data={requestData}
          errorTitle={intl.formatMessage({defaultMessage: "Import fejlede"})}
          execute={executeImport}
          loadingTitle={intl.formatMessage({defaultMessage: "Importerer områder"})}
          method="POST"
          onSuccess={handleImportSuccess}
        />
      )}
    </>
  );
});
