import {SyncedResourceHandleKey, SyncedResourceName} from "integration-constants";
import {kebabCase} from "lodash";
import {Branded} from "shared-types";

export type ApiUrl = Branded<string, "ApiUrl">;

const API_BASE = "/api";
const INTEGRATION_BASE = `${API_BASE}/integration`;

export function importResourceUrl(resource: SyncedResourceName): ApiUrl {
  return `${INTEGRATION_BASE}/import/${kebabCase(resource)}` as ApiUrl;
}

export function importPreviewUrl(resourceHandle: SyncedResourceHandleKey): ApiUrl {
  return `${INTEGRATION_BASE}/import-preview/${kebabCase(resourceHandle)}` as ApiUrl;
}

export function createResourceUrl(resource: SyncedResourceName): ApiUrl {
  return `${INTEGRATION_BASE}/create/${kebabCase(resource)}` as ApiUrl;
}

export function updateResourceUrl(resource: SyncedResourceName, id: string): ApiUrl {
  return `${INTEGRATION_BASE}/update/${kebabCase(resource)}/${id}` as ApiUrl;
}

export function updateAndUploadResourceUrl(resource: SyncedResourceName, id: string): ApiUrl {
  return `${INTEGRATION_BASE}/update-and-upload/${kebabCase(resource)}/${id}` as ApiUrl;
}

export const sendTasksUrl = `${INTEGRATION_BASE}/send-tasks` as ApiUrl;
export const sendOrderUrl = `${INTEGRATION_BASE}/send-order` as ApiUrl;

export const productUrl = `${API_BASE}/products/` as ApiUrl;
export const productPhotoUrl = `${API_BASE}/productphoto/` as ApiUrl;

export const productGroupUrl = `${API_BASE}/productgroups/` as ApiUrl;
export const productGroupPhotoUrl = `${API_BASE}/productgroupphoto/` as ApiUrl;
