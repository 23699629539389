import {Match, SearchField} from "app-utils";
import {MdiReactIconProps} from "mdi-react";
import DomainIcon from "mdi-react/DomainIcon";
import HistoryIcon from "mdi-react/HistoryIcon";
import StarIcon from "mdi-react/StarIcon";
import WarehouseIcon from "mdi-react/WarehouseIcon";

export const entryCategories = [
  "recentlyUsed",
  "favorite",
  "storage",
  "standard",
  "otherCustomer",
] as const;

export type EntryCategoryCode = (typeof entryCategories)[number];

export const entryCategoryIcons: Partial<{
  [Code in EntryCategoryCode]: React.ComponentType<MdiReactIconProps>;
}> = {
  favorite: StarIcon,
  otherCustomer: DomainIcon,
  recentlyUsed: HistoryIcon,
  storage: WarehouseIcon,
};

export const categoriesWithIcons = new Set(
  entryCategories.filter((entryCategory) => entryCategoryIcons[entryCategory]),
);

export const categoryOrder: {
  [Code in EntryCategoryCode]: number;
} = Object.fromEntries(entryCategories.map((code, index) => [code, index])) as {
  [Code in EntryCategoryCode]: number;
};

export interface EntryData<Identifier extends string> {
  readonly category: EntryCategoryCode;
  readonly color?: string;
  readonly conflict?: boolean | undefined;
  readonly disabled?: boolean;
  readonly exactMatchValue?: string;
  readonly identifier: Identifier;
  readonly photoUrl?: string;
  readonly primaryText: string;
  readonly priority?: boolean;
  readonly readonly?: boolean;
  readonly recentlyUsedSortKey?: number;
  readonly searchFields: readonly SearchField[];
  readonly secondaryText?: string;
}

export interface SearchResultEntryData<Identifier extends string> extends EntryData<Identifier> {
  readonly highlight?: boolean;
  readonly matches: readonly Match[];
}

export interface MaybeSearchResultEntryData<Identifier extends string>
  extends EntryData<Identifier> {
  readonly highlight?: boolean;
  readonly matches?: readonly Match[];
}

export type FilterVariant = "AND" | "OR";
