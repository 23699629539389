import {User, UserProfile} from "@co-common-libs/resources";
import {isValidEmail, notNull} from "@co-common-libs/utils";
import {ResponsiveDialog, TrimTextField} from "@co-frontend-libs/components";
import {
  getCurrentRole,
  getCurrentUser,
  getExtendedCustomerSettings,
  getUserArray,
} from "@co-frontend-libs/redux";
import {useCallWithFalse, useCallWithTrue, useResettingState} from "@co-frontend-libs/utils";
import {DialogContent} from "@material-ui/core";
import {postalCodes} from "app-utils";
import React, {useCallback, useMemo} from "react";
import {useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ProcessingDialog} from "../processing-dialog";
import {UserCreateData, UserEditData, UserProfileData} from "./types";

interface CreateProps {
  data?: undefined;
  onCancel: () => void;
  onOk: (userData: UserCreateData, userProfileData: UserProfileData) => Promise<void>;
  open: boolean;
  processingTitle: string;
  title: string;
}

interface EditProps {
  data: {
    user: Readonly<User>;
    userProfile: Readonly<UserProfile>;
  };
  onCancel: () => void;
  onOk: (userData: UserEditData, userProfileData: UserProfileData) => void;
  open: boolean;
  processingTitle?: undefined;
  title: string;
}

type UserCreateEditDialogProps = CreateProps | EditProps;

export const UserCreateEditDialog = function UserCreateEditDialog(
  props: UserCreateEditDialogProps,
): React.JSX.Element | null {
  const {data, onCancel, onOk, open, title} = props;
  const user = data?.user;
  const userProfile = data?.userProfile;
  const intl = useIntl();
  const customerSettings = useSelector(getExtendedCustomerSettings);
  const userArray = useSelector(getUserArray);
  const currentRole = useSelector(getCurrentRole);
  const currentUser = useSelector(getCurrentUser);

  const {
    userProfiles: {canEditField: unboundCanEditField, canSeeField: unboundCanSeeField},
  } = customerSettings;

  const canSeeField = currentUser ? unboundCanSeeField.bind(null, null, currentUser) : () => true;
  const canEditField =
    data?.userProfile && currentUser
      ? unboundCanEditField.bind(null, data.userProfile, currentUser)
      : () => true;

  const [savingPromise, setSavingPromise] = useResettingState<Promise<void> | null>(null, open);

  const [saveInProgress, setSaveInProgress] = useResettingState<boolean>(false, open);
  const setSaveInProgressTrue = useCallWithTrue(setSaveInProgress);
  const setSaveInProgressFalse = useCallWithFalse(setSaveInProgress);

  const [loginIdentifier, setLoginIdentifier] = useResettingState(
    user?.loginIdentifier ?? "",
    open,
  );
  const [password, setPassword] = useResettingState("", open);
  const [address, setAddress] = useResettingState(userProfile?.address ?? "", open);
  const [alias, setAlias] = useResettingState(userProfile?.alias ?? "", open);
  const [cellphone, setCellphone] = useResettingState(userProfile?.cellphone ?? "", open);
  const [cellphoneExtra, setCellphoneExtra] = useResettingState(
    userProfile?.cellphoneExtra ?? "",
    open,
  );
  const [city, setCity] = useResettingState(userProfile?.city ?? "", open);
  const [email, setEmail] = useResettingState(userProfile?.email ?? "", open);
  const [employeeNumber, setEmployeeNumber] = useResettingState(
    userProfile?.employeeNumber ?? "",
    open,
  );
  const [name, setName] = useResettingState(userProfile?.name ?? "", open);
  const [note, setNote] = useResettingState(userProfile?.note ?? "", open);
  const [postalCode, setPostalCode] = useResettingState(userProfile?.postalCode ?? "", open);

  const handleLoginIdentifierFieldBlur = useCallback(() => {
    if (alias === "") {
      setAlias(loginIdentifier.toLocaleUpperCase());
    }
  }, [alias, loginIdentifier, setAlias]);

  const handlePostalCodeChange = useCallback(
    (value: string): void => {
      const cityForPostalCode = postalCodes[value];
      setPostalCode(value);
      if (cityForPostalCode) {
        setCity(cityForPostalCode);
      }
    },
    [setCity, setPostalCode],
  );

  const loginIdentifierDuplicate: boolean = useMemo(() => {
    if (!loginIdentifier) {
      return false;
    } else {
      const upperLoginIdentifier = loginIdentifier.toUpperCase();
      return userArray.some(
        (otherUser) =>
          otherUser.loginIdentifier === upperLoginIdentifier && otherUser.url !== user?.url,
      );
    }
  }, [loginIdentifier, user?.url, userArray]);

  const emailIsValidOrEmpty = useMemo(() => (email ? isValidEmail(email) : true), [email]);

  const handleOk = useCallback(() => {
    const upperLoginIdentifier = loginIdentifier.toUpperCase();
    const userProfileData = {
      address,
      alias,
      cellphone,
      cellphoneExtra,
      city,
      email,
      employeeNumber,
      name,
      note,
      postalCode,
    };
    let processUser: Promise<void> | void;
    if (data === undefined) {
      processUser = onOk({loginIdentifier: upperLoginIdentifier, password}, userProfileData);
    } else {
      processUser = onOk({loginIdentifier: upperLoginIdentifier}, userProfileData);
    }

    if (processUser !== undefined) {
      setSavingPromise(processUser);
      setSaveInProgressTrue();
    }
  }, [
    address,
    alias,
    cellphone,
    cellphoneExtra,
    city,
    data,
    email,
    employeeNumber,
    loginIdentifier,
    name,
    note,
    onOk,
    password,
    postalCode,
    setSaveInProgressTrue,
    setSavingPromise,
  ]);

  if (!currentRole || !currentUser) {
    return null;
  }

  const editingExistingInstance = data !== undefined;

  const c5AgromatSync = customerSettings.c5Sync && customerSettings.navSyncProfile !== "bejstrupm";

  const elements = [
    canSeeField("loginIdentifier") ? (
      <TrimTextField
        autoFocus
        error={loginIdentifierDuplicate}
        fullWidth
        helperText={
          loginIdentifierDuplicate
            ? intl.formatMessage({
                defaultMessage: "Der eksisterer allerede en bruger med dette brugernavn",
              })
            : undefined
        }
        key="loginIdentifier"
        label={intl.formatMessage({defaultMessage: "Brugernavn"})}
        margin="dense"
        onBlur={handleLoginIdentifierFieldBlur}
        onChange={setLoginIdentifier}
        readonly={!canEditField("loginIdentifier")}
        required
        value={loginIdentifier}
        variant="outlined"
      />
    ) : null,
    !editingExistingInstance ? (
      <TrimTextField
        autoComplete="new-password"
        fullWidth
        key="password"
        label={intl.formatMessage({defaultMessage: "Adgangskode"})}
        margin="dense"
        onChange={setPassword}
        required
        type="password"
        value={password}
        variant="outlined"
      />
    ) : null,
    canSeeField("alias") ? (
      <TrimTextField
        fullWidth
        helperText={
          c5AgromatSync
            ? intl.formatMessage({
                defaultMessage: "Initialer som de står i C5",
              })
            : undefined
        }
        key="alias"
        label={intl.formatMessage({defaultMessage: "Initialer"})}
        margin="dense"
        onChange={setAlias}
        readonly={!canEditField("alias")}
        required
        value={alias}
        variant="outlined"
      />
    ) : null,
    canSeeField("name") ? (
      <TrimTextField
        fullWidth
        key="name"
        label={intl.formatMessage({defaultMessage: "Navn"})}
        margin="dense"
        onChange={setName}
        readonly={!canEditField("name")}
        value={name}
        variant="outlined"
      />
    ) : null,
    canSeeField("employeeNumber") ? (
      <TrimTextField
        fullWidth
        key="employeeNumber"
        label={intl.formatMessage({defaultMessage: "Medarbejdernummer"})}
        margin="dense"
        onChange={setEmployeeNumber}
        readonly={!canEditField("employeeNumber")}
        value={employeeNumber}
        variant="outlined"
      />
    ) : null,
    canSeeField("address") ? (
      <TrimTextField
        fullWidth
        key="address"
        label={intl.formatMessage({defaultMessage: "Adresse"})}
        margin="dense"
        onChange={setAddress}
        readonly={!canEditField("address")}
        value={address}
        variant="outlined"
      />
    ) : null,
    canSeeField("postalCode") ? (
      <TrimTextField
        fullWidth
        key="postalCode"
        label={intl.formatMessage({defaultMessage: "Postnummer"})}
        margin="dense"
        onChange={handlePostalCodeChange}
        readonly={!canEditField("postalCode")}
        value={postalCode}
        variant="outlined"
      />
    ) : null,
    canSeeField("city") ? (
      <TrimTextField
        fullWidth
        key="city"
        label={intl.formatMessage({defaultMessage: "By"})}
        margin="dense"
        onChange={setCity}
        readonly={!canEditField("city")}
        value={city}
        variant="outlined"
      />
    ) : null,
    canSeeField("email") ? (
      <TrimTextField
        error={!emailIsValidOrEmpty}
        fullWidth
        helperText={
          !emailIsValidOrEmpty
            ? intl.formatMessage({
                defaultMessage: "E-mailadressen er ikke gyldig",
              })
            : undefined
        }
        key="email"
        label={intl.formatMessage({defaultMessage: "E-mail"})}
        margin="dense"
        onChange={setEmail}
        readonly={!canEditField("email")}
        variant="outlined"
      />
    ) : null,
    canSeeField("cellphone") ? (
      <TrimTextField
        fullWidth
        key="cellphone"
        label={intl.formatMessage({defaultMessage: "Mobil"})}
        margin="dense"
        onChange={setCellphone}
        readonly={!canEditField("cellphone")}
        variant="outlined"
      />
    ) : null,
    canSeeField("cellphoneExtra") ? (
      <TrimTextField
        fullWidth
        key="cellphoneExtra"
        label={intl.formatMessage({
          defaultMessage: "Mobil (Kun synlig for administrationen)",
        })}
        margin="dense"
        onChange={setCellphoneExtra}
        readonly={!canEditField("cellphoneExtra")}
        value={cellphoneExtra}
        variant="outlined"
      />
    ) : null,
    canSeeField("note") ? (
      <TrimTextField
        fullWidth
        key="note"
        label={intl.formatMessage({
          defaultMessage: "Note",
        })}
        margin="dense"
        multiline
        onChange={setNote}
        readonly={!canEditField("note")}
        value={note}
        variant="outlined"
      />
    ) : null,
  ].filter(notNull);

  const hasRequiredElements = elements.some((element) => element.props.required);
  const dataIsValid = !elements.some(
    (element) => element.props.error || (element.props.required && !element.props.value),
  );

  return (
    <>
      {savingPromise ? (
        <ProcessingDialog
          onClose={setSaveInProgressFalse}
          processPromise={savingPromise}
          title={props.processingTitle || title}
        />
      ) : null}

      <ResponsiveDialog
        okDisabled={!dataIsValid}
        onCancel={onCancel}
        onOk={handleOk}
        open={open && !saveInProgress}
        requiredFieldsHelperText={hasRequiredElements}
        title={title}
      >
        <DialogContent>{elements}</DialogContent>
      </ResponsiveDialog>
    </>
  );
};
