import {Project} from "@co-common-libs/resources";
import {
  getContactLookup,
  getCustomerLookup,
  getCustomerSettings,
  getLocationLookup,
  getUserUserProfileLookup,
} from "@co-frontend-libs/redux";
import {Box, Card, CardContent, CardHeader, Divider, Grid, Typography} from "@material-ui/core";
import React, {useMemo} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {CustomerFileListCard} from "../customer-file-list-card";
import {ContactInfo, LocationInfo} from "../info-blocks";
import {UserInfo} from "../info-blocks/user-info";
import {ProjectFilesCard} from "../project-files-card/project-files-card";
import {ProjectDeleteButton} from "./project-delete-button";
import {ProjectEditButton} from "./project-edit-button";
import {ProjectNotes} from "./project-notes";

interface ProjectDetailsProps {
  project: Project;
}

export const ProjectDetails = React.memo(function ProjectDetails(
  props: ProjectDetailsProps,
): string | React.JSX.Element {
  const {project} = props;
  const intl = useIntl();

  const customerLookup = useSelector(getCustomerLookup);
  const customer = customerLookup(project.customer);
  const {projectLabelVariant} = useSelector(getCustomerSettings);
  const contactLookup = useSelector(getContactLookup);
  const contact = project.contact && contactLookup(project.contact);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);
  const responsibleUser = project.responsible && userUserProfileLookup(project.responsible);
  const locationLookup = useSelector(getLocationLookup);
  const relatedWorkplace = project.relatedWorkplace && locationLookup(project.relatedWorkplace);

  const translateProjectAccessChoice = useMemo(() => {
    switch (project.access) {
      case "barred":
        return intl.formatMessage({defaultMessage: "Spærret"});
      case "closed":
        return intl.formatMessage({defaultMessage: "Afsluttet"});
      case "hidden":
        return intl.formatMessage({defaultMessage: "Skjult"});
      case "open":
        return intl.formatMessage({defaultMessage: "Åben"});
      default:
        return project.access;
    }
  }, [project, intl]);

  return project && customer ? (
    <Grid container spacing={3}>
      <Grid item sm={6} xs={12}>
        <Card>
          <Box alignItems="center" display="flex" justifyContent="space-between">
            <CardHeader data-testid="project-name" title={project.name} />
            <Box alignItems="right" display="flex" justifyContent="space-between">
              <ProjectEditButton project={project} />
              <ProjectDeleteButton project={project} />
            </Box>
          </Box>
          <CardContent>
            <table>
              <tbody>
                <tr>
                  <td>
                    <FormattedMessage defaultMessage="Projektnr.: " />
                  </td>
                  <td>{project.projectNumber}</td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage defaultMessage="Kunde: " />
                  </td>
                  <td>{customer?.name}</td>
                </tr>
                <tr>
                  <td>
                    <FormattedMessage defaultMessage="Adgang: " />
                  </td>
                  <td>{translateProjectAccessChoice}</td>
                </tr>
              </tbody>
            </table>
            <Divider />
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Typography variant="h6">
                <FormattedMessage defaultMessage="Kontaktperson, kunde" />
              </Typography>
            </Box>
            {contact && <ContactInfo contact={contact} />}
            <Divider />
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Typography variant="h6">
                <FormattedMessage defaultMessage="Ansvarlig, intern" />
              </Typography>
            </Box>
            {responsibleUser && <UserInfo user={responsibleUser} />}
            <Divider />
            <Box alignItems="center" display="flex" justifyContent="space-between">
              <Typography variant="h6">
                <FormattedMessage defaultMessage="Arbejdssted" />
              </Typography>
            </Box>
            {relatedWorkplace && <LocationInfo location={relatedWorkplace} />}
          </CardContent>
        </Card>
      </Grid>
      <Grid item sm={6} xs={12}>
        <Card style={{marginBottom: 22}}>
          <Grid container spacing={3}>
            <Grid item xs>
              <ProjectNotes editable project={project} />
            </Grid>
          </Grid>
        </Card>
        <Card style={{marginBottom: 22}}>
          <Grid container spacing={3}>
            <Grid item xs>
              <ProjectFilesCard editable projectUrl={project.url} />
            </Grid>
          </Grid>
        </Card>
        <Card style={{marginBottom: 22}}>
          <Grid container spacing={3}>
            <Grid item xs>
              <CustomerFileListCard customerURL={customer.url} />
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  ) : projectLabelVariant === "PROJECT" ? (
    <FormattedMessage defaultMessage="Projekt ikke fundet på serveren" />
  ) : (
    <FormattedMessage defaultMessage="Sag ikke fundet på serveren" />
  );
});
