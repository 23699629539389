import {IconButton} from "@material-ui/core";
import MapIcon from "mdi-react/MapIcon";
import React from "react";

export function MapsButton({address}: {address: string}): React.JSX.Element {
  const query = encodeURIComponent(address);
  const mapsUrl = `https://maps.google.com/?q=${query}`;
  return (
    <IconButton color="primary" href={mapsUrl} target="_blank">
      <MapIcon />
    </IconButton>
  );
}
