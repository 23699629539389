export const settingsModuleIdentifiers = [
  "konsulentOverblik",
  "virksomhed",
  "information",
  "kalender",
  "kort",
  "minDagseddel",
  "minArbejdstid",
  "mineLnbilag",
  "ordre",
  "opgaver",
  "kunder",
  "lager",
  "dagsedler",
  "lnrapport",
  "medarbejdere",
  "medarbejderGrupper",
  "dokumenter",
  "ruter",
  "vrksted",
  "madbestilling",
  "indstillinger",
  "rapporter",
  "omrdestatus",
  "bilag",
  "system",
  "projekter",
  "faktura",
  "eConomic",
  "c5",
  "nav",
  "brugerData",
  "display",
  "ln",
  "dataLnLn",
  "lessorLn",
  "maanssonLn",
  "gratisalLn",
  "tidsknapper",
  "log",
  "udgede",
  "reporting",
  "timers",
  "expert",
  "braendstoftillaeg",
  "stregkodeScanner",
  "economySystemIntegration",
  "backendConfiguration",
] as const;

export type SettingsModuleID = (typeof settingsModuleIdentifiers)[number];

export interface SettingsModuleMetaData {
  readonly documentationURL?: string;
  readonly title: string;
}

export type SettingsModules = {
  readonly [S in SettingsModuleID]: SettingsModuleMetaData;
};

export const settingsModules: SettingsModules = {
  backendConfiguration: {title: "Kunde-specifik backend konfiguration"},
  bilag: {title: "Bilag"},
  braendstoftillaeg: {title: "Brændstoftillæg"},
  brugerData: {title: "BrugerData"},
  c5: {title: "C5"},
  dagsedler: {title: "Dagsedler"},
  dataLnLn: {title: "DataLøn løn"},
  display: {title: "Display"},
  dokumenter: {title: "Dokumenter"},
  eConomic: {title: "e-conomic"},
  economySystemIntegration: {title: "Økonomisystem"},
  expert: {title: "Nightmare Mode ☠️"},
  faktura: {title: "Faktura"},
  gratisalLn: {title: "Gratisal løn"},
  indstillinger: {title: "Indstillinger"},
  information: {title: "Information"},
  kalender: {title: "Kalender"},
  konsulentOverblik: {title: "Konsulent, overblik"},
  kort: {title: "Kort"},
  kunder: {title: "Kunder"},
  lager: {title: "Lager"},
  lessorLn: {title: "Lessor løn"},
  ln: {title: "Løn"},
  lnrapport: {title: "Lønrapport"},
  log: {title: "LOG"},
  maanssonLn: {title: "Maansson løn"},
  madbestilling: {title: "Madbestilling"},
  medarbejdere: {title: "Medarbejdere"},
  medarbejderGrupper: {title: "Medarbejdergrupper"},
  minArbejdstid: {title: "Min arbejdstid"},
  minDagseddel: {title: "Min dagseddel"},
  mineLnbilag: {title: "Mine lønbilag"},
  nav: {title: "NAV"},
  omrdestatus: {title: "Områdestatus"},
  opgaver: {title: "Opgaver"},
  ordre: {title: "Ordre"},
  projekter: {title: "Projekter"},
  rapporter: {title: "Rapporter"},
  reporting: {title: "Logopsætning"},
  ruter: {title: "Ruter"},
  stregkodeScanner: {title: "Stregkode/QR-kode scanner"},
  system: {title: "System"},
  tidsknapper: {title: "Tidsknapper"},
  timers: {title: "Tidsknapper"},
  udgede: {title: "Udgåede"},
  virksomhed: {title: "Virksomhed"},
  vrksted: {title: "Værksted"},
};
