import {
  Config,
  REMOVED_SETTINGS_WHITELIST,
  schema,
  SettingID,
  settings,
  SettingsGroupID,
  SettingsModuleID,
} from "@co-common-libs/config";
import {SettingEntry} from "@co-common-libs/resources";
import {getCustomerSettings, getSettingEntryArray} from "@co-frontend-libs/redux";
import {Card, CardContent, useTheme} from "@material-ui/core";
import _ from "lodash";
import React, {useCallback, useMemo, useState} from "react";
import {FormattedMessage, IntlShape, useIntl} from "react-intl";
import {useSelector} from "react-redux";
import {ModuleData} from "../types";
import {editDialogOverrides} from "../ui-overrides";
import {
  checkConflictsWith,
  checkRequiresAllOf,
  checkRequiresOneOf,
  getValidator,
  settingDependenciesToString,
  settingValueIsBlank,
} from "../utils";
import {SettingDialog, SettingDialogProps} from "./setting-dialog";
import {SettingsModuleView} from "./settings-module-view";

const JSON_INDENTATION_LEVEL = 2;

interface SettingsViewProps {
  selectedGroup?: SettingsGroupID | undefined;
  selectedModule?: SettingsModuleID | undefined;
  settingsStructure: readonly ModuleData[];
}

function getDependencyWarnings(
  intl: IntlShape,
  settingEntryArray: readonly Readonly<SettingEntry>[],
  nonBlankSettings: Partial<Config>,
): Map<string, string[]> {
  const dependencyWarnings = new Map<string, string[]>();
  settingEntryArray.forEach((setting) => {
    const errors: string[] = [];
    if (setting.data) {
      const settingMetaData = settings[setting.key as SettingID];
      if (!settingMetaData) {
        dependencyWarnings.set(setting.key, [
          intl.formatMessage({
            defaultMessage: "Er ikke en gyldig setting. Kontakt udviklerne!",
          }),
        ]);
        return;
      }
      const conflictsWithIssue = checkConflictsWith(
        settingMetaData,
        nonBlankSettings,
        setting.data,
      );
      if (conflictsWithIssue) {
        errors.push(
          intl.formatMessage(
            {
              defaultMessage: "Må ikke bruges sammen med: {others}",
            },
            {others: settingDependenciesToString(conflictsWithIssue)},
          ),
        );
      }
      const requiresAllOfIssue = checkRequiresAllOf(
        settingMetaData,
        nonBlankSettings,
        setting.data,
      );
      if (requiresAllOfIssue) {
        errors.push(
          intl.formatMessage(
            {
              defaultMessage: "Kræver også: {others}",
            },
            {
              others: settingDependenciesToString(requiresAllOfIssue),
            },
          ),
        );
      }
      const requiresOneOfIssue = checkRequiresOneOf(
        settingMetaData,
        nonBlankSettings,
        setting.data,
      );
      if (requiresOneOfIssue) {
        errors.push(
          intl.formatMessage(
            {
              defaultMessage: "Kræver mindst en af: {others}",
            },
            {others: settingDependenciesToString(requiresOneOfIssue)},
          ),
        );
      }
      if (errors.length) {
        dependencyWarnings.set(setting.key, errors);
      }
    }
  });
  return dependencyWarnings;
}

const SettingErrorsAndWarnings = React.memo(function SettingErrorsAndWarnings({
  nonBlankSettings,
}: {
  nonBlankSettings: Partial<Config>;
}): React.JSX.Element | null {
  const settingEntryArray = useSelector(getSettingEntryArray);
  const currentSettingsCombined = useMemo(
    () =>
      Object.fromEntries(
        settingEntryArray
          .filter((settingEntry) => !REMOVED_SETTINGS_WHITELIST.includes(settingEntry.key))
          .map((settingEntry) => [settingEntry.key, settingEntry.data]),
      ),
    [settingEntryArray],
  );

  const validator = useMemo(() => getValidator(schema), []);
  const schemaErrors = useMemo(
    () => validator(currentSettingsCombined),
    [currentSettingsCombined, validator],
  );
  const theme = useTheme();
  const intl = useIntl();

  const dependencyWarnings = useMemo(
    () =>
      getDependencyWarnings(
        intl,
        settingEntryArray.filter(
          (settingEntry) => !REMOVED_SETTINGS_WHITELIST.includes(settingEntry.key),
        ),
        nonBlankSettings,
      ),
    [intl, settingEntryArray, nonBlankSettings],
  );

  if (!schemaErrors && !dependencyWarnings.size) {
    return null;
  }

  return (
    <Card style={{margin: "1em"}}>
      <CardContent>
        {schemaErrors ? (
          <div style={{color: theme.palette.error.main}}>
            <FormattedMessage defaultMessage="Valideringsfejl" tagName="h3" />
            <pre>
              <code>{JSON.stringify(schemaErrors, null, JSON_INDENTATION_LEVEL)}</code>
            </pre>
          </div>
        ) : null}
        {dependencyWarnings.size ? (
          <div style={{color: theme.palette.warning.main}}>
            <FormattedMessage defaultMessage="Advarsler" tagName="h3" />
            {[...dependencyWarnings.entries()].map(([key, errors]) => {
              return (
                <div key={key}>
                  <div>{key}</div>
                  <ul>
                    {errors.map((error, index) => (
                      <li key={index}>{error}</li>
                    ))}
                  </ul>
                </div>
              );
            })}
          </div>
        ) : null}
      </CardContent>
    </Card>
  );
});

export function SettingsView(props: SettingsViewProps): React.JSX.Element {
  const {selectedGroup, selectedModule, settingsStructure} = props;

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogSettingID, setDialogSettingID] = useState<SettingID>();

  const handleOpenDialog = useCallback((settingsID: SettingID): void => {
    setDialogOpen(true);
    setDialogSettingID(settingsID);
  }, []);

  const handleCloseDialog = useCallback((): void => {
    setDialogOpen(false);
  }, []);

  const customerSettings = useSelector(getCustomerSettings);

  const nonBlankSettings = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(customerSettings).filter(([_key, value]) => !settingValueIsBlank(value)),
      ) satisfies Partial<Config>,
    [customerSettings],
  );

  let SettingDialogComponent: React.ComponentType<SettingDialogProps> | undefined;

  if (dialogSettingID) {
    SettingDialogComponent = editDialogOverrides[dialogSettingID] || SettingDialog;
  }
  return (
    <>
      <SettingErrorsAndWarnings nonBlankSettings={nonBlankSettings} />
      {settingsStructure.map(({moduleDocumentationURL, moduleGroups, moduleID, moduleTitle}) => (
        <SettingsModuleView
          key={moduleID}
          moduleDocumentationURL={moduleDocumentationURL}
          moduleGroups={moduleGroups}
          moduleID={moduleID}
          moduleTitle={moduleTitle}
          nonBlankSettings={nonBlankSettings}
          onOpenDialog={handleOpenDialog}
          selectedGroup={selectedGroup}
          selectedModule={selectedModule}
        />
      ))}
      {dialogSettingID && SettingDialogComponent ? (
        <SettingDialogComponent
          nonBlankSettings={nonBlankSettings}
          onClose={handleCloseDialog}
          open={dialogOpen}
          settingID={dialogSettingID}
        />
      ) : null}
    </>
  );
}
