/*
  Wrapper for fetch; http://caniuse.com/#search=fetch
  (We use a XMLHttpRequest-based polyfill where fetch is not implemented...)

  The wrapper implicitly translates between JS objects locally and JSON
  for the server, adds the auth token header and modifies/extends error
  handling.
*/
import {
  apiVersion as currentApiVersion,
  frontendVersion as currentFrontendVersion,
} from "@co-common-libs/frontend-version";
import {notNull} from "@co-common-libs/utils";
// polyfill
import {SimpleEventDispatcher} from "ste-simple-events";
import "whatwg-fetch";

const SECOND_MS = 1000;
const MINUTE_SECONDS = 60;
const TIMEOUT_MINUTES = 5;
const TIMEOUT_MS = TIMEOUT_MINUTES * MINUTE_SECONDS * SECOND_MS;

export interface ResponseWithData extends Response {
  data?: any;
}

export class NetworkError extends Error {
  override cause: Error;
  constructor(cause: Error) {
    super(cause.message);
    this.cause = cause;
  }
}

export class StatusError extends Error {
  data: any;
  response: ResponseWithData;
  status: number;
  constructor(response: ResponseWithData) {
    super(response.statusText);
    this.status = response.status;
    this.data = response.data;
    this.response = response;
  }
}

export class ContentTypeNotJSONError extends Error {
  contentType: string | null;
  response: ResponseWithData;
  responseText: string;
  status: number;
  constructor(response: ResponseWithData, responseText: string) {
    super(response.statusText);
    this.status = response.status;
    this.contentType = response.headers.get("content-type");
    this.response = response;
    this.responseText = responseText;
  }
}
const wrapErrorToNetworkError = (error: Error): never => {
  throw new NetworkError(error);
};

const checkStatus = (response: ResponseWithData): ResponseWithData => {
  if (response.ok) {
    return response;
  } else {
    throw new StatusError(response);
  }
};

const checkContentType = (
  response: ResponseWithData,
): Promise<ResponseWithData> | ResponseWithData => {
  const contentType = response.headers.get("content-type");
  const jsonType = "application/json";
  if (contentType === jsonType || (contentType && contentType.indexOf(`${jsonType};`) === 0)) {
    return response;
  } else {
    return response.text().then((text) => {
      if (text === "") {
        response.data = null;
        return response;
      } else {
        throw new ContentTypeNotJSONError(response, text);
      }
    });
  }
};

const parseJson = (response: ResponseWithData): Promise<ResponseWithData> | ResponseWithData => {
  if (response.data === null) {
    return response;
  } else {
    return response.json().then((data: any) => {
      response.data = data;
      return response;
    });
  }
};

let defaultAuthToken: string | null = null;
export const setAuthToken = (newToken: string | null): void => {
  defaultAuthToken = newToken;
};
export const clearAuthToken = (): void => {
  setAuthToken(null);
};
export function hasAuthToken(): boolean {
  return defaultAuthToken !== null;
}

const fetchWithTimeout = (url: string, options: RequestInit, delay: number): Promise<Response> => {
  if (window.AbortController) {
    const abortController = new AbortController();
    const optionsWithSignal = {...options, signal: abortController.signal};
    if (options.signal) {
      options.signal.addEventListener("abort", () => {
        abortController.abort();
      });
    }
    setTimeout(() => {
      abortController.abort();
    }, delay);
    return window.fetch(url, optionsWithSignal);
  } else {
    const timer = new Promise<Response>((_resolve, reject) => {
      setTimeout(() => {
        reject(new Error("timeout"));
      }, delay);
    });
    return Promise.race([window.fetch(url, options), timer]);
  }
};

export const jsonFetchErrorEvent = new SimpleEventDispatcher<
  ContentTypeNotJSONError | Error | NetworkError | StatusError
>();

export const jsonFetch = (
  url: string,
  method = "GET",
  data: unknown = null,
  abortSignal?: AbortSignal,
  authToken = defaultAuthToken,
  versions?: {
    apiVersion: string | null;
    createdByVersion: string | null;
    frontendVersion: string | null;
  },
  contentType?: string,
): Promise<ResponseWithData> => {
  const {apiVersion, createdByVersion, frontendVersion} = versions || {
    apiVersion: currentApiVersion,
    frontendVersion: currentFrontendVersion,
  };
  const headers = new Headers();
  headers.append(
    "Accept",
    [
      "application/json",
      apiVersion ? `api=${apiVersion}` : null,
      frontendVersion ? `frontend=${frontendVersion}` : null,
      createdByVersion ? `createdBy=${createdByVersion}` : null,
    ]
      .filter(notNull)
      .join("; "),
  );
  const options: RequestInit = {
    headers,
    method,
  };
  if (data instanceof FormData) {
    options.body = data;
  } else if (data !== null) {
    headers.append("Content-Type", contentType || "application/json");
    options.body = JSON.stringify(data);
  }
  if (authToken) {
    headers.append("Authorization", `Token ${authToken}`);
  }

  if (abortSignal) {
    options.signal = abortSignal;
  }
  return fetchWithTimeout(url, options, TIMEOUT_MS)
    .catch(wrapErrorToNetworkError)
    .then(checkContentType)
    .then(parseJson)
    .then(checkStatus)
    .catch((reason) => {
      if (reason instanceof ContentTypeNotJSONError) {
        // throw StatusError instead if relevant...
        checkStatus(reason.response);
      }
      throw reason;
    })
    .catch((error) => {
      jsonFetchErrorEvent.dispatch(error);
      // eslint-disable-next-line no-console
      console.error(error);
      throw error;
    });
};
