import type {Writable} from "ts-essentials";
import {
  AccomodationAllowance,
  ComputedTime,
  CultureUrl,
  CustomerUrl,
  Delivery,
  emptyTask,
  Location,
  LocationUrl,
  Machine,
  MachineUrl,
  MachineUse,
  Order,
  Patch,
  PatchOperation,
  Pickup,
  PriceGroupUrl,
  ProductUrl,
  ReportingSpecification,
  ReportingSpecificationUrl,
  Task,
  TaskPhoto,
  TimeCorrection,
  Timer,
  TimerStart,
  TimerUrl,
  urlToId,
  UserPhoto,
  UserUrl,
  WorkTypeUrl,
} from "@co-common-libs/resources";
import {
  addToProductUses,
  formatDuration,
  getInputSpecificationsMap,
  getLastTimerStart,
  getMaterialUsePatchFromLogEntries,
  getNormalisedDeviceTimestamp,
  getReadonlyPriceItems,
  getUnitCode,
  getWorkTypeString,
  ITEM_TYPE_TIMER,
  machinePotentialPriceGroups,
  workTypePotentialPriceGroups,
} from "@co-common-libs/resources-utils";
import {
  dateFromString,
  dateToString,
  formatAddress,
  MINUTE_MILLISECONDS,
  notNull,
  notUndefined,
  sortByOrderMember,
} from "@co-common-libs/utils";
import {
  DeleteDialog,
  IconLinkButton,
  MachineDialog,
  SingleTextFieldDialog,
  StatusBar,
} from "@co-frontend-libs/components";
import {
  ConnectedCultureDialog,
  ConnectedDepartmentDialog,
  ConnectedLogLegalPriceGroupDialog,
  ConnectedMachineOperatorDialog,
  ConnectedProductDialog,
} from "@co-frontend-libs/connected-components";
import {
  actions,
  getAccomodationAllowanceArray,
  getContactArray,
  getContactLookup,
  getCultureLookup,
  getCurrentRole,
  getCurrentUserProfile,
  getCurrentUserSortedTimerStartArray,
  getCurrentUserURL,
  getCustomerLookup,
  getDaysAbsenceArray,
  getDeliveryLocationArray,
  getExtendedCustomerSettings,
  getLocationArray,
  getLocationLookup,
  getMachineArray,
  getMachineLookup,
  getNotificationDialogOpen,
  getOrderLookup,
  getPickupLocationArray,
  getPriceGroupLookup,
  getPriceItemLookup,
  getProductArray,
  getProductGroupLookup,
  getProductLookup,
  getProductUseLogArray,
  getProjectArray,
  getProjectLookup,
  getPunchInOutPeriodsPerEmployee,
  getQueryParameters,
  getReportingSpecificationArray,
  getReportingSpecificationLookup,
  getShareToken,
  getSprayLocationArray,
  getSprayLogArray,
  getTaskArray,
  getTaskLookup,
  getTimerArray,
  getTimerLookup,
  getTimerStartArray,
  getTransportLogArray,
  getUnitLookup,
  getUserLookup,
  getUserUserProfileLookup,
  getWorkTypeArray,
  getWorkTypeLookup,
  getYieldDeliveryLocationArray,
  getYieldLogArray,
  getYieldPickupLocationArray,
} from "@co-frontend-libs/redux";
import {colorMap, getFrontendSentry, useCallWithFalse} from "@co-frontend-libs/utils";
import {Grid, Tab, Tabs, useTheme} from "@material-ui/core";
import {
  CalledInDialog,
  computeEffectiveMinutes,
  CustomerApproveOrChangeDialog,
  CustomerSelectCreateDialog,
  getUsedFieldUrlSet,
  LocationSelectCreateDialog,
  MachinePriceGroupWizard,
  MachineRemovalBlockedDialog,
  MissingBreakDialog,
  MissingProductDialog,
  NotesDialog,
  PageLayout,
  PhotoDisplayDialog,
  PriceGroupDialog,
  ProductGroupTreeDialog,
  showCalledInDialog,
  TransportTotalsTable,
  WorkTypeSelectionWrapper,
} from "app-components";
import {
  ADD_FIELD_ACTION,
  ADD_MACHINE_ACTION,
  ADD_PHOTO_ACTION,
  ADD_PROJECT_ACTION,
  ADD_TIME_ACTION,
  ADD_WORKPLACE_ACTION,
  addDanishCountryPrefix,
  APPROVE_CUSTOMER_ACTION,
  buildSprayLogReport,
  buildTransportLogReport,
  buildYieldLogReport,
  CHANGE_CUSTOMER_ACTION,
  CHANGE_DEPARTMENT_ACTION,
  CHANGE_EMPLOYEE_ACTION,
  CHANGE_MATERIAL_ACTION,
  CHANGE_SMALL_MACHINE_ACTION,
  computeIntervalsTruncated,
  computeIntervalSums,
  computeWorkFromTo,
  concurrencyAllowedForTask,
  copyTaskWithLogs,
  copyTaskWithLogsLocations,
  EDIT_LOG_ACTION,
  EDIT_MACHINE_ACTION,
  EDIT_NOTE_ACTION,
  ErrorAction,
  getBreakTimer,
  getCompletedAsInternalNewDate,
  getContinuationTaskTargetDate,
  getEmployeeHolidayCalendars,
  getEmployeeTaskRemunerationGroup,
  getRelevantPriceGroupSet,
  getTaskGenericPrimaryTimerLabel,
  getTaskSecondaryTimerList,
  hasSecondaryInvoicedTime,
  machineAlreadySelected,
  machineRemovalBlocked,
  MachineRemovalBlockedReason,
  mapSum,
  mergeIntervals,
  padZero,
  readProductUseLog,
  saveTimerStart,
  SELECT_WORK_TYPE_ACTION,
  taskChangeCustomerCulture,
  useBoundActions,
  useQueryParameter,
  willTaskBeRecorded,
  workTypeChangeBlocked,
} from "app-utils";
import bowser from "bowser";
import {differenceInCalendarDays, eachDayOfInterval} from "date-fns";
import {instanceURL} from "frontend-global-config";
import _ from "lodash";
import PhoneIcon from "mdi-react/PhoneIcon";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import {FormattedMessage, useIntl} from "react-intl";
import {batch, useDispatch, useSelector} from "react-redux";
import {v4 as uuid} from "uuid";
import {computeRowData} from "../bookkeeping-day/bookkeeping-task-table";
import {EconomicTaskIssuesList} from "../economic-sync/issues/economic-task-issues-list";
import {ActionButtons} from "./action-buttons";
import {AutoCorrectionDialog} from "./auto-correction-dialog";
import {ChangeWorkTypeButton} from "./change-work-type-button";
import CompletedDialog from "./completed-dialog";
import CopiedDialog from "./copied-dialog";
import {GeolocationTab} from "./geolocation-tab";
import {getComputedTime} from "./get-computed-time";
import {InfoTabContent} from "./info-tab";
import {InvoicingTab} from "./invoicing-tab";
import MachinesCard from "./machines-card";
import {PhotosTab} from "./photos-tab";
import ProductsTabContent from "./products-tab";
import {RemoveFieldsDialog} from "./remove-fields-dialog";
import {LogCard} from "./reporting/log-card";
import {SmallMachinesTabContent} from "./small-machines";
import {SprayLog} from "./spray-log";
import {TaskDeleteDialog} from "./task-delete-dialog";
import {TaskWarningDialogs} from "./task-warning-dialogs";
import TimeCard from "./time-cards";
import {IntervalWithTimer} from "./time-cards/types";
import {AddTimeCorrectionDialog} from "./time-correction/add-time-correction-dialog";
import {EditTimeCorrectionDialog} from "./time-correction/edit-time-correction-dialog";
import TransportLogCard from "./transport-log-card";
import {editTimeCorrectionsAllowed} from "./utils";
import {ValidatedDialog} from "./validated-dialog";
import {WorkplaceLine} from "./workplace-line";
import {YieldLog} from "./yield-log";

function getSharedReportingSpecification(
  orderTasks: readonly Readonly<Task>[],
  reportingSpecificationLookup: (
    url: ReportingSpecificationUrl,
  ) => ReportingSpecification | undefined,
): ReportingSpecification | undefined {
  const sharedReportingSpecificationUrls = Array.from(
    new Set(
      orderTasks
        .map((t) => {
          const specification =
            t.reportingSpecification && reportingSpecificationLookup(t.reportingSpecification);
          if (
            specification &&
            specification.allowTotalsTable !== false &&
            specification.shared &&
            specification.showSharedTotalsTableOnAllOrderTasks
          ) {
            return specification.url;
          } else {
            return null;
          }
        })
        .filter(notNull),
    ),
  );
  if (sharedReportingSpecificationUrls.length === 1) {
    return reportingSpecificationLookup(sharedReportingSpecificationUrls[0]);
  }
  return undefined;
}

interface TaskInstanceProps {
  activeTimer?: TimerUrl | undefined;
  computedIntervals: readonly ComputedTime[];
  genericPrimaryTimer: Timer;
  intervals: readonly {
    readonly fromTimestamp: string;
    readonly timer: TimerUrl | null;
    readonly toTimestamp: string;
  }[];
  now: Date;
  task: Task;
  timerMinutesMap: ReadonlyMap<TimerUrl, number>;
}

export function TaskInstance(props: TaskInstanceProps): React.JSX.Element {
  const {
    activeTimer,
    computedIntervals,
    genericPrimaryTimer,
    intervals,
    now,
    task,
    timerMinutesMap,
  } = props;

  const accomodationAllowanceArray = useSelector(getAccomodationAllowanceArray);
  const contactArray = useSelector(getContactArray);
  const contactLookup = useSelector(getContactLookup);
  const cultureLookup = useSelector(getCultureLookup);
  const currentRole = useSelector(getCurrentRole);
  const currentTab = useQueryParameter<string>("tab", "time");
  const currentUserProfile = useSelector(getCurrentUserProfile);
  const currentUserSortedTimerStartArray = useSelector(getCurrentUserSortedTimerStartArray);
  const currentUserURL = useSelector(getCurrentUserURL);
  const customerLookup = useSelector(getCustomerLookup);
  const customerSettings = useSelector(getExtendedCustomerSettings);
  const daysAbsenceArray = useSelector(getDaysAbsenceArray);
  const deliveryLocationArray = useSelector(getDeliveryLocationArray);
  const locationArray = useSelector(getLocationArray);
  const locationLookup = useSelector(getLocationLookup);
  const machineArray = useSelector(getMachineArray);
  const machineLookup = useSelector(getMachineLookup);
  const notificationDialogOpen = useSelector(getNotificationDialogOpen);
  const orderLookup = useSelector(getOrderLookup);
  const pickupLocationArray = useSelector(getPickupLocationArray);
  const priceGroupLookup = useSelector(getPriceGroupLookup);
  const priceItemLookup = useSelector(getPriceItemLookup);
  const productArray = useSelector(getProductArray);
  const productGroupLookup = useSelector(getProductGroupLookup);
  const productLookup = useSelector(getProductLookup);
  const productUseLogArray = useSelector(getProductUseLogArray);
  const projectArray = useSelector(getProjectArray);
  const projectLookup = useSelector(getProjectLookup);
  const punchInOutPeriodsPerEmployee = useSelector(getPunchInOutPeriodsPerEmployee);
  const queryParameters = useSelector(getQueryParameters);
  const reportingSpecificationLookup = useSelector(getReportingSpecificationLookup);
  const reportingSpecificationArray = useSelector(getReportingSpecificationArray);
  const shareToken = useSelector(getShareToken);
  const sprayLocationArray = useSelector(getSprayLocationArray);
  const sprayLogArray = useSelector(getSprayLogArray);
  const taskArray = useSelector(getTaskArray);
  const taskLookup = useSelector(getTaskLookup);
  const timerArray = useSelector(getTimerArray);
  const timerLookup = useSelector(getTimerLookup);
  const timerStartArray = useSelector(getTimerStartArray);
  const transportLogArray = useSelector(getTransportLogArray);
  const unitLookup = useSelector(getUnitLookup);
  const userLookup = useSelector(getUserLookup);
  const userUserProfileLookup = useSelector(getUserUserProfileLookup);
  const workTypeArray = useSelector(getWorkTypeArray);
  const workTypeLookup = useSelector(getWorkTypeLookup);
  const yieldDeliveryLocationArray = useSelector(getYieldDeliveryLocationArray);
  const yieldLogArray = useSelector(getYieldLogArray);
  const yieldPickupLocationArray = useSelector(getYieldPickupLocationArray);
  const theme = useTheme();

  const dispatch = useDispatch();

  const [notesDialogDismissed, setNotesDialogDismissed] = useState(
    !!(
      queryParameters &&
      queryParameters.notesDisplayed &&
      parseInt(queryParameters.notesDisplayed)
    ),
  );
  const [workplaceDialogCallback, setWorkplaceDialogCallback] = useState<
    ((workPlace: Location | null) => void) | null
  >(null);
  const [workplaceDialogCustomerURL, setWorkplaceDialogCustomerURL] = useState<CustomerUrl | null>(
    null,
  );
  const [deleteEntryRequest, setDeleteEntryRequest] = useState<Delivery | Pickup | null>(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [machineDialogOpen, setMachineDialogOpen] = useState(false);
  const [departmentDialogOpen, setDepartmentDialogOpen] = useState(false);
  const [productDialogOpen, setProductDialogOpen] = useState(false);
  const [completedDialogCancelled, setCompletedDialogCancelled] = useState(false);
  const [completedDialogCompletedAsInternal, setCompletedDialogCompletedAsInternal] =
    useState(false);
  const [completedDialogContinuation, setCompletedDialogContinuation] = useState(false);
  const [missingProductDialogOpen, setMissingProductDialogOpen] = useState(false);
  const [
    openMissingBreakDialogAfterMissingProductDialog,
    setOpenMissingBreakDialogAfterMissingProductDialog,
  ] = useState(false);
  const [missingBreakDialogOpen, setMissingBreakDialogOpen] = useState(false);
  const [completedDialogOpen, setCompletedDialogOpen] = useState(false);
  const [nextCustomerDialogOpen, setNextCustomerDialogOpen] = useState(false);
  const [approvedDialogOpen, setApprovedDialogOpen] = useState(false);
  const [validatedDialogOpen, setValidatedDialogOpen] = useState(false);
  const [customerApproveDialogOpen, setCustomerApproveDialogOpen] = useState(false);
  const setCustomerApproveDialogOpenFalse = useCallWithFalse(setCustomerApproveDialogOpen);
  const [customerDialogOpen, setCustomerDialogOpen] = useState(false);
  const [machineOperatorDialogOpen, setMachineOperatorDialogOpen] = useState(false);
  const [enterExtraWorkNoteDialogOpen, setEnterExtraWorkNoteDialogOpen] = useState(false);
  const [copiedDialogOpen, setCopiedDialogOpen] = useState(false);
  const [removeFieldsDialogOpen, setRemoveFieldsDialogOpen] = useState(false);
  const [cultureDialogOpen, setCultureDialogOpen] = useState(false);
  const [performOldTaskCheck, setPerformOldTaskCheck] = useState(false);
  const [autoCorrectionDialog, setAutoCorrectionDialog] = useState<{
    fromTimestamp: string;
    timerURL: TimerUrl;
    toTimestamp: string;
  } | null>(null);
  const [calledInDialogOpen, setCalledInDialogOpen] = useState(false);
  const [machineOperatorTimeCorrectionDialogOpen, setMachineOperatorTimeCorrectionDialogOpen] =
    useState(false);
  const [managerTimeCorrectionDialogOpen, setManagerTimeCorrectionDialogOpen] = useState(false);
  const [editIntervalEnd, setEditIntervalEnd] = useState<string | null>(null);
  const [editIntervalStart, setEditIntervalStart] = useState<string | null>(null);
  const [editIntervalTimer, setEditIntervalTimer] = useState<Timer | null>(null);
  const [transportMachineDialogOpen, setTransportMachineDialogOpen] = useState(false);
  const [yieldLogDialogs, setYieldLogDialogs] = useState<readonly React.JSX.Element[] | null>(null);
  const [displayedImage, setDisplayedImage] = useState<TaskPhoto | UserPhoto | null>(null);
  const [deletingPhoto, setDeletingPhoto] = useState<string | null>(null);
  const [deletingFile, setDeletingFile] = useState<string | null>(null);
  const [sprayLogDialogs, setSprayLogDialogs] = useState<readonly React.JSX.Element[] | null>(null);
  const [timerButtonClickedFor, setTimerButtonClickedFor] = useState<TimerUrl | null>(null);

  const workTypeSelectionControl = useRef<{start: () => void}>(null);

  const breakTimer = getBreakTimer(timerArray);
  const breakTimerURL = breakTimer?.url;

  const orderURL = task.order;
  const order = orderURL ? orderLookup(orderURL) : undefined;
  const customerURL = order ? order.customer : null;
  const customer = customerURL ? customerLookup(customerURL) : null;
  const cultureURL = order ? order.culture : null;
  const culture = cultureURL && cultureLookup(cultureURL);

  const {boundCreate, boundUpdate} = useBoundActions();

  const boundRegisterTimerStartPosition = useCallback(
    (timerStart: TimerStart): void => {
      dispatch(actions.registerTimerStartPosition(timerStart));
    },
    [dispatch],
  );

  const intl = useIntl();

  const buildReports = useCallback(() => {
    if (!currentUserURL) {
      return;
    }
    buildTransportLogReport(currentUserURL, task, transportLogArray, boundCreate);
    buildSprayLogReport(currentUserURL, task, sprayLogArray, boundCreate);
    buildYieldLogReport(currentUserURL, task, yieldLogArray, boundCreate);
    if (task.reportingSpecification && !task.logSkipped) {
      const id = uuid();
      const url = instanceURL("reportingPrintout", id);
      const deviceTimestamp = new Date().toISOString();
      const newReportingPrintout = {
        createdBy: currentUserURL,
        deviceTimestamp,
        id,
        task: task.url,
        url,
      };
      dispatch(actions.create(newReportingPrintout));
    }
  }, [
    boundCreate,
    currentUserURL,
    dispatch,
    sprayLogArray,
    task,
    transportLogArray,
    yieldLogArray,
  ]);

  const handleRequestBuildReports = useCallback(() => {
    buildReports();
  }, [buildReports]);

  const handleRequestWorkplaceDialog = useCallback(
    (
      callback: (workPlace: Location | null) => void,
      workplaceCustomerURL: CustomerUrl | null,
    ): void => {
      setWorkplaceDialogCallback(callback);
      setWorkplaceDialogCustomerURL(workplaceCustomerURL);
    },
    [],
  );

  const handleWorkplaceDialogOk = useCallback(
    (url: LocationUrl): void => {
      const callback = workplaceDialogCallback;
      setWorkplaceDialogCallback(null);
      const workplace = locationLookup(url);
      if (callback && workplace) {
        callback(workplace);
      }
    },
    [locationLookup, workplaceDialogCallback],
  );

  const handleWorkplaceDialogNone = useCallback((): void => {
    const callback = workplaceDialogCallback;
    setWorkplaceDialogCallback(null);
    if (callback) {
      callback(null);
    }
  }, [workplaceDialogCallback]);

  const handleWorkplaceDialogCancel = useCallback((): void => {
    setWorkplaceDialogCallback(null);
  }, []);

  const handleRequestDeleteEntry = useCallback((entry: Delivery | Pickup): void => {
    setDeleteEntryRequest(entry);
  }, []);

  const handleDeleteEntryCancel = useCallback((): void => {
    setDeleteEntryRequest(null);
  }, []);

  const handleDeleteEntryOk = useCallback((): void => {
    const entry = deleteEntryRequest;
    if (entry) {
      setDeleteEntryRequest(null);
      dispatch(actions.remove(entry.url));
    }
  }, [deleteEntryRequest, dispatch]);

  const timerChange = useCallback(
    (timerURL: TimerUrl | null, timestamp: string | null = null): void => {
      const userURL = task.machineOperator;
      const taskURL = task.url;
      if (userURL && taskURL) {
        saveTimerStart(
          boundCreate,
          timerURL,
          taskURL,
          userURL,
          timestamp,
          customerSettings.geolocation.registerPositionOnTimerClick &&
            currentUserURL === task.machineOperator
            ? boundRegisterTimerStartPosition
            : undefined,
        );
      }
    },
    [
      boundCreate,
      boundRegisterTimerStartPosition,
      currentUserURL,
      customerSettings.geolocation.registerPositionOnTimerClick,
      task.machineOperator,
      task.url,
    ],
  );

  const handleDeleteButton = useCallback((): void => {
    setDeleteDialogOpen(true);
    // stop timer, just in case
    timerChange(null);
  }, [timerChange]);

  const handleDeleteDialogOk = useCallback((): void => {
    setDeleteDialogOpen(false);
    const taskURL = task.url;
    let deleteOrder = false;
    if (orderURL) {
      const tasksForOrder = taskArray.filter((t) => t.order === orderURL);
      if (tasksForOrder.length === 1 && tasksForOrder[0].url === taskURL) {
        deleteOrder = true;
      }
    }
    dispatch(actions.remove(taskURL));
    if (orderURL && deleteOrder) {
      dispatch(actions.remove(orderURL));
    }
    window.setTimeout(() => {
      batch(() => {
        dispatch(
          actions.backSkip([
            "/order/:id",
            "/order/:id/:taskID",
            "/orderEntry/:id",
            "/task/:id",
            "/taskDetails/:id",
            "/taskEdit/:id",
            "/internalTask/:id",
          ]),
        );
      });
    });
  }, [dispatch, orderURL, task.url, taskArray]);

  const handleDeleteDialogCancel = useCallback((): void => {
    setDeleteDialogOpen(false);
  }, []);

  const handleMachineSelectButton = useCallback((): void => {
    setMachineDialogOpen(true);
  }, []);

  const handleMachineDialogOk = useCallback(
    (machineURL: MachineUrl, priceGroupURL: PriceGroupUrl | null): void => {
      setMachineDialogOpen(false);
      const oldMachines = task.machineuseSet || [];
      if (machineAlreadySelected(task, machineURL)) {
        return;
      }
      const newEntry: MachineUse = {
        machine: machineURL,
        priceGroup: priceGroupURL,
        transporter: false,
      };
      const newMachines = [...oldMachines, newEntry];
      const patch: Patch<Task> = [{member: "machineuseSet", value: newMachines}];
      dispatch(actions.update(task.url, patch));
      const workType = task.workType ? workTypeLookup(task.workType) : undefined;
      if (workType?.department) {
        return;
      }
      const machine = machineLookup(machineURL);
      if (!machine?.department) {
        return;
      }
      const oldMachineDepartments = new Set(
        oldMachines
          .map((machineUse) => machineLookup(machineUse.machine))
          .filter(notUndefined)
          .map((oldMachine) => oldMachine.department)
          .filter(Boolean),
      );
      if (oldMachineDepartments.size && !oldMachineDepartments.has(machine.department)) {
        setDepartmentDialogOpen(true);
      }
    },
    [dispatch, machineLookup, task, workTypeLookup],
  );

  const handleMachineDialogCancel = useCallback((): void => {
    setMachineDialogOpen(false);
  }, []);

  const handleDepartmentSelectButton = useCallback((): void => {
    setDepartmentDialogOpen(true);
  }, []);

  const handleDepartmentDialogOk = useCallback(
    (selected: string): void => {
      dispatch(actions.update(task.url, [{member: "department", value: selected}]));
      setDepartmentDialogOpen(false);
    },
    [dispatch, task.url],
  );

  const handleDepartmentDialogCancel = useCallback((): void => {
    setDepartmentDialogOpen(false);
  }, []);

  const handlePriceGroupDialogOk = useCallback(
    (priceGroupURL: PriceGroupUrl): void => {
      if (
        task.workType &&
        !task.priceGroup &&
        workTypePotentialPriceGroups(task.workType, customerURL, workTypeLookup, priceGroupLookup)
          .length > 1
      ) {
        const patch: Patch<Task> = [{member: "priceGroup", value: priceGroupURL}];
        dispatch(actions.update(task.url, patch));
      } else if (task.machineuseSet && task.machineuseSet.length) {
        const machineIndex = task.machineuseSet.findIndex(
          (machineUse) =>
            !machineUse.priceGroup &&
            machinePotentialPriceGroups(
              machineUse.machine,
              customerURL,
              machineLookup,
              priceGroupLookup,
            ).length > 1,
        );
        if (machineIndex !== -1) {
          const newMachineUse = [...task.machineuseSet];
          newMachineUse[machineIndex] = {
            ...newMachineUse[machineIndex],
            priceGroup: priceGroupURL,
          };
          const patch: Patch<Task> = [{member: "machineuseSet", value: newMachineUse}];
          dispatch(actions.update(task.url, patch));
        }
      }
    },
    [
      customerURL,
      dispatch,
      machineLookup,
      priceGroupLookup,
      task.machineuseSet,
      task.priceGroup,
      task.url,
      task.workType,
      workTypeLookup,
    ],
  );

  const [machineRemovalBlockedReason, setMachineRemovalBlockedReason] =
    useState<MachineRemovalBlockedReason | null>(null);
  const [machineRemovalBlockedDialogOpen, setMachineRemovalBlockedDialogOpen] = useState(false);

  const handleRemoveMachine = useCallback(
    (index: number): void => {
      const machineUse = task.machineuseSet[index];
      if (!machineUse) {
        return;
      }
      const machineUrl = machineUse.machine;
      const blockedReason = machineRemovalBlocked(
        task,
        machineUrl,
        timerMinutesMap,
        customerSettings,
        {
          machineLookup,
          priceGroupLookup,
          reportingSpecificationLookup,
          timerArray,
          workTypeLookup,
        },
      );
      if (blockedReason) {
        setMachineRemovalBlockedDialogOpen(true);
        setMachineRemovalBlockedReason(blockedReason);
      } else {
        const oldMachines = task.machineuseSet || [];
        const newMachines = oldMachines.slice();
        newMachines.splice(index, 1);
        const patch: Patch<Task> = [{member: "machineuseSet", value: newMachines}];
        dispatch(actions.update(task.url, patch));
      }
    },
    [
      customerSettings,
      dispatch,
      machineLookup,
      priceGroupLookup,
      reportingSpecificationLookup,
      task,
      timerArray,
      timerMinutesMap,
      workTypeLookup,
    ],
  );

  const handleMachineRemovalBlockedDialogClose = useCallback((): void => {
    setMachineRemovalBlockedDialogOpen(false);
  }, []);

  const handleProductSelectButton = useCallback((): void => {
    setProductDialogOpen(true);
  }, []);

  const handleProductDialogOk = useCallback(
    (urlOrURLs: ProductUrl | ReadonlySet<ProductUrl>): void => {
      setProductDialogOpen(false);
      const patch = addToProductUses(
        task.productUses || {},
        urlOrURLs,
        productArray,
        productLookup,
        productGroupLookup,
        customerSettings,
        currentUserURL,
      );
      if (patch.length) {
        dispatch(actions.update(task.url, patch));
      }
    },
    [
      currentUserURL,
      customerSettings,
      dispatch,
      productArray,
      productGroupLookup,
      productLookup,
      task.productUses,
      task.url,
    ],
  );

  const handleProductDialogCancel = useCallback((): void => {
    setProductDialogOpen(false);
  }, []);

  const handleCompletedButton = useCallback((): void => {
    // FIXME: handle admins stopping task on behalf of others
    // stop timer, just in case
    timerChange(null);

    const logSpecification = task.reportingSpecification
      ? reportingSpecificationLookup(task.reportingSpecification)
      : null;
    if (logSpecification && !task.logSkipped) {
      const inputSpecificationsMap = getInputSpecificationsMap(logSpecification);

      const materialPatch = getMaterialUsePatchFromLogEntries(
        logSpecification,
        customerSettings,
        task,
        undefined,
        inputSpecificationsMap,
        priceItemLookup,
        unitLookup,
        productLookup,
        currentUserURL,
      );
      if (materialPatch.length) {
        dispatch(actions.update(task.url, materialPatch));
      }
    }

    let showMissingBreakDialog = false;
    if (customerSettings.askRegardingMissingBreakOnExternalTaskCompletion) {
      const finalSums = computeIntervalSums(intervals, new Date());
      showMissingBreakDialog = !!breakTimerURL && !finalSums.get(breakTimerURL);
    }
    const showMissingProductDialog =
      Object.keys(task.productUses || {}).length < 1 &&
      task.machineuseSet
        .map((mu) => mu.priceGroup)
        .concat([task.priceGroup])
        .filter(notNull)
        .some((priceGroupURL) => {
          const priceGroup = priceGroupLookup(priceGroupURL);
          return priceGroup && priceGroup.requireCompletionConfirmationIfNoProducts;
        });

    setNextCustomerDialogOpen(false);

    if (showMissingProductDialog) {
      setCompletedDialogCancelled(false);
      setCompletedDialogCompletedAsInternal(false);
      setCompletedDialogContinuation(false);
      setMissingProductDialogOpen(true);
      setOpenMissingBreakDialogAfterMissingProductDialog(showMissingBreakDialog);
    } else if (showMissingBreakDialog) {
      setCompletedDialogCancelled(false);
      setCompletedDialogCompletedAsInternal(false);
      setCompletedDialogContinuation(false);
      setMissingBreakDialogOpen(true);
    } else {
      setCompletedDialogCancelled(false);
      setCompletedDialogCompletedAsInternal(false);
      setCompletedDialogContinuation(false);
      setCompletedDialogOpen(true);
    }
  }, [
    breakTimerURL,
    currentUserURL,
    customerSettings,
    dispatch,
    intervals,
    priceGroupLookup,
    priceItemLookup,
    productLookup,
    reportingSpecificationLookup,
    task,
    timerChange,
    unitLookup,
  ]);

  const handleContinueToNextCustomerButton = useCallback((): void => {
    if (task.completed) {
      setNextCustomerDialogOpen(true);
    } else {
      timerChange(null);
      setCompletedDialogCancelled(false);
      setCompletedDialogCompletedAsInternal(false);
      setCompletedDialogContinuation(false);
      setCompletedDialogOpen(true);
      setNextCustomerDialogOpen(true);
    }
  }, [task.completed, timerChange]);

  const handleIncompleteButton = useCallback((): void => {
    timerChange(null);

    setNextCustomerDialogOpen(false);
    setCompletedDialogCancelled(false);
    setCompletedDialogCompletedAsInternal(false);
    setCompletedDialogContinuation(true);
    setCompletedDialogOpen(true);
  }, [timerChange]);

  const handleCancelledButton = useCallback((): void => {
    timerChange(null);

    setNextCustomerDialogOpen(false);
    setCompletedDialogCancelled(true);
    setCompletedDialogCompletedAsInternal(false);
    setCompletedDialogContinuation(false);
    setCompletedDialogOpen(true);
  }, [timerChange]);

  const handleCompletedDialogCancel = useCallback((): void => {
    setCompletedDialogOpen(false);
    setNextCustomerDialogOpen(false);
  }, []);

  const handleCompletedAsInternalButton = useCallback((): void => {
    timerChange(null);

    setNextCustomerDialogOpen(false);
    setCompletedDialogCancelled(false);
    setCompletedDialogCompletedAsInternal(true);
    setCompletedDialogContinuation(true);
    setCompletedDialogOpen(true);
  }, [timerChange]);

  const handleMissingBreakDialogOk = useCallback((): void => {
    setCompletedDialogOpen(true);
    setMissingBreakDialogOpen(false);
  }, []);

  const handleMissingBreakDialogCancel = useCallback((): void => {
    setMissingBreakDialogOpen(false);
  }, []);

  const handleMissingProductDialogOk = useCallback((): void => {
    setCompletedDialogOpen(!openMissingBreakDialogAfterMissingProductDialog);
    setMissingBreakDialogOpen(openMissingBreakDialogAfterMissingProductDialog);
    setMissingProductDialogOpen(false);
  }, [openMissingBreakDialogAfterMissingProductDialog]);

  const handleMissingProductDialogCancel = useCallback((): void => {
    setMissingProductDialogOpen(false);
    setOpenMissingBreakDialogAfterMissingProductDialog(false);
  }, []);

  const getTimerStarts = useCallback((): TimerStart[] => {
    const taskURL = task.url;
    return _.sortBy(
      timerStartArray.filter((instance) => instance.task === taskURL),
      getNormalisedDeviceTimestamp,
    );
  }, [task.url, timerStartArray]);

  const handleCompletedDialogOk = useCallback(
    (
      _event: unknown,
      workshopTaskParameters: {
        machineUse: readonly MachineUse[] | null;
        needsPreparation: boolean;
        needsPreparationMinutes: number;
        needsRepair: boolean;
        repairNote: string;
        selectedMachines: readonly MachineUrl[];
        selectedPreparationMachines: readonly MachineUrl[];
      } | null,
      registerAccommodationGroup: string | null,
    ): void => {
      // FIXME: sanity-check timer stop logic here
      const cancelled = !!completedDialogCancelled;
      const continuation = completedDialogContinuation;
      const completedAsInternal = completedDialogCompletedAsInternal;
      setCompletedDialogOpen(false);

      const computeTimestamp = new Date();
      const timerStarts = getTimerStarts();
      const newComputedIntervals = computeIntervalsTruncated(
        timerStarts,
        computeTimestamp.toISOString(),
      );
      const correctionIntervals = task.machineOperatorTimeCorrectionSet || [];
      const managerCorrectionIntervals = task.managerTimeCorrectionSet || [];
      const newIntervals = mergeIntervals(
        newComputedIntervals,
        correctionIntervals,
        managerCorrectionIntervals,
        computeTimestamp.toISOString(),
      );
      const {workFromTimestamp, workToTimestamp} = computeWorkFromTo(newIntervals);

      if (registerAccommodationGroup && workFromTimestamp && workToTimestamp) {
        const fromDateTime = new Date(workFromTimestamp);
        const toDateTime = new Date(workToTimestamp);
        if (differenceInCalendarDays(toDateTime, fromDateTime) > 0) {
          const dates = eachDayOfInterval({
            end: toDateTime,
            start: fromDateTime,
          });
          dates.pop();
          dates.forEach((date) => {
            const id = uuid();
            const url = instanceURL("accomodationAllowance", id);
            const instance: AccomodationAllowance = {
              date: dateToString(date),
              employee: task.machineOperator as UserUrl,
              id,
              remunerationGroup: registerAccommodationGroup,
              url,
            };
            dispatch(actions.create(instance));
          });
        } else {
          const id = uuid();
          const url = instanceURL("accomodationAllowance", id);
          const instance: AccomodationAllowance = {
            date: dateToString(fromDateTime),
            employee: task.machineOperator as UserUrl,
            id,
            remunerationGroup: registerAccommodationGroup,
            url,
          };
          dispatch(actions.create(instance));
        }
      }
      if (workshopTaskParameters) {
        const {needsPreparationMinutes, repairNote, selectedMachines, selectedPreparationMachines} =
          workshopTaskParameters;
        if (selectedPreparationMachines.length > 0) {
          const machineuseSet = selectedPreparationMachines.map((machine) => {
            return {machine, priceGroup: null, transporter: false};
          });
          const id = uuid();
          const url = instanceURL("task", id);
          const workTypeIdentifier = customerSettings.preparationWorkType;
          const workType = workTypeArray.find((w) => w.identifier === workTypeIdentifier);
          const workTypeURL = workType ? workType.url : null;
          const preparationTask: Task = {
            ...emptyTask,
            createdBy: currentUserURL,
            id,
            machineOperator: customerSettings.defaultTaskEmployee
              ? instanceURL("user", customerSettings.defaultTaskEmployee)
              : null,
            machineuseSet,
            minutesExpectedTotalTaskDuration: needsPreparationMinutes,
            url,
            workType: workTypeURL,
          };
          dispatch(actions.create(preparationTask));
        }
        if (selectedMachines.length > 0) {
          const machineuseSet = selectedMachines.map((machine) => {
            return {machine, priceGroup: null, transporter: false};
          });
          const id = uuid();
          const url = instanceURL("task", id);
          const workTypeIdentifier = customerSettings.repairWorkType;
          const workType = workTypeArray.find((w) => w.identifier === workTypeIdentifier);
          const workTypeURL = workType ? workType.url : null;
          const repairTask: Task = {
            ...emptyTask,
            createdBy: currentUserURL,
            id,
            machineOperator: customerSettings.defaultTaskEmployee
              ? instanceURL("user", customerSettings.defaultTaskEmployee)
              : null,
            machineuseSet,
            notesFromMachineOperator: repairNote,
            url,
            workType: workTypeURL,
          };
          dispatch(actions.create(repairTask));
        }
      }

      const patch: Patch<Task> = [
        {member: "cancelled", value: cancelled},
        {member: "completed", value: true},
        {member: "computedTimeSet", value: newComputedIntervals},
        {member: "workFromTimestamp", value: workFromTimestamp},
        {member: "workToTimestamp", value: workToTimestamp},
      ];

      dispatch(actions.update(task.url, patch));
      if (customerSettings.autoCreateLogPrint) {
        buildReports();
      }
      if (continuation) {
        const machineOperatorProfile =
          task.machineOperator && userUserProfileLookup(task.machineOperator);
        const holidayCalendars = getEmployeeHolidayCalendars(
          customerSettings,
          machineOperatorProfile,
        );
        const currentDate =
          (task.workFromTimestamp
            ? new Date(task.workFromTimestamp)
            : task.date
              ? dateFromString(task.date)
              : new Date()) || new Date();

        const continuationDate = dateToString(
          completedAsInternal
            ? getCompletedAsInternalNewDate(
                holidayCalendars,
                currentDate,
                customerSettings.completeTaskAsInternalTargetDate,
              )
            : getContinuationTaskTargetDate(
                holidayCalendars,
                currentDate,
                customerSettings.continuationTaskTargetDate,
              ),
        );

        let {minutesExpectedTotalTaskDuration} = task;
        if (minutesExpectedTotalTaskDuration) {
          const timerMinutes = computeIntervalSums(newIntervals, computeTimestamp);
          const primaryTimerURL = genericPrimaryTimer.url;
          let minutes = 0;
          if (completedAsInternal) {
            timerMinutes.forEach((timerValue, _timer) => {
              minutes += timerValue;
            });
          } else {
            minutes = timerMinutes.get(primaryTimerURL) || 0;
          }
          if (minutesExpectedTotalTaskDuration > minutes) {
            minutesExpectedTotalTaskDuration -= minutes;
          } else {
            minutesExpectedTotalTaskDuration = null;
          }
        }
        const overrides: Partial<Writable<Task>> = {
          completed: false,
          minutesExpectedTotalTaskDuration,
          time: null,
        };
        if (!customerSettings.continuationCopyFields.includes("order") && task.order) {
          if (order) {
            const orderCopyID = uuid();
            const orderCopyURL = instanceURL("order", orderCopyID);
            const orderCopy: Writable<Order> = {
              ...order,
              billed: false,
              createdBy: currentUserURL,
              date: continuationDate,
              id: orderCopyID,
              remoteUrl: "",
              url: orderCopyURL,
              validatedAndRecorded: false,
            };
            delete orderCopy.created;
            dispatch(actions.create(orderCopy));
            overrides.order = orderCopyURL;
          }
        }

        overrides.date = continuationDate;
        const copyResult = copyTaskWithLogsLocations(
          task,
          customerSettings.continuationCopyFields,
          overrides,
          {
            deliveryLocationArray,
            locationLookup,
            machineLookup,
            pickupLocationArray,
            priceGroupLookup,
            productLookup,
            projectLookup,
            reportingSpecificationLookup,
            sprayLocationArray,
            sprayLogArray,
            transportLogArray,
            workTypeLookup,
            yieldDeliveryLocationArray,
            yieldLogArray,
            yieldPickupLocationArray,
          },
          dispatch,
          intl,
          customerSettings,
        );
        copyResult.forEach((instance) => {
          dispatch(actions.createOrUpdate(instance));
        });
        const taskCopy = copyResult[0];
        const newURL = taskCopy.url;
        if (completedAsInternal) {
          dispatch(
            actions.update(task.url, [
              {member: "completedAsInternal", value: true},
              {member: "continuationTask", value: newURL},
            ]),
          );
        }
      }

      if (!nextCustomerDialogOpen) {
        window.setTimeout(() => {
          batch(() => {
            dispatch(
              actions.forwardBackSkip([
                "/order/:id",
                "/order/:id/:taskID",
                "/orderEntry/:id",
                "/task/:id",
                "/taskDetails/:id",
                "/taskEdit/:id",
                "/internalTask/:id",
              ]),
            );
          });
        });
      }
    },
    [
      buildReports,
      completedDialogCancelled,
      completedDialogCompletedAsInternal,
      completedDialogContinuation,
      currentUserURL,
      customerSettings,
      deliveryLocationArray,
      dispatch,
      genericPrimaryTimer.url,
      getTimerStarts,
      intl,
      locationLookup,
      machineLookup,
      nextCustomerDialogOpen,
      order,
      pickupLocationArray,
      priceGroupLookup,
      productLookup,
      projectLookup,
      reportingSpecificationLookup,
      sprayLocationArray,
      sprayLogArray,
      task,
      transportLogArray,
      userUserProfileLookup,
      workTypeArray,
      workTypeLookup,
      yieldDeliveryLocationArray,
      yieldLogArray,
      yieldPickupLocationArray,
    ],
  );

  const handleNextCustomerDialogCancel = useCallback((): void => {
    setNextCustomerDialogOpen(false);
  }, []);

  const handleNextCustomerDialogOk = useCallback(
    (nextCustomerURL: CustomerUrl, inputWorkplace?: Location | null): void => {
      setNextCustomerDialogOpen(false);
      let workplace = inputWorkplace;
      if (workplace === undefined) {
        const customerWorkplaceList = locationArray.filter(
          (w) => w.active && w.customer === nextCustomerURL,
        );
        if (customerWorkplaceList.length === 1) {
          workplace = customerWorkplaceList[0];
        } else if (customerWorkplaceList.length) {
          const callback = (w: Location | null): void =>
            handleNextCustomerDialogOk(nextCustomerURL, w);

          handleRequestWorkplaceDialog(callback, nextCustomerURL);
          return;
        }
      }
      if (!task || !order) {
        return;
      }
      const today = dateToString(new Date());
      const orderCopyID = uuid();
      const orderCopyURL = instanceURL("order", orderCopyID);
      const newContact = contactArray.find(
        (instance) =>
          instance.customer === nextCustomerURL && instance.active && instance.defaultContact,
      );
      const contactURL = newContact ? newContact.url : null;
      const nextCustomer = customerLookup(nextCustomerURL);
      const address = nextCustomer ? formatAddress(nextCustomer) : "";
      const orderCopy: Writable<Order> = {
        ...order,
        address,
        billed: false,
        contact: contactURL,
        createdBy: currentUserURL,
        customer: nextCustomerURL,
        date: today,
        id: orderCopyID,
        remoteUrl: "",
        url: orderCopyURL,
      };
      delete orderCopy.created;
      const workplaceURL = workplace ? workplace.url : null;
      const overrides: Partial<Writable<Task>> = {
        address,
        createdBy: currentUserURL,
        machineOperator: currentUserURL,
        notesFromMachineOperator: "",
        notesFromManager: "",
        order: orderCopyURL,
        relatedWorkplace: workplaceURL,
      };
      if (task.date == null || task.date < today) {
        overrides["date"] = today;
      }
      const copyResult = copyTaskWithLogs(
        task,
        customerSettings.taskCopyFields,
        overrides,
        {
          locationLookup,
          machineLookup,
          priceGroupLookup,
          productLookup,
          projectLookup,
          reportingSpecificationLookup,
          sprayLogArray,
          transportLogArray,
          workTypeLookup,
          yieldLogArray,
        },
        dispatch,
        intl,
        customerSettings,
      );
      const taskCopy = copyResult[0];
      const taskCopyID = urlToId(taskCopy.url);
      if (orderCopy) {
        dispatch(actions.create(orderCopy));
      }
      dispatch(actions.create(taskCopy));
      // skipping the task instance; it is handled otherwise; only save logs/other...
      for (let i = 1; i < copyResult.length; i += 1) {
        const instance = copyResult[i];
        dispatch(actions.createOrUpdate(instance));
      }
      window.setTimeout(() => {
        batch(() => {
          dispatch(actions.go("/task/:id", {id: taskCopyID}, {}, "REPLACE"));
          setNotesDialogDismissed(false);
        });
      }, 0);
    },
    [
      contactArray,
      currentUserURL,
      customerLookup,
      customerSettings,
      dispatch,
      handleRequestWorkplaceDialog,
      intl,
      locationArray,
      locationLookup,
      machineLookup,
      order,
      priceGroupLookup,
      productLookup,
      projectLookup,
      reportingSpecificationLookup,
      sprayLogArray,
      task,
      transportLogArray,
      workTypeLookup,
      yieldLogArray,
    ],
  );

  const handleGoToOrder = useCallback((): void => {
    if (orderURL) {
      const orderID = urlToId(orderURL);
      const taskID = urlToId(task.url);
      dispatch(actions.go("/order/:id/:taskID", {id: orderID, taskID}));
    }
  }, [dispatch, orderURL, task.url]);

  const handleCompletedValidatedDialogAction = useCallback(
    (action: ErrorAction): void => {
      if (action === ADD_MACHINE_ACTION) {
        setApprovedDialogOpen(false);
        setCompletedDialogOpen(false);
        setMachineDialogOpen(true);
        setValidatedDialogOpen(false);
      } else if (action === APPROVE_CUSTOMER_ACTION) {
        setCustomerApproveDialogOpen(true);
      } else if (action === ADD_PHOTO_ACTION) {
        setApprovedDialogOpen(false);
        setCompletedDialogOpen(false);
        setValidatedDialogOpen(false);
        dispatch(actions.putQueryKey("tab", "photos"));
      } else if (action === ADD_TIME_ACTION || action === EDIT_MACHINE_ACTION) {
        setApprovedDialogOpen(false);
        setCompletedDialogOpen(false);
        setValidatedDialogOpen(false);
        dispatch(actions.putQueryKey("tab", "time"));
      } else if (action === EDIT_NOTE_ACTION) {
        setApprovedDialogOpen(false);
        setCompletedDialogOpen(false);
        setValidatedDialogOpen(false);
        dispatch(actions.putQueryKey("tab", "time"));
      } else if (action === CHANGE_MATERIAL_ACTION) {
        setApprovedDialogOpen(false);
        setCompletedDialogOpen(false);
        setValidatedDialogOpen(false);
        dispatch(actions.putQueryKey("tab", "products"));
      } else if (action === CHANGE_SMALL_MACHINE_ACTION) {
        setApprovedDialogOpen(false);
        setCompletedDialogOpen(false);
        setValidatedDialogOpen(false);
        dispatch(actions.putQueryKey("tab", "smallMachines"));
      } else if (action === SELECT_WORK_TYPE_ACTION) {
        setApprovedDialogOpen(false);
        setCompletedDialogOpen(false);
        setValidatedDialogOpen(false);
        if (workTypeSelectionControl.current) {
          workTypeSelectionControl.current.start();
        }
      } else if (action === CHANGE_CUSTOMER_ACTION) {
        setApprovedDialogOpen(false);
        setCompletedDialogOpen(false);
        setCustomerDialogOpen(true);
        setValidatedDialogOpen(false);
      } else if (action === CHANGE_EMPLOYEE_ACTION) {
        setApprovedDialogOpen(false);
        setCompletedDialogOpen(false);
        setMachineOperatorDialogOpen(true);
        setValidatedDialogOpen(false);
      } else if (action === CHANGE_DEPARTMENT_ACTION) {
        setApprovedDialogOpen(false);
        setCompletedDialogOpen(false);
        setDepartmentDialogOpen(true);
        setValidatedDialogOpen(false);
      } else if (action === ADD_PROJECT_ACTION || action === ADD_WORKPLACE_ACTION) {
        setApprovedDialogOpen(false);
        setCompletedDialogOpen(false);
        setValidatedDialogOpen(false);
        handleGoToOrder();
      } else if (action === ADD_FIELD_ACTION) {
        setApprovedDialogOpen(false);
        setCompletedDialogOpen(false);
        setValidatedDialogOpen(false);
        dispatch(actions.putQueryKey("tab", "info"));
      } else if (action === EDIT_LOG_ACTION) {
        setApprovedDialogOpen(false);
        setCompletedDialogOpen(false);
        setValidatedDialogOpen(false);
        if (bowser.mobile || customerSettings.alwaysUseLogTab) {
          dispatch(actions.putQueryKey("tab", "log"));
        } else {
          dispatch(actions.putQueryKey("tab", "time"));
        }
      } else {
        const sentry = getFrontendSentry();
        sentry.captureMessage(
          `Unhandled error action "${action}" encountered in TaskInstance.handleCompletedValidatedDialogAction`,
          "error",
        );
        setApprovedDialogOpen(false);
        setCompletedDialogOpen(false);
        setValidatedDialogOpen(false);
      }
    },
    [customerSettings.alwaysUseLogTab, dispatch, handleGoToOrder],
  );

  const handleExtraWorkButton = useCallback((): void => {
    setEnterExtraWorkNoteDialogOpen(true);
  }, []);

  const handleEnterExtraWorkNoteDialogCancel = useCallback((): void => {
    setEnterExtraWorkNoteDialogOpen(false);
  }, []);

  const taskCopy = useCallback(
    (note?: string, resetProject?: boolean): void => {
      if (!task || (orderURL && !order)) {
        return;
      }
      const today = dateToString(new Date());
      let overrideOrderURL = order?.url || null;
      let orderCopy: Writable<Order> | undefined;
      if (!customerSettings.taskCopyFields.includes("order") && order) {
        const orderCopyID = uuid();
        overrideOrderURL = instanceURL("order", orderCopyID);
        orderCopy = {
          ...order,
          billed: false,
          createdBy: currentUserURL,
          date: today,
          id: orderCopyID,
          remoteUrl: "",
          url: overrideOrderURL,
        };
        const contact = orderCopy.contact ? contactLookup(orderCopy.contact) : null;
        if (contact && !contact.active) {
          orderCopy.contact = null;
        }
        delete orderCopy.created;
      }
      const overrides: Partial<Writable<Task>> = {
        createdBy: currentUserURL,
        order: overrideOrderURL,
      };
      if (
        resetProject ||
        !currentRole ||
        !currentRole.manager ||
        customerSettings.taskCopyAlwaysOverridemachineOperator
      ) {
        overrides.machineOperator = currentUserURL;
      }
      if (task.date == null || task.date < today) {
        overrides["date"] = today;
      }
      if (resetProject) {
        overrides.project = null;
      }
      if (note != null) {
        overrides.notesFromMachineOperator = note;
      }
      const copyResult = copyTaskWithLogsLocations(
        task,
        customerSettings.taskCopyFields,
        overrides,
        {
          deliveryLocationArray,
          locationLookup,
          machineLookup,
          pickupLocationArray,
          priceGroupLookup,
          productLookup,
          projectLookup,
          reportingSpecificationLookup,
          sprayLocationArray,
          sprayLogArray,
          transportLogArray,
          workTypeLookup,
          yieldDeliveryLocationArray,
          yieldLogArray,
          yieldPickupLocationArray,
        },
        dispatch,
        intl,
        customerSettings,
      );
      if (orderCopy) {
        dispatch(actions.create(orderCopy));
      }
      copyResult.forEach((instance) => {
        dispatch(actions.createOrUpdate(instance));
      });
      const taskCopyID = urlToId(copyResult[0].url);
      window.setTimeout(() => {
        batch(() => {
          dispatch(actions.go("/task/:id", {id: taskCopyID}, {}, "REPLACE"));
          setCopiedDialogOpen(true);
        });
      }, 0);
    },
    [
      contactLookup,
      currentRole,
      currentUserURL,
      customerSettings,
      deliveryLocationArray,
      dispatch,
      intl,
      locationLookup,
      machineLookup,
      order,
      orderURL,
      pickupLocationArray,
      priceGroupLookup,
      productLookup,
      projectLookup,
      reportingSpecificationLookup,
      sprayLocationArray,
      sprayLogArray,
      task,
      transportLogArray,
      workTypeLookup,
      yieldDeliveryLocationArray,
      yieldLogArray,
      yieldPickupLocationArray,
    ],
  );

  const handleEnterExtraWorkNoteDialogOk = useCallback(
    (note: string): void => {
      setEnterExtraWorkNoteDialogOpen(false);
      taskCopy(note, !customerSettings.extraWorkButtonCopiesProject);
    },
    [customerSettings.extraWorkButtonCopiesProject, taskCopy],
  );

  const handleCopyButton = useCallback((): void => {
    taskCopy();
  }, [taskCopy]);

  const handleChangeCustomerButton = useCallback((): void => {
    setCustomerDialogOpen(true);
  }, []);

  const handleRemoveFieldsDialogCancel = useCallback((): void => {
    setRemoveFieldsDialogOpen(false);
  }, []);

  const handleRemoveFieldsDialogOk = useCallback((): void => {
    setRemoveFieldsDialogOpen(false);

    if (!orderURL || !order) {
      return;
    }

    const reportingSpecification =
      task.reportingSpecification && !task.logSkipped
        ? reportingSpecificationLookup(task.reportingSpecification)
        : undefined;
    const taskLogUsingFields = !!(
      reportingSpecification?.fieldsUsedFor && reportingSpecification?.fieldsUsedFor !== "unused"
    );

    const usedFieldsUrls =
      taskLogUsingFields && task.reportingLocations && task.reportingLog
        ? getUsedFieldUrlSet(task.reportingLocations, task.reportingLog)
        : undefined;

    const newFieldUseList = task.fielduseSet.filter((use) => usedFieldsUrls?.has(use.relatedField));

    dispatch(actions.update(task.url, [{member: "fielduseSet", value: newFieldUseList}]));
  }, [
    dispatch,
    order,
    orderURL,
    reportingSpecificationLookup,
    task.fielduseSet,
    task.logSkipped,
    task.reportingLocations,
    task.reportingLog,
    task.reportingSpecification,
    task.url,
  ]);

  const handleChangeCustomerCancel = useCallback((): void => {
    setCustomerDialogOpen(false);
  }, []);

  const changeCustomerCulture = useCallback(
    (selectedCustomerURL: CustomerUrl | null, selectedCultureURL: CultureUrl | null): void => {
      taskChangeCustomerCulture(
        selectedCustomerURL,
        selectedCultureURL,
        {
          contactArray,
          create: boundCreate,
          customerLookup,
          orderLookup,
          task,
          taskArray,
          unitLookup,
          update: boundUpdate,
        },
        customerSettings,
        currentUserURL,
      );
      setTimeout(() => {
        setNotesDialogDismissed(false);
      });
    },
    [
      boundCreate,
      boundUpdate,
      contactArray,
      currentUserURL,
      customerLookup,
      customerSettings,
      orderLookup,
      task,
      taskArray,
      unitLookup,
    ],
  );

  const handleChangeWorkplace = useCallback(
    (location: Location | null): void => {
      dispatch(
        actions.update(task.url, [
          {member: "relatedWorkplace", value: location ? location.url : null},
        ]),
      );
    },
    [dispatch, task.url],
  );

  const handleChangeCustomerOk = useCallback(
    (url: CustomerUrl): void => {
      setCustomerDialogOpen(false);
      if (!order) {
        return;
      }
      const oldURL = order.customer;
      if (url !== oldURL) {
        changeCustomerCulture(url, null);
        if (task.fielduseSet.length) {
          setRemoveFieldsDialogOpen(true);
        }
      }

      const {
        locations: {canChangeWorkplace, canCreateWorkplace},
        taskWizardAskForWorkPlace,
      } = customerSettings;

      const hasSelectableWorkPlaces = canChangeWorkplace
        ? locationArray.some(
            (location) => location.customer === url && location.active && !location.logOnlyLocation,
          )
        : locationArray.some(
            (location) => location.remoteUrl && location.customer === url && location.active,
          );
      if (taskWizardAskForWorkPlace && (canCreateWorkplace || hasSelectableWorkPlaces)) {
        setWorkplaceDialogCallback(handleChangeWorkplace);
        setWorkplaceDialogCustomerURL(url);
      }
    },
    [
      changeCustomerCulture,
      customerSettings,
      handleChangeWorkplace,
      locationArray,
      order,
      task.fielduseSet.length,
    ],
  );

  const handleChangeCultureButton = useCallback((): void => {
    setCultureDialogOpen(true);
  }, []);

  const handleCultureDialogCancel = useCallback((): void => {
    setCultureDialogOpen(false);
  }, []);

  const handleCultureDialogOk = useCallback(
    (url: CultureUrl): void => {
      setCultureDialogOpen(false);
      const selectedCulture = cultureLookup(url);
      if (!task || !order || !selectedCulture) {
        return;
      }
      const oldURL = order.culture;
      if (url !== oldURL) {
        changeCustomerCulture(null, url);
      }
    },
    [changeCustomerCulture, cultureLookup, order, task],
  );

  const handleWorkTypeSelectButton = useCallback((): void => {
    if (workTypeSelectionControl.current) {
      workTypeSelectionControl.current.start();
    }
  }, []);

  const handleWorkTypePriceGroupSelection = useCallback(
    (workTypeUrl: WorkTypeUrl, priceGroupUrl: PriceGroupUrl | null): void => {
      const patch: Patch<Task> = [
        {member: "priceGroup", value: priceGroupUrl},
        {member: "workType", value: workTypeUrl},
      ];
      dispatch(actions.update(task.url, patch));
    },
    [dispatch, task.url],
  );

  const handleSaveButton = useCallback((): void => {
    dispatch(actions.startOnlineSaves());
  }, [dispatch]);

  const handleNotesDialogClose = useCallback((): void => {
    setNotesDialogDismissed(true);
  }, []);

  const handleValidatedButton = useCallback((): void => {
    // stop timer, just in case
    timerChange(null);
    const {useApproveReport} = customerSettings;
    if (useApproveReport) {
      setApprovedDialogOpen(true);
    } else {
      setValidatedDialogOpen(true);
    }
  }, [customerSettings, timerChange]);

  const handleValidatedDialogOk = useCallback((): void => {
    setValidatedDialogOpen(false);
    console.assert(task.completed);
    if (!task.completed) {
      return;
    }
    const patch: PatchOperation<Task>[] = [{member: "validatedAndRecorded", value: true}];
    if (!willTaskBeRecorded(task, order, customerSettings, timerStartArray, breakTimerURL)) {
      patch.push({member: "archivable", value: true});
    }
    dispatch(actions.update(task.url, patch));
    setTimeout(() => {
      batch(() => {
        dispatch(actions.back());
      });
    });
  }, [breakTimerURL, customerSettings, dispatch, order, task, timerStartArray]);

  const handleValidatedDialogCancel = useCallback((): void => {
    setValidatedDialogOpen(false);
  }, []);

  const handleApprovedDialogOk = useCallback((): void => {
    setApprovedDialogOpen(false);
    console.assert(task.completed);
    if (!task.completed) {
      return;
    }
    const patch: PatchOperation<Task>[] = [{member: "reportApproved", value: true}];
    if (customerSettings.economicSync) {
      if (!willTaskBeRecorded(task, order, customerSettings, timerStartArray, breakTimerURL)) {
        patch.push({member: "archivable", value: true});
      }
    }
    dispatch(actions.update(task.url, patch));
    setTimeout(() => {
      batch(() => {
        dispatch(actions.back());
      });
    });
  }, [breakTimerURL, customerSettings, dispatch, order, task, timerStartArray]);

  const handleApprovedDialogCancel = useCallback((): void => {
    setApprovedDialogOpen(false);
  }, []);

  const handleTakeButton = useCallback((): void => {
    dispatch(actions.update(task.url, [{member: "machineOperator", value: currentUserURL}]));
  }, [currentUserURL, dispatch, task.url]);

  const handleCopiedDialogClose = useCallback((): void => {
    setCopiedDialogOpen(false);
  }, []);

  const addTimeCorrectionArray = useCallback(
    (corrections: [string, string, TimerUrl | null][], asManager: boolean): void => {
      let oldIntervals;
      if (asManager) {
        oldIntervals = task.managerTimeCorrectionSet || [];
      } else {
        oldIntervals = task.machineOperatorTimeCorrectionSet || [];
      }
      let newIntervals = oldIntervals.slice();
      let removeCompletedAsInternal = false;
      const completedAsInternal = task.completedAsInternal || task.continuationTask;

      corrections.forEach(([inputFromTimestamp, inputToTimestamp, timerURL]) => {
        let fromTimestamp = inputFromTimestamp;
        let toTimestamp = inputToTimestamp;
        if (completedAsInternal) {
          const timer = timerURL ? timerLookup(timerURL) : null;
          const isInvoicedTimer =
            timer &&
            (timer.isGenericEffectiveTime ||
              timer.priceGroup ||
              (timer.workType && workTypeLookup(timer.workType)?.productive));
          if (isInvoicedTimer) {
            removeCompletedAsInternal = true;
          }
        }

        const correctionLengthMilliseconds =
          new Date(toTimestamp).valueOf() - new Date(fromTimestamp).valueOf();
        if (correctionLengthMilliseconds < MINUTE_MILLISECONDS) {
          // ignore corrections of less than a minute
          return;
        }
        const intersectsPredicate = (interval: TimeCorrection): boolean => {
          return interval.toTimestamp > fromTimestamp && interval.fromTimestamp < toTimestamp;
        };
        const nonIntersectingIntervals = newIntervals.filter(_.negate(intersectsPredicate));
        const intersectingIntervals = newIntervals.filter(intersectsPredicate);
        newIntervals = nonIntersectingIntervals;
        if (!intersectingIntervals.length) {
          newIntervals.push({fromTimestamp, timer: timerURL, toTimestamp});
        } else {
          const sameWorkTypePredicate = (interval: TimeCorrection): boolean => {
            return interval.timer === timerURL;
          };
          const intersectingSameWorktype = intersectingIntervals.filter(sameWorkTypePredicate);
          intersectingSameWorktype.forEach((interval) => {
            const intervalFromTimestamp = interval.fromTimestamp;
            const intervalToTimestamp = interval.toTimestamp;
            if (intervalFromTimestamp < fromTimestamp) {
              fromTimestamp = intervalFromTimestamp;
            }
            if (intervalToTimestamp > toTimestamp) {
              toTimestamp = intervalToTimestamp;
            }
          });
          newIntervals.push({fromTimestamp, timer: timerURL, toTimestamp});
          const intersectingDifferentWorkType = intersectingIntervals.filter(
            _.negate(sameWorkTypePredicate),
          );
          intersectingDifferentWorkType.forEach((interval) => {
            // split into part before new interval and part after interval; both might be empty/have negative length...
            const intervalBeyondStart = {
              ...interval,
              toTimestamp: fromTimestamp,
            };
            const intervalBeyondEnd = {
              ...interval,
              fromTimestamp: toTimestamp,
            };
            const intervalBeyondStartLength =
              new Date(intervalBeyondStart.toTimestamp).valueOf() -
              new Date(intervalBeyondStart.fromTimestamp).valueOf();
            if (intervalBeyondStartLength > MINUTE_MILLISECONDS) {
              newIntervals = [...newIntervals, intervalBeyondStart];
            }
            const intervalBeyondEndLength =
              new Date(intervalBeyondEnd.toTimestamp).valueOf() -
              new Date(intervalBeyondEnd.fromTimestamp).valueOf();
            if (intervalBeyondEndLength > MINUTE_MILLISECONDS) {
              newIntervals = [...newIntervals, intervalBeyondEnd];
            }
          });
        }
        newIntervals = _.sortBy(newIntervals, (interval) => interval.fromTimestamp);
      });
      // cleanup
      if (newIntervals.length) {
        const cleaned = [];
        let previous = newIntervals[0];
        newIntervals.slice(1).forEach((interval) => {
          if (interval.timer !== previous.timer) {
            cleaned.push(previous);
            previous = interval;
          } else {
            const distance =
              new Date(interval.fromTimestamp).valueOf() - new Date(previous.toTimestamp).valueOf();
            if (distance < MINUTE_MILLISECONDS) {
              previous = {...previous, toTimestamp: interval.toTimestamp};
            } else {
              cleaned.push(previous);
              previous = interval;
            }
          }
        });
        cleaned.push(previous);
        newIntervals = cleaned;
      }
      if (!_.isEqual(newIntervals, oldIntervals)) {
        const patch: PatchOperation<Task>[] = [];
        if (asManager) {
          // perform manager time corrections
          patch.push({member: "managerTimeCorrectionSet", value: newIntervals});
        } else {
          // perform machine operator time corrections
          patch.push({
            member: "machineOperatorTimeCorrectionSet",
            value: newIntervals,
          });
        }
        const correctionIntervals = asManager
          ? task.machineOperatorTimeCorrectionSet
          : newIntervals;
        const managerCorrectionIntervals = asManager ? newIntervals : task.managerTimeCorrectionSet;
        const mergedIntervals = mergeIntervals(
          computedIntervals,
          correctionIntervals,
          managerCorrectionIntervals,
        );
        const timerUrls = new Set(mergedIntervals.map((i) => i.timer));
        const newTimerNotesSet = task.timernotesSet.filter((timerNote) =>
          timerUrls.has(timerNote.timer),
        );
        if (!_.isEqual(newTimerNotesSet, task.timernotesSet)) {
          patch.push({member: "timernotesSet", value: newTimerNotesSet});
        }

        if (completedAsInternal && removeCompletedAsInternal) {
          patch.push(
            {member: "completedAsInternal", value: false},
            {member: "continuationTask", value: null},
          );
        }
        dispatch(actions.update(task.url, patch));
      }
      const {onlyShowCalledInDialogForWorktypes, showCalledInDialogAfterMinutes} = customerSettings;
      const workType = task.workType && workTypeLookup(task.workType);

      if (
        !asManager &&
        showCalledInDialogAfterMinutes != null &&
        (!onlyShowCalledInDialogForWorktypes.length ||
          (workType && onlyShowCalledInDialogForWorktypes.includes(workType.identifier)))
      ) {
        const machineOperator = task.machineOperator && userLookup(task.machineOperator);
        const machineOperatorProfile =
          task.machineOperator && userUserProfileLookup(task.machineOperator);
        setCalledInDialogOpen(
          !!machineOperator &&
            !!machineOperatorProfile &&
            showCalledInDialog({
              customerSettings,
              daysAbsenceArray,
              machineOperator,
              machineOperatorProfile,
              machineOperatorTimeCorrectionSet: newIntervals,
              task,
              timerStartArray,
            }),
        );
      }
    },
    [
      computedIntervals,
      customerSettings,
      daysAbsenceArray,
      dispatch,
      task,
      timerLookup,
      timerStartArray,
      userLookup,
      userUserProfileLookup,
      workTypeLookup,
    ],
  );

  const addTimeCorrection = useCallback(
    (
      fromTimestamp: string,
      toTimestamp: string,
      timer: TimerUrl | null,
      asManager: boolean,
    ): void => {
      addTimeCorrectionArray([[fromTimestamp, toTimestamp, timer]], asManager);
    },
    [addTimeCorrectionArray],
  );

  const handleAddMachineOperatorTimeCorrection = useCallback(
    (fromTimestamp: string, toTimestamp: string, timer: TimerUrl | null): void => {
      addTimeCorrection(fromTimestamp, toTimestamp, timer, false);
    },
    [addTimeCorrection],
  );

  const timerButtonValid = useCallback(
    (timerURL: TimerUrl, inputLastTimerStart?: TimerStart): void => {
      const lastTimerStart =
        inputLastTimerStart ||
        (currentUserURL && getLastTimerStart(currentUserURL, timerStartArray));
      const lastTimerStartTaskURL = lastTimerStart && lastTimerStart.task;
      const computeTimestamp = new Date();
      if (
        lastTimerStart &&
        lastTimerStartTaskURL === task.url &&
        lastTimerStart.timer === timerURL
      ) {
        timerChange(null);
      } else {
        const oldDate = task.date;
        const oldTime = task.time;
        const nowDate = dateToString(computeTimestamp);
        const hoursDigits = 2;
        const minutesDigits = 2;
        const nowTime = `${padZero(computeTimestamp.getHours(), hoursDigits)}:${padZero(
          computeTimestamp.getMinutes(),
          minutesDigits,
        )}`;
        if (!oldDate || oldDate > nowDate) {
          dispatch(
            actions.update(task.url, [
              {member: "date", value: nowDate},
              {member: "time", value: nowTime},
            ]),
          );
        } else if (!oldTime || (oldDate === nowDate && oldTime > nowTime)) {
          dispatch(actions.update(task.url, [{member: "time", value: nowTime}]));
        }
        const timestamp = computeTimestamp.toISOString();
        timerChange(timerURL, timestamp);
        if (lastTimerStartTaskURL !== task.url) {
          const {autoFillUnregisteredMinutesOnStart} = customerSettings;
          if (autoFillUnregisteredMinutesOnStart != null) {
            const oldTask = lastTimerStartTaskURL && taskLookup(lastTimerStartTaskURL);
            if (oldTask) {
              const newComputedIntervals = getComputedTime(oldTask, timerStartArray, timestamp);
              const correctionIntervals = oldTask.machineOperatorTimeCorrectionSet || [];
              const managerCorrectionIntervals = oldTask.managerTimeCorrectionSet || [];
              const newIntervals = mergeIntervals(
                newComputedIntervals,
                correctionIntervals,
                managerCorrectionIntervals,
                timestamp,
              );
              const lastToTimestamp =
                newIntervals &&
                newIntervals.length &&
                newIntervals[newIntervals.length - 1].toTimestamp;
              if (lastToTimestamp) {
                const {unregisteredBreakAfterMinutes} = customerSettings;
                const lastToDate = new Date(lastToTimestamp);
                const differenceMilliseconds = computeTimestamp.valueOf() - lastToDate.valueOf();
                const differenceMinutes = differenceMilliseconds / MINUTE_MILLISECONDS;

                if (differenceMinutes >= 1 && differenceMinutes < unregisteredBreakAfterMinutes) {
                  if (differenceMinutes <= autoFillUnregisteredMinutesOnStart) {
                    handleAddMachineOperatorTimeCorrection(lastToTimestamp, timestamp, timerURL);
                  } else {
                    setAutoCorrectionDialog({
                      fromTimestamp: lastToTimestamp,
                      timerURL,
                      toTimestamp: timestamp,
                    });
                  }
                }
              }
            }
          }
        }
      }

      const {onlyShowCalledInDialogForWorktypes, showCalledInDialogAfterMinutes} = customerSettings;
      const workType = task.workType && workTypeLookup(task.workType);
      if (
        showCalledInDialogAfterMinutes != null &&
        (!onlyShowCalledInDialogForWorktypes.length ||
          (workType && onlyShowCalledInDialogForWorktypes.includes(workType.identifier)))
      ) {
        // As timerButtonValid() is run from a setTimeout-callback, rather than
        // a React event handler, React will not attempt to batch updates, and
        // we will have re-rendered with new data before each call to
        // this.timerChange() here returns. This means that
        // this.props.timerStartMap includes the new TimerStart-instances when
        // accessed here -- had it been bound to a local variable *before*
        // the calls to this.timerChange(), we would have the version
        // before/without the new TimerStart-instances...
        const machineOperator = task.machineOperator && userLookup(task.machineOperator);
        const machineOperatorProfile =
          task.machineOperator && userUserProfileLookup(task.machineOperator);
        setCalledInDialogOpen(
          !!machineOperator &&
            !!machineOperatorProfile &&
            showCalledInDialog({
              customerSettings,
              daysAbsenceArray,
              machineOperator,
              machineOperatorProfile,
              task,
              timerStartArray,
            }),
        );
      }
    },
    [
      currentUserURL,
      customerSettings,
      daysAbsenceArray,
      dispatch,
      handleAddMachineOperatorTimeCorrection,
      task,
      taskLookup,
      timerChange,
      timerStartArray,
      userLookup,
      userUserProfileLookup,
      workTypeLookup,
    ],
  );

  const handleTimerButton = useCallback((timerURL: TimerUrl): void => {
    setPerformOldTaskCheck(true);
    setTimerButtonClickedFor(timerURL);
  }, []);

  const {lastTaskTimerStart, oldTaskTimerStart, oldTimerTimerStart, requestedActiveTimer} =
    useMemo((): {
      lastTaskTimerStart: TimerStart | null;
      oldTaskTimerStart: TimerStart | null;
      oldTimerTimerStart: TimerStart | null;
      requestedActiveTimer: TimerUrl | null;
    } => {
      if (timerButtonClickedFor) {
        const timerURL = timerButtonClickedFor;
        const taskURL = task.url;
        const lastUserTimerStart = _.last(currentUserSortedTimerStartArray);
        const foundLastTaskTimerStart = _.findLast(
          currentUserSortedTimerStartArray,
          (timerStart) => timerStart.task === taskURL,
        );
        if (
          lastUserTimerStart &&
          lastUserTimerStart !== foundLastTaskTimerStart &&
          lastUserTimerStart.timer &&
          !customerSettings.concurrentTasksAllowed
        ) {
          // possibly conflict with other task...
          const lastTask = taskLookup(lastUserTimerStart.task);
          if (
            !concurrencyAllowedForTask(task, workTypeLookup, customerSettings) ||
            (lastTask && !concurrencyAllowedForTask(lastTask, workTypeLookup, customerSettings))
          ) {
            // Show "Stop other task?"-dialog
            return {
              lastTaskTimerStart: foundLastTaskTimerStart || null,
              oldTaskTimerStart: null,
              oldTimerTimerStart: lastUserTimerStart,
              requestedActiveTimer: timerURL,
            };
          }
        }
        const isFirstTimerStartOnTask = !foundLastTaskTimerStart;
        if (isFirstTimerStartOnTask && task.date && task.date !== dateToString(new Date())) {
          // Show "Wrong date, are you sure?"-dialog
          return {
            lastTaskTimerStart: foundLastTaskTimerStart || null,
            oldTaskTimerStart: null,
            oldTimerTimerStart: null,
            requestedActiveTimer: timerURL,
          };
        }
        const {oldTaskWarningAgeMinutes} = customerSettings;
        if (isFirstTimerStartOnTask && oldTaskWarningAgeMinutes != null && performOldTaskCheck) {
          const openTaskURLSet = new Set<string>();
          taskArray.forEach((otherTask) => {
            if (!otherTask.completed && otherTask.machineOperator === currentUserURL) {
              openTaskURLSet.add(otherTask.url);
            }
          });
          if (openTaskURLSet.size) {
            const foundOldTaskTimerStart = currentUserSortedTimerStartArray.find((timerStart) =>
              openTaskURLSet.has(timerStart.task),
            );
            if (foundOldTaskTimerStart) {
              const oldTaskTimerStartAge =
                new Date().valueOf() - new Date(foundOldTaskTimerStart.deviceTimestamp).valueOf();
              if (oldTaskTimerStartAge > oldTaskWarningAgeMinutes * MINUTE_MILLISECONDS) {
                return {
                  lastTaskTimerStart: foundLastTaskTimerStart || null,
                  oldTaskTimerStart: foundOldTaskTimerStart,
                  oldTimerTimerStart: null,
                  requestedActiveTimer: timerURL,
                };
              }
            }
          }
        }
      }
      return {
        lastTaskTimerStart: null,
        oldTaskTimerStart: null,
        oldTimerTimerStart: null,
        requestedActiveTimer: null,
      };
    }, [
      currentUserSortedTimerStartArray,
      currentUserURL,
      customerSettings,
      performOldTaskCheck,
      task,
      taskArray,
      taskLookup,
      timerButtonClickedFor,
      workTypeLookup,
    ]);

  useEffect(() => {
    if (
      timerButtonClickedFor &&
      !requestedActiveTimer &&
      !oldTimerTimerStart &&
      !oldTaskTimerStart
    ) {
      setTimerButtonClickedFor(null);
      timerButtonValid(timerButtonClickedFor, lastTaskTimerStart || undefined);
    }
  }, [
    lastTaskTimerStart,
    oldTaskTimerStart,
    oldTimerTimerStart,
    requestedActiveTimer,
    timerButtonClickedFor,
    timerButtonValid,
  ]);

  const handleTransportLogEntryAdded = useCallback((): void => {
    if (!customerSettings.geolocation.registerPositionOnTimerClick || !currentUserURL) {
      return;
    }
    const taskURL = task.url;
    dispatch(actions.registerTaskPosition(taskURL));
  }, [
    currentUserURL,
    customerSettings.geolocation.registerPositionOnTimerClick,
    dispatch,
    task.url,
  ]);

  const handleOldTaskOpenOk = useCallback((): void => {
    if (!requestedActiveTimer) {
      return;
    }
    setPerformOldTaskCheck(false);
  }, [requestedActiveTimer]);

  const handleOldTaskOpenCancel = useCallback((): void => {
    if (!oldTaskTimerStart) {
      return;
    }
    setTimerButtonClickedFor(null);
    window.setTimeout(() => {
      batch(() => {
        const taskID = urlToId(oldTaskTimerStart.task);
        dispatch(actions.go("/task/:id", {id: taskID}));
      });
    });
  }, [dispatch, oldTaskTimerStart]);

  const handleStopTimerDialogOk = useCallback((): void => {
    if (!oldTimerTimerStart || !requestedActiveTimer || !currentUserURL) {
      return;
    }
    const stopTaskURL = oldTimerTimerStart.task;
    saveTimerStart(
      boundCreate,
      null,
      stopTaskURL,
      currentUserURL,
      null,
      customerSettings.geolocation.registerPositionOnTimerClick &&
        currentUserURL === task.machineOperator
        ? boundRegisterTimerStartPosition
        : undefined,
    );
  }, [
    boundCreate,
    boundRegisterTimerStartPosition,
    currentUserURL,
    customerSettings.geolocation.registerPositionOnTimerClick,
    oldTimerTimerStart,
    requestedActiveTimer,
    task.machineOperator,
  ]);

  const handleStopTimerDialogCancel = useCallback((): void => {
    setTimerButtonClickedFor(null);
  }, []);

  const handleOtherDateDialogOk = useCallback((): void => {
    if (!requestedActiveTimer) {
      return;
    }
    const nowDate = dateToString(new Date());
    dispatch(actions.update(task.url, [{member: "date", value: nowDate}]));
  }, [dispatch, requestedActiveTimer, task.url]);

  const handleOtherDateDialogCancel = useCallback((): void => {
    setTimerButtonClickedFor(null);
  }, []);

  const handleRequestAddMachineOperatorTimeCorrection = useCallback((): void => {
    setMachineOperatorTimeCorrectionDialogOpen(true);
  }, []);

  const handleRequestAddManagerTimeCorrection = useCallback((): void => {
    setManagerTimeCorrectionDialogOpen(true);
  }, []);

  const handleCorrectionDialogCancel = useCallback((): void => {
    setMachineOperatorTimeCorrectionDialogOpen(false);
    setManagerTimeCorrectionDialogOpen(false);
  }, []);

  const handleAddManagerTimeCorrection = useCallback(
    (fromTimestamp: string, toTimestamp: string, timer: TimerUrl | null): void => {
      addTimeCorrection(fromTimestamp, toTimestamp, timer, true);
    },
    [addTimeCorrection],
  );

  const handleCorrectionDialogOk = useCallback(
    (fromTimestamp: string, toTimestamp: string, timer: TimerUrl | null): void => {
      handleCorrectionDialogCancel();
      if (machineOperatorTimeCorrectionDialogOpen) {
        handleAddMachineOperatorTimeCorrection(fromTimestamp, toTimestamp, timer);
      }
      if (managerTimeCorrectionDialogOpen) {
        handleAddManagerTimeCorrection(fromTimestamp, toTimestamp, timer);
      }
    },
    [
      handleAddMachineOperatorTimeCorrection,
      handleAddManagerTimeCorrection,
      handleCorrectionDialogCancel,
      machineOperatorTimeCorrectionDialogOpen,
      managerTimeCorrectionDialogOpen,
    ],
  );

  const handleTimelineIntervalClick = useCallback(
    (fromTimestamp: string, toTimestamp: string, timer: Timer | null): void => {
      setEditIntervalEnd(toTimestamp);
      setEditIntervalStart(fromTimestamp);
      setEditIntervalTimer(timer);
    },
    [],
  );

  const handleTimelineEditIntervalDialogCancel = useCallback((): void => {
    setEditIntervalEnd(null);
    setEditIntervalStart(null);
    setEditIntervalTimer(null);
  }, []);

  const handleTimelineEditIntervalDialogOk = useCallback(
    (fromTimestamp: string, toTimestamp: string, timerURL: TimerUrl | null): void => {
      if (!editIntervalStart || !editIntervalEnd) {
        return;
      }
      setEditIntervalEnd(null);
      setEditIntervalStart(null);
      setEditIntervalTimer(null);
      if (!task) {
        return;
      }
      const completed = !!(task ? task.completed : true);
      const validated = !!(task ? task.validatedAndRecorded : false);
      const role = currentRole;
      const userIsOnlyMachineOperator = role && !role.manager;
      const userIsOther = !task || task.machineOperator !== currentUserURL;
      let managerCorrections;
      if (!validated && !userIsOnlyMachineOperator) {
        // perform manager time corrections
        managerCorrections = true;
      } else if (!completed && !userIsOther) {
        // perform machine operator time corrections
        managerCorrections = false;
      } else {
        // no edit-permission...
        return;
      }
      const editIntervalTimerURL = editIntervalTimer ? editIntervalTimer.url : null;
      const corrections: [string, string, TimerUrl | null][] = [];

      if (timerURL === editIntervalTimerURL) {
        if (fromTimestamp < editIntervalStart) {
          corrections.push([fromTimestamp, editIntervalStart, editIntervalTimerURL]);
        }
        if (toTimestamp > editIntervalEnd) {
          corrections.push([editIntervalEnd, toTimestamp, editIntervalTimerURL]);
        }
      } else {
        corrections.push([fromTimestamp, toTimestamp, timerURL]);
      }
      if (fromTimestamp > editIntervalStart) {
        const previousInterval = _.findLast(intervals, (i) => i.toTimestamp <= editIntervalStart);
        let timer = null;
        if (previousInterval) {
          const distance =
            new Date(editIntervalStart).valueOf() -
            new Date(previousInterval.toTimestamp).valueOf();
          // if there's a minute or more of unregistered time in between, then the correction should be to unregistered
          if (distance <= MINUTE_MILLISECONDS) {
            ({timer} = previousInterval);
          }
        }
        corrections.push([editIntervalStart, fromTimestamp, timer]);
      }
      if (toTimestamp < editIntervalEnd) {
        const nextInterval = intervals.find((i) => i.fromTimestamp >= editIntervalEnd);
        let timer = null;
        if (nextInterval) {
          const distance =
            new Date(nextInterval.fromTimestamp).valueOf() - new Date(editIntervalEnd).valueOf();
          // if there's a minute or more of unregistered time in between, then the correction should be to unregistered
          if (distance <= MINUTE_MILLISECONDS) {
            ({timer} = nextInterval);
          }
        }
        corrections.push([toTimestamp, editIntervalEnd, timer]);
      }
      addTimeCorrectionArray(corrections, managerCorrections);
    },
    [
      addTimeCorrectionArray,
      currentRole,
      currentUserURL,
      editIntervalEnd,
      editIntervalStart,
      editIntervalTimer,
      intervals,
      task,
    ],
  );

  const handleTransportMachineSelectButton = useCallback((): void => {
    setTransportMachineDialogOpen(true);
  }, []);

  const handleTransportMachineDialogOk = useCallback(
    (url: string): void => {
      const oldMachines = task.machineuseSet;
      const newMachines = oldMachines.map((machineUse) => {
        const isTransporter = machineUse.machine === url;
        return {...machineUse, transporter: isTransporter};
      });
      dispatch(actions.update(task.url, [{member: "machineuseSet", value: newMachines}]));
      setTransportMachineDialogOpen(false);
    },
    [dispatch, task.machineuseSet, task.url],
  );

  const handleTransportMachineDialogCancel = useCallback((): void => {
    setTransportMachineDialogOpen(false);
  }, []);

  const addDialogsToState = useCallback(
    (newYieldLogDialogs: readonly React.JSX.Element[]): void => {
      setYieldLogDialogs(newYieldLogDialogs);
    },
    [],
  );

  const handlePhotoDisplay = useCallback((taskPhoto: TaskPhoto | UserPhoto): void => {
    setDisplayedImage(taskPhoto);
  }, []);

  const handleDisplayImageRequestClose = useCallback((): void => {
    window.setTimeout(() => {
      setDisplayedImage(null);
    }, 0);
  }, []);

  const handleRequestPhotoDelete = useCallback((taskPhotoURL: string): void => {
    setDeletingPhoto(taskPhotoURL);
  }, []);

  const handlePhotoDeleteDialogCancel = useCallback((): void => {
    setDeletingPhoto(null);
  }, []);

  const handlePhotoDeleteDialogOk = useCallback((): void => {
    if (deletingPhoto) {
      dispatch(actions.remove(deletingPhoto));
      setDeletingPhoto(null);
    }
  }, [deletingPhoto, dispatch]);

  const handleRequestFileDelete = useCallback((taskFileURL: string): void => {
    setDeletingFile(taskFileURL);
  }, []);

  const handleFileDeleteDialogCancel = useCallback((): void => {
    setDeletingFile(null);
  }, []);

  const handleFileDeleteDialogOk = useCallback((): void => {
    if (deletingFile) {
      dispatch(actions.remove(deletingFile));
      setDeletingFile(null);
    }
  }, [deletingFile, dispatch]);

  const addSprayDialogsToState = useCallback(
    (newSprayLogDialogs: readonly React.JSX.Element[]): void => {
      setSprayLogDialogs(newSprayLogDialogs);
    },
    [],
  );

  const handleAutoCorrectionOk = useCallback((): void => {
    if (!autoCorrectionDialog) {
      return;
    }
    const {fromTimestamp, timerURL, toTimestamp} = autoCorrectionDialog;
    setAutoCorrectionDialog(null);
    handleAddMachineOperatorTimeCorrection(fromTimestamp, toTimestamp, timerURL);
  }, [autoCorrectionDialog, handleAddMachineOperatorTimeCorrection]);

  const handleAutoCorrectionCancel = useCallback((): void => {
    setAutoCorrectionDialog(null);
  }, []);

  const handleCalledInDialogAnswer = useCallback(
    (calledIn: boolean): void => {
      const {url} = task;
      dispatch(actions.update(url, [{member: "calledIn", value: calledIn}]));
      setCalledInDialogOpen(false);
    },
    [dispatch, task],
  );

  const handleChangeMachineOperatorClick = useCallback((): void => {
    setMachineOperatorDialogOpen(true);
  }, []);

  const handleMachineOperatorDialogCancel = useCallback((): void => {
    setMachineOperatorDialogOpen(false);
  }, []);

  const handleMachineOperatorDialogOk = useCallback(
    (url: UserUrl): void => {
      setMachineOperatorDialogOpen(false);
      dispatch(actions.update(task.url, [{member: "machineOperator", value: url}]));
    },
    [dispatch, task.url],
  );

  const handlePriceGroupDialogCancel = useCallback((): void => {
    dispatch(actions.back());
  }, [dispatch]);

  const handleTabChange = useCallback(
    (_event: React.ChangeEvent<unknown>, value: string): void => {
      dispatch(actions.putQueryKey("tab", value));
    },
    [dispatch],
  );

  const {
    showC5AccountOnTaskInstanceAnList,
    showMachineOperatorInitialsOnTaskInstance,
    showMachineOperatorNameOnTaskInstance,
    showProjectNumberOnTaskInstance,
    showProjectOnTaskInstance,
    showWorkplaceOnTaskInstance,
    taskCultureSubheader,
  } = customerSettings;

  const logSpecification = task.reportingSpecification
    ? reportingSpecificationLookup(task.reportingSpecification)
    : null;

  let statusBarText;
  let statusBarIcon;
  const taskURL = task.url;
  const transportLog = taskURL ? transportLogArray.find((t) => t.task === taskURL) : null;
  const yieldLog = taskURL ? yieldLogArray.find((t) => t.task === taskURL) : null;

  const sprayLog = taskURL ? sprayLogArray.find((t) => t.task === taskURL) : null;

  const role = currentRole;
  const userIsManager = !!role && role.manager;
  const userIsOnlyMachineOperator = !!role && !role.manager;
  const userIsJobber = !!role && role.jobber;
  const userIsOther = !task || task.machineOperator !== currentUserURL;
  const userIsSeniorMachineOperator = !!role && role.seniorMachineOperator;
  const hasActivity = !!intervals.length;
  const allowSeniorToEdit = userIsSeniorMachineOperator && !hasActivity;
  const userIsOtherMachineOperator = userIsOther && userIsOnlyMachineOperator && !allowSeniorToEdit;
  const completed = !!(task ? task.completed : true);
  const reportApproved = !!(task ? task.reportApproved : true);
  const createdBy = task ? task.createdBy : null;
  const createdByProfile = createdBy ? userUserProfileLookup(createdBy) : null;
  const userInitials = createdByProfile ? createdByProfile.alias : null;
  const validated = !!(task ? task.validatedAndRecorded || task.reportApproved : false);

  const date = task ? task.date : null;
  const otherTasks = taskArray.filter((instance) => {
    // other tasks on same customer on same date
    if (instance.date !== date) {
      return false;
    }
    const instanceOrder = instance.order && orderLookup(instance.order);
    return instanceOrder && instanceOrder.customer === customerURL && !instanceOrder.draft;
  });
  const machineOperatorURL = task ? task.machineOperator : null;
  const machineOperator = machineOperatorURL && userLookup(machineOperatorURL);
  const machineOperatorProfile = machineOperatorURL && userUserProfileLookup(machineOperatorURL);
  const responsibleInitials = machineOperatorProfile ? machineOperatorProfile.alias : null;

  const otherMachineOperators = !cultureURL
    ? _.sortBy(
        Array.from(new Set(otherTasks.map((instance) => instance.machineOperator)))
          .filter(notNull)
          .filter((url) => url !== machineOperatorURL)
          .map(userLookup)
          .filter(notUndefined),
        (mo) => userUserProfileLookup(mo.url)?.alias || mo.loginIdentifier,
      ).map((user) => {
        const userURL = user.url;
        const userProfile = userUserProfileLookup(userURL);
        const phone = userProfile ? userProfile.cellphone || userProfile.phone : null;
        return (
          <div key={userURL}>
            {userProfile && userProfile.alias} ({userProfile && userProfile.name}){" "}
            {phone ? (
              <IconLinkButton href={`tel:${addDanishCountryPrefix(phone)}`} Icon={PhoneIcon} />
            ) : (
              <IconLinkButton Icon={PhoneIcon} />
            )}
          </div>
        );
      })
    : undefined;

  const machineList = ((task && task.machineuseSet) || [])
    .map((machineUse) => {
      const machineURL = machineUse.machine;
      return machineLookup(machineURL);
    })
    .filter(notUndefined);

  let workTypeString = "";
  let priceGroupString = null;
  const workTypeURL = task ? task.workType : null;
  const workTypeInstance = workTypeURL ? workTypeLookup(workTypeURL) : null;
  if (workTypeInstance) {
    workTypeString = getWorkTypeString(workTypeInstance);
    const priceGroupURL = task ? task.priceGroup : null;
    const priceGroup = priceGroupURL ? priceGroupLookup(priceGroupURL) : undefined;
    if (priceGroup) {
      priceGroupString = priceGroup.name;
    }
  } else {
    if (customerSettings.enableExternalTaskDepartmentField && task.department === "E") {
      workTypeString = intl.formatMessage({
        defaultMessage: "Entreprenørarbejde",
      });
    } else {
      let primaryMachine: Machine | undefined;
      if (machineList.length === 1) {
        primaryMachine = machineList[0];
      } else {
        primaryMachine = machineList.find((machine) => !machine.canPull);
        if (!primaryMachine) {
          primaryMachine = machineList.find((machine) => machine.selfPropelled);
        }
        if (!primaryMachine) {
          primaryMachine = machineList[0];
        }
      }
      if (primaryMachine) {
        const primaryMachineURL = primaryMachine.url;
        workTypeString = `${primaryMachine.c5_machine}: ${primaryMachine.name}`;
        ((task && task.machineuseSet) || []).forEach((machineUse) => {
          if (!machineUse) {
            return;
          }
          if (machineUse.machine === primaryMachineURL) {
            const priceGroupURL = machineUse.priceGroup || null;
            const priceGroup = priceGroupURL ? priceGroupLookup(priceGroupURL) : undefined;
            if (priceGroup) {
              priceGroupString = priceGroup.name;
            }
          }
        });
      }
    }
  }
  const hoursColonMinutesStringLength = 5;
  const time = task ? (task.time || "").substr(0, hoursColonMinutesStringLength) : null;
  const address = customer ? formatAddress(customer) : null;
  const orderURLX = task ? task.order : null;
  const orderInstance = orderURLX && orderLookup(orderURLX);
  let completedText: React.JSX.Element | null = null;
  if (completed) {
    completedText = (
      <div>
        <h2>Opgaven er markeret fuldført og kan ikke rettes</h2>
      </div>
    );
  }

  const canTakeTask =
    task &&
    !hasActivity &&
    task.machineOperator !== currentUserURL &&
    (!task.machineOperator || customerSettings.allowTakingPendingTasksFromOthers);

  const disableDelete =
    validated ||
    (userIsOnlyMachineOperator &&
      (userIsOther ||
        userIsJobber ||
        completed ||
        hasActivity ||
        (task.createdBy !== currentUserURL &&
          !customerSettings.machineOperatorCanDeleteAssignedTasks)));

  let computedStartTime;
  let computedEndTime;
  if (computedIntervals.length) {
    computedStartTime = computedIntervals[0].fromTimestamp;
    computedEndTime =
      computedIntervals[computedIntervals.length - 1].toTimestamp || now.toISOString();
  }
  let finalStartTime;
  let finalEndTime;
  if (intervals.length) {
    finalStartTime = intervals[0].fromTimestamp;
    finalEndTime = intervals[intervals.length - 1].toTimestamp || now.toISOString();
  }

  let primaryWorkType;
  if (workTypeURL) {
    primaryWorkType = workTypeLookup(workTypeURL);
  }

  const secondaryTimersList = getTaskSecondaryTimerList(task, customerSettings, {
    machineLookup,
    priceGroupLookup,
    timerArray,
    workTypeLookup,
  });
  const secondaryTimers = new Set(secondaryTimersList);

  const machineRemovalAllowed = !task.order || !!customerSettings.allowCustomerTaskMachineChange;

  const deletableMachineURLSet = machineRemovalAllowed
    ? new Set(machineList.map((machine) => machine.url))
    : new Set<string>();

  const intervalsWithTimers = intervals.map((interval) => {
    const intervalTimerURL = interval.timer;
    return {
      ...interval,
      timer: (intervalTimerURL && timerLookup(intervalTimerURL)) || null,
    };
  });
  const transportMachineMap = new Map<string, Machine>();
  if (task) {
    const priceItemUseSet = sortByOrderMember(Object.values(task.priceItemUses || {}));
    const machineuseSet = task.machineuseSet || [];
    machineuseSet.forEach((machineUse) => {
      const priceGroupURL = machineUse.priceGroup;
      const hasHoursLine = priceItemUseSet.some((priceItemUse) => {
        const priceItemURL = priceItemUse.priceItem;
        const priceItem = priceItemLookup(priceItemURL);
        if (
          priceItem &&
          (priceItem.priceGroup === priceGroupURL ||
            (priceGroupURL && priceItem.priceGroups?.includes(priceGroupURL))) &&
          (priceItem.itemtype === ITEM_TYPE_TIMER || priceItem.genericEffectiveTimerTarget)
        ) {
          const unitLower = getUnitCode(priceItem, unitLookup).toLowerCase();
          if (
            unitLower === "time" ||
            unitLower === "timer" ||
            unitLower === "tim" ||
            unitLower === "tim." ||
            unitLower === "time(r)"
          ) {
            return true;
          }
        }
        return false;
      });
      if (hasHoursLine) {
        const machineURL = machineUse.machine;
        const machine = machineLookup(machineURL);
        if (machine) {
          transportMachineMap.set(machineURL, machine);
        }
      }
    });
  }
  const transportMachineUse = task ? (task.machineuseSet || []).find((u) => u.transporter) : null;
  const transportMachine = transportMachineUse
    ? transportMachineMap.get(transportMachineUse.machine)
    : null;

  const orderTasks = taskArray.filter((t) => t.order === task.order);
  const sharedReportingSpecification = getSharedReportingSpecification(
    orderTasks,
    reportingSpecificationLookup,
  );

  let logTabHeader;
  if (
    (bowser.mobile || customerSettings.alwaysUseLogTab) &&
    (yieldLog || sprayLog || transportLog || logSpecification || sharedReportingSpecification)
  ) {
    logTabHeader = <Tab label="Log" value="log" />;
  }
  let transportLogCard;
  let sprayLogCard;
  let yieldLogCard;
  let logCard;
  if (
    ((bowser.mobile || customerSettings.alwaysUseLogTab) && currentTab === "log") ||
    (!bowser.mobile && currentTab === "time")
  ) {
    if (order) {
      if (logSpecification) {
        logCard = (
          <Grid item sm xs={12}>
            <LogCard
              logSpecification={logSpecification}
              onRequestBuildReports={handleRequestBuildReports}
              order={order}
              readonly={
                (completed && userIsOnlyMachineOperator) ||
                validated ||
                (userIsOther && userIsOnlyMachineOperator)
              }
              task={task}
              timerMinutesMap={timerMinutesMap}
            />
          </Grid>
        );
      } else if (sharedReportingSpecification) {
        logCard = (
          <Grid item sm xs={12}>
            <TransportTotalsTable
              otherTasks={orderTasks.filter((t) => t.url !== task.url)}
              showLoadCounts={sharedReportingSpecification.showLoadCounts}
              task={task}
            />
          </Grid>
        );
      }
    }
    if (transportLog) {
      transportLogCard = (
        <Grid item sm xs={12}>
          <TransportLogCard
            completed={completed}
            disabled={
              (completed && userIsOnlyMachineOperator) ||
              validated ||
              (userIsOther && userIsOnlyMachineOperator)
            }
            onEntryAdded={handleTransportLogEntryAdded}
            onRequestBuildReports={handleRequestBuildReports}
            onRequestDeleteEntry={handleRequestDeleteEntry}
            task={task}
            transportLog={transportLog}
            userIsOtherMachineOperator={userIsOtherMachineOperator}
            validated={validated}
            workTypeString={workTypeString}
          />
        </Grid>
      );
    }
    if (yieldLog) {
      yieldLogCard = (
        <Grid item sm xs={12}>
          <YieldLog
            addDialogs={addDialogsToState}
            completed={completed}
            disabled={
              (completed && userIsOnlyMachineOperator) ||
              validated ||
              (userIsOther && userIsOnlyMachineOperator)
            }
            machineOperator={machineOperator || undefined}
            onRequestBuildReports={handleRequestBuildReports}
            task={task}
            userIsOtherMachineOperator={userIsOtherMachineOperator}
            validated={validated}
            yieldLog={yieldLog}
          />
        </Grid>
      );
    }
    if (sprayLog) {
      sprayLogCard = (
        <Grid item sm xs={12}>
          <SprayLog
            addDialogs={addSprayDialogsToState}
            completed={completed}
            disabled={
              (completed && userIsOnlyMachineOperator) ||
              validated ||
              (userIsOther && userIsOnlyMachineOperator)
            }
            machineOperator={machineOperator || undefined}
            onRequestBuildReports={handleRequestBuildReports}
            sprayLog={sprayLog}
            task={task}
            userIsOnlyMachineOperator={userIsOnlyMachineOperator}
            userIsOtherMachineOperator={userIsOtherMachineOperator}
            validated={validated}
          />
        </Grid>
      );
    }
  }
  const workType =
    !customerSettings.noExternalTaskWorkType && task?.workType
      ? workTypeLookup(task.workType)
      : undefined;

  const hidePrimaryTimer = !!(
    workType &&
    customerSettings.hidePrimaryTimeButtonForExternalWorktypes.includes(workType.identifier)
  );

  const genericPrimaryTimerLabel = getTaskGenericPrimaryTimerLabel(
    task,
    workTypeLookup,
    priceGroupLookup,
    customerSettings,
  );
  let tabContent;
  if (currentTab === "time") {
    const timeCard = (
      <TimeCard
        activeTimer={activeTimer}
        completed={completed}
        customerSettings={customerSettings}
        finalEndTime={finalEndTime}
        finalStartTime={finalStartTime}
        genericPrimaryTimer={genericPrimaryTimer}
        genericPrimaryTimerLabel={genericPrimaryTimerLabel}
        hidePrimaryButton={hidePrimaryTimer}
        intervals={intervalsWithTimers.filter((x) => x.timer) as IntervalWithTimer[]}
        now={now.toISOString()}
        onRequestAddMachineOperatorTimeCorrection={
          completed || validated || userIsOther
            ? undefined
            : handleRequestAddMachineOperatorTimeCorrection
        }
        onRequestAddManagerTimeCorrection={
          validated || userIsOnlyMachineOperator ? undefined : handleRequestAddManagerTimeCorrection
        }
        onTimelineIntervalClick={
          editTimeCorrectionsAllowed({
            taskCompleted: completed,
            taskValidated: validated,
            userIsManager,
            userIsOther,
          })
            ? handleTimelineIntervalClick
            : undefined
        }
        onTimerButton={handleTimerButton}
        onTransportMachineSelectButton={handleTransportMachineSelectButton}
        secondaryTimers={secondaryTimers}
        task={task}
        timerMinutes={timerMinutesMap}
        transportMachine={transportMachine || undefined}
        update={boundUpdate}
        userIsOnlyMachineOperator={userIsOnlyMachineOperator}
        userIsOther={userIsOther}
        userIsOtherMachineOperator={userIsOtherMachineOperator}
        validated={validated}
      />
    );
    const machinesCard = !workTypeInstance?.disallowMachineUse ? (
      <MachinesCard
        allowMachineChange={customerSettings.allowCustomerTaskMachineChange}
        completed={completed}
        currentDepartment={task && task.department}
        customerSettings={customerSettings}
        deletableMachineURLSet={deletableMachineURLSet}
        enableTaskDepartmentField={customerSettings.enableExternalTaskDepartmentField}
        machineList={machineList}
        machineuseSet={(task && task.machineuseSet) || []}
        onDepartmentSelectButton={handleDepartmentSelectButton}
        onMachineSelectButton={handleMachineSelectButton}
        onRemoveMachine={handleRemoveMachine}
        priceGroupLookup={priceGroupLookup}
        userIsOnlyMachineOperator={userIsOnlyMachineOperator}
        userIsOtherMachineOperator={userIsOtherMachineOperator}
        validated={validated}
        workType={workTypeInstance || undefined}
      />
    ) : null;
    if (bowser.mobile || (!bowser.mobile && customerSettings.alwaysUseLogTab)) {
      tabContent = (
        <div>
          <Grid container>
            <Grid item xs={12}>
              {timeCard}
            </Grid>
          </Grid>
          {machinesCard}
        </div>
      );
    } else {
      tabContent = (
        <div>
          <Grid container>
            <Grid item sm xs={12}>
              {timeCard}
            </Grid>
            {transportLogCard}
            {yieldLogCard}
            {sprayLogCard}
            {logCard}
          </Grid>
          {machinesCard}
        </div>
      );
    }
  } else if (currentTab === "log") {
    tabContent = (
      <Grid>
        {transportLogCard}
        {yieldLogCard}
        {sprayLogCard}
        {logCard}
      </Grid>
    );
  } else if (currentTab === "info") {
    const workplaceURL = task ? task.relatedWorkplace : null;
    const workplace = workplaceURL ? locationLookup(workplaceURL) : undefined;
    const pickupLocationURL = task ? task.relatedPickupLocation : null;
    const pickupLocation = pickupLocationURL ? locationLookup(pickupLocationURL) : undefined;
    const contactInstance =
      orderInstance && orderInstance.contact ? contactLookup(orderInstance.contact) : null;
    const priceGroupURL = task ? task.priceGroup : null;
    const priceGroup = priceGroupURL ? priceGroupLookup(priceGroupURL) : undefined;
    let taskEffectiveTime = "";
    if (customerSettings.showTaskEffectiveTimeOnTaskInfoTab) {
      const finalSums = computeIntervalSums(intervals, now);
      const minutesTotal = mapSum(finalSums);
      const breakMinutes = (breakTimerURL && finalSums.get(breakTimerURL)) || 0;
      const minutesEffective = computeEffectiveMinutes(genericPrimaryTimer, finalSums);

      const paidMinutes = minutesTotal - breakMinutes;

      const {externalPrimaryMinutes} = computeRowData({
        customer: customer || undefined,
        intervals,
        locationLookup,
        machineLookup,
        now,
        priceGroupLookup,
        priceItemLookup,
        productLookup,
        projectArray,
        projectLookup,
        task,
        timerArray,
        timerLookup,
        workTypeLookup,
      });
      const percentMultiplier = 100;
      let efficiencyPercent;
      if (minutesEffective) {
        efficiencyPercent = paidMinutes
          ? Math.round((percentMultiplier * externalPrimaryMinutes) / paidMinutes)
          : 0;
      } else {
        // if minutesEffective is undefined, set percent to zero
        efficiencyPercent = 0;
      }
      taskEffectiveTime = `${efficiencyPercent}% (${formatDuration(
        customerSettings.durationFormat,
        externalPrimaryMinutes,
      )})`;
    }
    tabContent = (
      <InfoTabContent
        address={address ?? undefined}
        completed={completed}
        completedText={completedText || undefined}
        contact={contactInstance || undefined}
        culture={culture || undefined}
        customer={customer || undefined}
        date={date || undefined}
        hasActivity={hasActivity}
        onChangeCultureButton={handleChangeCultureButton}
        onChangeCustomerButton={handleChangeCustomerButton}
        onChangeMachineOperatorClick={handleChangeMachineOperatorClick}
        onGoToOrder={handleGoToOrder}
        onRequestNotesDialogClose={handleNotesDialogClose}
        order={order || undefined}
        otherMachineOperators={otherMachineOperators}
        pickupLocation={pickupLocation}
        priceGroup={priceGroup}
        responsibleInitials={responsibleInitials || undefined}
        shareToken={shareToken}
        task={task}
        taskEffectiveTime={taskEffectiveTime}
        time={time || undefined}
        userInitials={userInitials || undefined}
        userIsJobber={userIsJobber}
        userIsOnlyMachineOperator={userIsOnlyMachineOperator}
        userIsOtherMachineOperator={userIsOtherMachineOperator}
        userIsSeniorMachineOperator={userIsSeniorMachineOperator}
        validated={validated}
        workplace={workplace}
        workType={workTypeString}
      />
    );
  } else if (currentTab === "products") {
    const readonlyPriceItems =
      logSpecification && !task.logSkipped
        ? getReadonlyPriceItems(
            logSpecification,
            sortByOrderMember(Object.values(task.priceItemUses || {})),
            priceItemLookup,
            unitLookup,
          )
        : undefined;

    tabContent = (
      <ProductsTabContent
        completed={completed}
        currentRole={currentRole}
        currentUserURL={currentUserURL}
        customerSettings={customerSettings}
        hasActivity={hasActivity}
        machineArray={machineArray}
        onProductSelectButton={handleProductSelectButton}
        priceGroupLookup={priceGroupLookup}
        productDialogTimerMinutesMap={timerMinutesMap}
        readonlyPriceItems={readonlyPriceItems}
        reportingSpecification={logSpecification || undefined}
        task={task}
        userIsManager={userIsManager}
        userIsOtherMachineOperator={userIsOtherMachineOperator}
        validated={validated}
      />
    );
  } else if (currentTab === "smallMachines") {
    tabContent = (
      <SmallMachinesTabContent
        completed={completed}
        customerSettings={customerSettings}
        machineArray={machineArray}
        machineLookup={machineLookup}
        priceGroupLookup={priceGroupLookup}
        priceItemLookup={priceItemLookup}
        task={task}
        unitLookup={unitLookup}
        update={boundUpdate}
        userIsManager={userIsManager}
        userIsOtherMachineOperator={userIsOtherMachineOperator}
        validated={validated}
      />
    );
  } else if (currentTab === "photos") {
    tabContent = (
      <PhotosTab
        completed={completed}
        onPhotoDisplay={handlePhotoDisplay}
        onRequestFileDelete={handleRequestFileDelete}
        onRequestPhotoDelete={handleRequestPhotoDelete}
        task={task}
        userIsOnlyMachineOperator={userIsOnlyMachineOperator}
        userIsOtherMachineOperator={userIsOtherMachineOperator}
        validated={validated}
      />
    );
  } else if (currentTab === "invoicing" && !userIsOnlyMachineOperator) {
    tabContent = <InvoicingTab task={task} />;
  } else if (currentTab === "geolocation" && !userIsOnlyMachineOperator) {
    tabContent = <GeolocationTab taskURL={task.url} />;
  }
  let workTypeLackingPriceGroup;
  let machineLackingPriceGroup;
  if (!task.recordedInC5) {
    if (
      task.workType &&
      !task.priceGroup &&
      workTypePotentialPriceGroups(task.workType, customerURL, workTypeLookup, priceGroupLookup)
        .length > 1
    ) {
      workTypeLackingPriceGroup = task.workType;
    } else if (task.machineuseSet && task.machineuseSet.length) {
      const machineUseLackingPriceGroup = task.machineuseSet.find(
        (machineUse) =>
          !machineUse.priceGroup &&
          machinePotentialPriceGroups(
            machineUse.machine,
            customerURL,
            machineLookup,
            priceGroupLookup,
          ).length > 1,
      );
      if (machineUseLackingPriceGroup) {
        machineLackingPriceGroup = machineUseLackingPriceGroup.machine;
      }
    }
  }

  const genericPrimaryTimerURL = genericPrimaryTimer.url;
  const hasEffectiveTime = !!timerMinutesMap.get(genericPrimaryTimerURL);
  const hasInvoicedTime = hasSecondaryInvoicedTime(
    secondaryTimers,
    timerMinutesMap,
    workTypeLookup,
    customerSettings.timersContributingToEffectiveTime,
    priceItemLookup,
    sortByOrderMember(Object.values(task.priceItemUses || {})),
  );

  let header;
  if (!customerSettings.noExternalTaskWorkType) {
    const workTypeChangeDisabled =
      validated || (completed && userIsOnlyMachineOperator) || userIsOtherMachineOperator;
    const workTypeChangeBlockedReason = workTypeChangeBlocked(
      task,
      timerMinutesMap,
      customerSettings,
      {
        machineLookup,
        priceGroupLookup,
        reportingSpecificationArray,
        reportingSpecificationLookup,
        timerArray,
        workTypeLookup,
      },
    );

    const workTypeChangeButton =
      customerSettings.enableExternalTaskWorkTypeAndVariantSwitch && !userIsJobber ? (
        <ChangeWorkTypeButton
          blockedReason={workTypeChangeBlockedReason}
          disabled={workTypeChangeDisabled}
          onWorkTypeSelectButton={handleWorkTypeSelectButton}
        />
      ) : null;
    if (!workTypeURL) {
      header = (
        <h3>
          <FormattedMessage defaultMessage="Arbejdsområde ikke valgt" />
          {workTypeChangeButton}
        </h3>
      );
    } else if (priceGroupString) {
      header = (
        <h3>
          {workTypeString}
          &nbsp;(
          {priceGroupString}){workTypeChangeButton}
        </h3>
      );
    } else {
      header = (
        <h3>
          {workTypeString}
          {workTypeChangeButton}
        </h3>
      );
    }
  } else if (workTypeString) {
    header = <h3>{workTypeString}</h3>;
  }
  const customerName = customer ? customer.name : "";
  const customerC5Account =
    customer && showC5AccountOnTaskInstanceAnList ? ` - ${customer.c5_account}` : "";

  const cultureName = culture ? culture.name : "";
  let machineOperatorString = "";
  if (machineOperatorProfile) {
    const {alias, name} = machineOperatorProfile;
    if (
      showMachineOperatorNameOnTaskInstance &&
      showMachineOperatorInitialsOnTaskInstance &&
      name &&
      alias
    ) {
      machineOperatorString = ` - ${name} (${alias})`;
    } else if (showMachineOperatorNameOnTaskInstance && name) {
      machineOperatorString = ` - ${name}`;
    } else if (showMachineOperatorInitialsOnTaskInstance && alias) {
      machineOperatorString = ` - ${alias}`;
    }
  }

  const workplaceURL = task ? task.relatedWorkplace : null;
  const workplace = workplaceURL ? locationLookup(workplaceURL) : undefined;

  const workplaceString =
    showWorkplaceOnTaskInstance && !customerSettings.taskPickupDelivery
      ? workplace
        ? ` - ${workplace.name || formatAddress(workplace)}`
        : ` - ${task.address}`
      : "";

  const machineuseSet =
    customerSettings.noExternalTaskWorkType && task ? task.machineuseSet : undefined;
  const machines = machineuseSet
    ? machineuseSet.map((machineUse) => machineLookup(machineUse.machine)).filter(notUndefined)
    : undefined;
  const projectURL = task.project;
  const {enableExtraWorkButton} = customerSettings;
  const project = projectURL && projectLookup(projectURL);
  let extraWorkDialog;
  if (enableExtraWorkButton) {
    let extraWorkDefaultNote = "";

    if (enableExtraWorkButton === "PROJECT" && projectURL) {
      extraWorkDefaultNote = intl.formatMessage(
        {defaultMessage: "Ekstra arbejde for {project}"},
        {project: project ? project.projectNumber : ""},
      );
    } else if (enableExtraWorkButton === "REF" && customerSettings.enableOrderReferenceNumber) {
      extraWorkDefaultNote = intl.formatMessage(
        {defaultMessage: "Ekstra arbejde for {project}"},
        {project: order ? order.referenceNumber : ""},
      );
    } else if (enableExtraWorkButton === "REF" && customerSettings.enableTaskReferenceNumber) {
      extraWorkDefaultNote = intl.formatMessage(
        {defaultMessage: "Ekstra arbejde for {project}"},
        {project: task.referenceNumber},
      );
    }
    extraWorkDialog = (
      <SingleTextFieldDialog
        label={intl.formatMessage({defaultMessage: "Note"})}
        onCancel={handleEnterExtraWorkNoteDialogCancel}
        onOk={handleEnterExtraWorkNoteDialogOk}
        open={enterExtraWorkNoteDialogOpen}
        title={intl.formatMessage({defaultMessage: "Ekstra arbejde note"})}
        value={extraWorkDefaultNote}
      />
    );
  }
  let projectString = "";
  if (project) {
    if (showProjectOnTaskInstance && showProjectNumberOnTaskInstance) {
      projectString = ` - ${project.projectNumber}: ${project.name}`;
    } else if (showProjectOnTaskInstance) {
      projectString = ` - ${project.name}`;
    } else if (showProjectNumberOnTaskInstance) {
      projectString = ` - ${project.projectNumber}`;
    }
  }
  let canRegisterAccommodation = false;
  let accommodationAllowanceRegistratedOnTaskDate = false;
  let accommodationRemunerationGroup = "";
  if (completedDialogOpen && finalStartTime) {
    if (machineOperatorProfile) {
      accommodationRemunerationGroup = getEmployeeTaskRemunerationGroup(
        customerSettings,
        machineOperatorProfile,
        task,
        workTypeLookup,
      );
      canRegisterAccommodation =
        !!customerSettings.remunerationGroups[accommodationRemunerationGroup]
          ?.accomodationAllowance;
      const finalStartDate = dateToString(new Date(finalStartTime));
      accommodationAllowanceRegistratedOnTaskDate =
        canRegisterAccommodation &&
        accomodationAllowanceArray.some(
          (accommodationAllowance) =>
            accommodationAllowance.employee === machineOperatorURL &&
            accommodationAllowance.date === finalStartDate &&
            accommodationAllowance.remunerationGroup === accommodationRemunerationGroup,
        );
    }
  }
  const hasProjectString =
    (enableExtraWorkButton && enableExtraWorkButton === "PROJECT" && !!projectURL) ||
    (enableExtraWorkButton === "REF" &&
      customerSettings.enableOrderReferenceNumber &&
      order &&
      order.referenceNumber) ||
    (enableExtraWorkButton === "REF" &&
      customerSettings.enableTaskReferenceNumber &&
      task.referenceNumber);

  const priceGroups = Array.from(
    getRelevantPriceGroupSet(task, {
      timerLookup,
      timerMinutesMap,
    }),
  )
    .map(priceGroupLookup)
    .filter(notUndefined);

  let taskIssues = null;
  if (userIsManager) {
    if (customerSettings.economicSync) {
      taskIssues = <EconomicTaskIssuesList context="task" task={task} />;
    } else if (task.lastTransferError && !task.recordedInC5) {
      taskIssues = (
        <h4 style={{color: theme.palette.error.main}}>
          <FormattedMessage
            defaultMessage="Fejl under overførsel: {error}. Ret fejlen og overfør igen."
            values={{error: task.lastTransferError}}
          />
        </h4>
      );
    }
  }
  return (
    <PageLayout
      tabs={
        <Tabs
          onChange={handleTabChange}
          scrollButtons="auto"
          value={currentTab}
          variant="scrollable"
        >
          <Tab label={intl.formatMessage({defaultMessage: "Tid"})} value="time" />
          {logTabHeader}
          <Tab label={intl.formatMessage({defaultMessage: "Info"})} value="info" />
          <Tab
            label={
              bowser.mobile
                ? intl.formatMessage({defaultMessage: "Mat."})
                : customerSettings.materialUseAlternativeText
                  ? intl.formatMessage({defaultMessage: "Materialer"})
                  : intl.formatMessage({defaultMessage: "Materiel"})
            }
            value="products"
          />
          {customerSettings.enableSmallMachines &&
          workType?.enableSmallMachines !== false &&
          machineArray.some((machine) => machine.active && machine.smallMachine) ? (
            <Tab
              label={
                bowser.mobile
                  ? intl.formatMessage({defaultMessage: "S.mask."})
                  : intl.formatMessage({defaultMessage: "Småmaskiner"})
              }
              value="smallMachines"
            />
          ) : null}
          <Tab label={intl.formatMessage({defaultMessage: "Foto"})} value="photos" />
          {customerSettings.enableInvoiceCorrections && !userIsOnlyMachineOperator ? (
            <Tab
              label={
                bowser.mobile
                  ? intl.formatMessage({defaultMessage: "Fak."})
                  : intl.formatMessage({defaultMessage: "Fakturering"})
              }
              value="invoicing"
            />
          ) : null}
          {customerSettings.enableGPSList && !userIsOnlyMachineOperator ? (
            <Tab label={intl.formatMessage({defaultMessage: "GPS"})} value="geolocation" />
          ) : null}
        </Tabs>
      }
      toolbar={intl.formatMessage({defaultMessage: "Opgave"})}
    >
      {!bowser.mobile || (bowser.mobile && currentTab !== "products") ? (
        <div style={{margin: "1em 1em 0"}}>
          {header}
          <h4>
            {customerName +
              customerC5Account +
              machineOperatorString +
              workplaceString +
              projectString}
          </h4>
          {taskCultureSubheader && cultureName ? <h4>{cultureName}</h4> : null}
          {customerSettings.taskPickupDelivery ? (
            <WorkplaceLine
              locationLookup={locationLookup}
              workplaceURL={task.relatedWorkplace || undefined}
            />
          ) : null}
          {task && task.cancelled && task.completed ? (
            <h4 style={{color: colorMap.ERROR}}>
              <FormattedMessage defaultMessage="Opgaven er lukket som aflyst" />
            </h4>
          ) : null}
          {task && task.completedAsInternal && task.completed ? (
            <h4 style={{color: colorMap.ERROR}}>
              <FormattedMessage defaultMessage="Opgaven er afleveret som intern" />
            </h4>
          ) : null}
          {taskIssues}
        </div>
      ) : (
        <div style={{margin: "1em 1em 0"}}>
          <span style={{display: "inline-block"}} />
        </div>
      )}
      {tabContent}
      <div style={{padding: "1em"}}>
        <ActionButtons
          canTakeTask={canTakeTask}
          completed={completed}
          customerSettings={customerSettings}
          disableDelete={disableDelete}
          hasEffectiveTime={hasEffectiveTime}
          hasInvoicedTime={hasInvoicedTime}
          hasProject={!!hasProjectString}
          includeCancelled={customerSettings.includeTaskCancel}
          includeCopy={customerSettings.includeTaskCopy}
          onCancelledButton={handleCancelledButton}
          onCompletedAsInternalButton={handleCompletedAsInternalButton}
          onCompletedButton={handleCompletedButton}
          onContinueToNextCustomerButton={handleContinueToNextCustomerButton}
          onCopyButton={handleCopyButton}
          onDeleteButton={handleDeleteButton}
          onExtraWorkButton={handleExtraWorkButton}
          onIncompleteButton={handleIncompleteButton}
          onSaveButton={handleSaveButton}
          onTakeButton={handleTakeButton}
          onValidatedButton={handleValidatedButton}
          reportApproved={reportApproved}
          userIsOnlyMachineOperator={userIsOnlyMachineOperator}
          userIsOtherMachineOperator={userIsOtherMachineOperator}
          validated={validated}
        />
      </div>
      <MachinePriceGroupWizard
        onCancel={handleMachineDialogCancel}
        onOk={handleMachineDialogOk}
        open={machineDialogOpen}
        task={task}
      />
      <MachineDialog
        machineArray={Array.from(transportMachineMap.values())}
        machineLabelVariant={customerSettings.machineLabelVariant}
        onCancel={handleTransportMachineDialogCancel}
        onOk={handleTransportMachineDialogOk}
        open={transportMachineDialogOpen}
      />
      {customerSettings.productImageSelection ? (
        <ProductGroupTreeDialog
          onCancel={handleProductDialogCancel}
          onOk={handleProductDialogOk}
          open={productDialogOpen}
        />
      ) : (
        <ConnectedProductDialog
          machines={machines}
          onCancel={handleProductDialogCancel}
          onOk={handleProductDialogOk}
          open={productDialogOpen}
          preferredCategory="recentlyUsed"
          preferredProductURLs={
            customerSettings.enableRecentlyUsedProducts
              ? readProductUseLog(productUseLogArray, productLookup, task)
              : undefined
          }
          priceGroups={priceGroups}
          workType={workType}
        />
      )}
      <MissingProductDialog
        onCancel={handleMissingProductDialogCancel}
        onOk={handleMissingProductDialogOk}
        open={missingProductDialogOpen}
      />
      {customerSettings.askRegardingMissingBreakOnExternalTaskCompletion ? (
        <MissingBreakDialog
          onCancel={handleMissingBreakDialogCancel}
          onOk={handleMissingBreakDialogOk}
          open={missingBreakDialogOpen}
        />
      ) : null}
      <CompletedDialog
        accommodationAllowanceRegistratedOnTaskDate={accommodationAllowanceRegistratedOnTaskDate}
        accommodationRemunerationGroup={accommodationRemunerationGroup}
        cancelled={completedDialogCancelled}
        canRegisterAccommodation={canRegisterAccommodation}
        completedAsInternal={completedDialogCompletedAsInternal}
        continuation={completedDialogContinuation}
        customerSettings={customerSettings}
        finalIntervals={intervalsWithTimers}
        finalStartTime={finalStartTime}
        onAction={handleCompletedValidatedDialogAction}
        onCancel={handleCompletedDialogCancel}
        onOk={handleCompletedDialogOk}
        open={completedDialogOpen}
        secondaryTimers={secondaryTimers}
        task={task}
      />
      <CustomerSelectCreateDialog
        onCancel={handleNextCustomerDialogCancel}
        onOk={handleNextCustomerDialogOk}
        open={nextCustomerDialogOpen && !completedDialogOpen && task.completed}
      />
      <ConnectedCultureDialog
        onCancel={handleCultureDialogCancel}
        onOk={handleCultureDialogOk}
        open={cultureDialogOpen}
      />
      <WorkTypeSelectionWrapper
        customerURL={customerURL}
        onWorkTypeSelection={handleWorkTypePriceGroupSelection}
        ref={workTypeSelectionControl}
        task={task}
      />
      <ValidatedDialog
        cancelled={task && task.cancelled}
        computedEndTime={computedEndTime}
        computedIntervals={computedIntervals}
        computedStartTime={computedStartTime}
        continuation={completedDialogContinuation}
        customerSettings={customerSettings}
        finalEndTime={finalEndTime}
        finalIntervals={intervalsWithTimers}
        finalStartTime={finalStartTime}
        genericPrimaryTimer={genericPrimaryTimer}
        onAction={handleCompletedValidatedDialogAction}
        onCancel={handleValidatedDialogCancel}
        onOk={handleValidatedDialogOk}
        open={validatedDialogOpen && !customerApproveDialogOpen}
        primaryWorkType={primaryWorkType}
        secondaryTimers={secondaryTimers}
        task={task}
        transportLog={transportLog || undefined}
      />
      <ValidatedDialog
        cancelled={task && task.cancelled}
        computedEndTime={computedEndTime}
        computedIntervals={computedIntervals}
        computedStartTime={computedStartTime}
        continuation={completedDialogContinuation}
        customerSettings={customerSettings}
        finalEndTime={finalEndTime}
        finalIntervals={intervalsWithTimers}
        finalStartTime={finalStartTime}
        genericPrimaryTimer={genericPrimaryTimer}
        onAction={handleCompletedValidatedDialogAction}
        onCancel={handleApprovedDialogCancel}
        onOk={handleApprovedDialogOk}
        open={approvedDialogOpen}
        primaryWorkType={primaryWorkType}
        secondaryTimers={secondaryTimers}
        task={task}
        transportLog={transportLog || undefined}
      />
      <PriceGroupDialog
        customerURL={customer?.url}
        machineURL={machineLackingPriceGroup}
        onCancel={handlePriceGroupDialogCancel}
        onOk={handlePriceGroupDialogOk}
        open={!!machineLackingPriceGroup}
      />
      <ConnectedLogLegalPriceGroupDialog
        customerUrl={customer?.url || null}
        onCancel={handlePriceGroupDialogCancel}
        onOk={handlePriceGroupDialogOk}
        open={!!workTypeLackingPriceGroup}
        task={task}
        workTypeURL={workTypeLackingPriceGroup || undefined}
      />
      {customerSettings.customers.liveSyncWithThirdParty ? (
        <CustomerApproveOrChangeDialog
          allowChangeCurrentTaskOnly
          onCancel={setCustomerApproveDialogOpenFalse}
          onSuccess={setCustomerApproveDialogOpenFalse}
          open={customerApproveDialogOpen}
          task={task}
        />
      ) : null}
      <TaskWarningDialogs
        oldTaskTimerStart={!!oldTaskTimerStart}
        oldTimerTimerStart={!!oldTimerTimerStart}
        onActiveRouteWarningDialogCancel={handleStopTimerDialogCancel}
        onOldTaskDialogCancel={handleOldTaskOpenCancel}
        onOldTaskDialogOk={handleOldTaskOpenOk}
        onOtherDateDialogCancel={handleOtherDateDialogCancel}
        onOtherDateDialogOk={handleOtherDateDialogOk}
        onStopTimerDialogCancel={handleStopTimerDialogCancel}
        onStopTimerDialogOk={handleStopTimerDialogOk}
        requestedActiveTimer={!!requestedActiveTimer}
        task={task}
      />

      <CalledInDialog onAnswer={handleCalledInDialogAnswer} open={calledInDialogOpen} />
      <NotesDialog
        dismissed={currentUserProfile?.showNotePopupOnTask === false || notesDialogDismissed}
        onRequestClose={handleNotesDialogClose}
        order={order || undefined}
        task={task}
      />
      <CopiedDialog
        customer={customer || undefined}
        machineList={machineList}
        onRequestClose={handleCopiedDialogClose}
        open={copiedDialogOpen && !notificationDialogOpen}
        workType={workTypeInstance || undefined}
      />
      {userIsOnlyMachineOperator ? (
        <DeleteDialog
          onCancel={handleDeleteDialogCancel}
          onOk={handleDeleteDialogOk}
          open={deleteDialogOpen}
        >
          <FormattedMessage defaultMessage="SLET?" />
        </DeleteDialog>
      ) : (
        <TaskDeleteDialog
          breakTimer={breakTimer}
          computedIntervals={computedIntervals}
          customer={customer || undefined}
          genericPrimaryTimer={genericPrimaryTimer}
          intervalsWithTimers={intervalsWithTimers}
          machineOperatorProfile={machineOperatorProfile || undefined}
          onCancel={handleDeleteDialogCancel}
          onOk={handleDeleteDialogOk}
          open={deleteDialogOpen}
          project={project || undefined}
          secondaryTimers={secondaryTimers}
          task={task}
          workType={workType}
        />
      )}
      <EditTimeCorrectionDialog
        correctionDisabled={
          validated || userIsOtherMachineOperator || (completed && userIsOnlyMachineOperator)
        }
        fromTimestamp={editIntervalStart}
        genericPrimaryTimer={genericPrimaryTimer}
        genericPrimaryTimerLabel={genericPrimaryTimerLabel}
        hidePrimaryTimer={hidePrimaryTimer}
        legalIntervals={
          customerSettings.usePunchInOut && task.machineOperator
            ? punchInOutPeriodsPerEmployee.get(task.machineOperator)
            : undefined
        }
        onCancel={handleTimelineEditIntervalDialogCancel}
        onOk={handleTimelineEditIntervalDialogOk}
        open={!!(editIntervalStart && editIntervalEnd)}
        secondaryTimers={secondaryTimers}
        timer={editIntervalTimer}
        toTimestamp={editIntervalEnd}
      />
      <AddTimeCorrectionDialog
        defaultDate={task.date}
        genericPrimaryTimer={genericPrimaryTimer}
        genericPrimaryTimerLabel={genericPrimaryTimerLabel}
        hidePrimaryTimer={hidePrimaryTimer}
        legalIntervals={
          customerSettings.usePunchInOut && task.machineOperator
            ? punchInOutPeriodsPerEmployee.get(task.machineOperator)
            : undefined
        }
        onCancel={handleCorrectionDialogCancel}
        onOk={handleCorrectionDialogOk}
        open={managerTimeCorrectionDialogOpen || machineOperatorTimeCorrectionDialogOpen}
        secondaryTimers={secondaryTimers}
      />
      <DeleteDialog
        onCancel={handleDeleteEntryCancel}
        onOk={handleDeleteEntryOk}
        open={!!deleteEntryRequest}
      >
        <FormattedMessage defaultMessage="Slet postering?" />
      </DeleteDialog>
      <LocationSelectCreateDialog
        customerURL={workplaceDialogCustomerURL}
        includeLogOnlyLocations={false}
        logOnlyLocation={false}
        machineOperator={task.machineOperator}
        onCancel={handleWorkplaceDialogCancel}
        onNone={!customerSettings.requireWorkplaceIfExists ? handleWorkplaceDialogNone : undefined}
        onOk={handleWorkplaceDialogOk}
        open={!!workplaceDialogCallback && !removeFieldsDialogOpen}
        titleVariant="WORKPLACE"
      />
      <CustomerSelectCreateDialog
        onCancel={handleChangeCustomerCancel}
        onOk={handleChangeCustomerOk}
        open={customerDialogOpen}
      />
      <RemoveFieldsDialog
        onNo={handleRemoveFieldsDialogCancel}
        onYes={handleRemoveFieldsDialogOk}
        open={removeFieldsDialogOpen}
      />
      <ConnectedDepartmentDialog
        onCancel={handleDepartmentDialogCancel}
        onOk={handleDepartmentDialogOk}
        open={departmentDialogOpen}
      />

      {yieldLogDialogs}
      {sprayLogDialogs}
      <PhotoDisplayDialog
        instance={displayedImage || undefined}
        key="photo-display-dialog"
        onRequestClose={handleDisplayImageRequestClose}
      />
      <AutoCorrectionDialog
        fromTimestamp={autoCorrectionDialog?.fromTimestamp}
        onCancel={handleAutoCorrectionCancel}
        onOk={handleAutoCorrectionOk}
        open={!!autoCorrectionDialog}
        toTimestamp={autoCorrectionDialog?.toTimestamp}
      />
      {extraWorkDialog}
      {customerSettings.allowMachineOperatorChangeOnTaskInfoPage ? (
        <ConnectedMachineOperatorDialog
          key="machine-operator-dialog"
          onCancel={handleMachineOperatorDialogCancel}
          onOk={handleMachineOperatorDialogOk}
          open={machineOperatorDialogOpen}
        />
      ) : null}
      <StatusBar status={statusBarIcon} text={statusBarText} />
      <DeleteDialog
        key="delete-photo-dialog"
        onCancel={handlePhotoDeleteDialogCancel}
        onOk={handlePhotoDeleteDialogOk}
        open={!!deletingPhoto}
      >
        <FormattedMessage defaultMessage="Slet foto?" />
      </DeleteDialog>
      <DeleteDialog
        key="delete-file-dialog"
        onCancel={handleFileDeleteDialogCancel}
        onOk={handleFileDeleteDialogOk}
        open={!!deletingFile}
      >
        <FormattedMessage defaultMessage="Slet fil?" />
      </DeleteDialog>
      <MachineRemovalBlockedDialog
        blockedReason={machineRemovalBlockedReason}
        key="machine-removal-blocked-dialog"
        onClose={handleMachineRemovalBlockedDialogClose}
        open={machineRemovalBlockedDialogOpen}
      />
    </PageLayout>
  );
}
