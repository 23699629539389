import {Config} from "@co-common-libs/config";
import {Role} from "@co-common-libs/resources";
import {notUndefined} from "@co-common-libs/utils";
import {
  actions,
  getCurrentRole,
  getCurrentUserProfile,
  getCustomerSettings,
  getPathName,
  getSettingsArray,
  getUserId,
  PathTemplate,
} from "@co-frontend-libs/redux";
import {PathParameters} from "@co-frontend-libs/routing-sync-history";
import {jsonFetch} from "@co-frontend-libs/utils";
import {Divider, List, ListItem, ListItemIcon, ListItemText, SvgIcon} from "@material-ui/core";
import {computePersistedQueries} from "app-utils";
import bowser from "bowser";
import {globalConfig, resourceURL} from "frontend-global-config";
import AccountMultipleIcon from "mdi-react/AccountMultipleIcon";
import CalendarIcon from "mdi-react/CalendarIcon";
import ChartArcIcon from "mdi-react/ChartArcIcon";
import ClipboardTextIcon from "mdi-react/ClipboardTextIcon";
import CloudSyncIcon from "mdi-react/CloudSyncIcon";
import CornIcon from "mdi-react/CornIcon";
import DomainIcon from "mdi-react/DomainIcon";
import EngineIcon from "mdi-react/EngineIcon";
import FileChartIcon from "mdi-react/FileChartIcon";
import FileDocumentBoxMultipleIcon from "mdi-react/FileDocumentBoxMultipleIcon";
import FileDocumentIcon from "mdi-react/FileDocumentIcon";
import FolderMultipleIcon from "mdi-react/FolderMultipleIcon";
import ProjectIcon from "mdi-react/FolderTextIcon";
import FoodVariantIcon from "mdi-react/FoodVariantIcon";
import HelpCircleOutlineIcon from "mdi-react/HelpCircleOutlineIcon";
import MapIcon from "mdi-react/MapIcon";
import NewspaperIcon from "mdi-react/NewspaperIcon";
import SettingsIcon from "mdi-react/SettingsIcon";
import TagMultipleIcon from "mdi-react/TagMultipleIcon";
import TagTextOutlineIcon from "mdi-react/TagTextOutlineIcon";
import TimetableIcon from "mdi-react/TimetableIcon";
import WarehouseIcon from "mdi-react/WarehouseIcon";
import React, {useCallback, useMemo, useState} from "react";
import {defineMessages, FormattedMessage, IntlShape, useIntl} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {ClearAppDialog} from "../../clear-app-dialog";
import {FullscreenLinksDialog} from "../../fullscreen-links-dialog";
import {NavListItem} from "../nav-list-item";
import {UserDropdownMenu} from "./user-dropdown-menu";

const messages = defineMessages({
  appendices: {
    defaultMessage: "Bilag",
    id: "menu.label.appendices",
  },
  bookkeeping: {
    defaultMessage: "Dagsedler",
    id: "menu.label.bookkeeping",
  },
  brugerdataSync: {
    defaultMessage: "Brugerdata",
    id: "menu.label.brugerdata-sync",
  },
  culture: {
    defaultMessage: "Kulturer",
    id: "menu.culture",
  },
  customers: {
    defaultMessage: "Kunder",
  },
  dinnerBookings: {
    defaultMessage: "Madbestillinger",
    id: "menu.label.dinner-bookings",
  },
  documents: {
    defaultMessage: "Dokumenter",
    id: "menu.documents",
  },
  economicSync: {
    defaultMessage: "e-conomic",
    id: "menu.label.economic-sync",
  },
  employees: {
    defaultMessage: "Medarbejdere",
    id: "menu.label.users",
  },
  fetchPunchInOut: {
    defaultMessage: "Hent ind/udstempling",
    id: "menu.label.fetch-punch-in-out",
  },
  fullscreenLinks: {
    defaultMessage: "Fullscreen links",
    id: "menu.label.fullscreen-links",
  },
  geolocationMap: {
    defaultMessage: "Kort",
    id: "menu.geolocation-map",
  },
  help: {
    defaultMessage: "Hjælp",
    id: "menu.help",
  },
  importAndExport: {
    defaultMessage: "Import og Eksport",
    id: "menu.importAndExport",
  },
  information: {
    defaultMessage: "Information",
    id: "menu.information",
  },
  locationStorage: {
    defaultMessage: "Lager",
    id: "menu.location-storage",
  },
  navSync: {
    defaultMessage: "NAV",
    id: "menu.label.nav-sync",
  },
  order: {
    defaultMessage: "Ordre",
    id: "menu.order",
  },
  projects: {
    defaultMessage: "Projekter",
    id: "menu.projects",
  },
  remunerationReports: {
    defaultMessage: "Lønrapporter",
  },
  reports: {
    defaultMessage: "Rapporter",
    id: "menu.label.reports",
  },
  salaryVoucher: {
    defaultMessage: "Mine lønbilag",
    id: "menu.label.salary-voucher",
  },
  settings: {
    defaultMessage: "Indstillinger",
    id: "menu.settings",
  },
  synchronize: {
    defaultMessage: "Synkronisering",
    id: "menu.synchronize",
  },
  taskCalendar: {
    defaultMessage: "Kalender",
    id: "menu.calendar",
  },
  tasks: {
    defaultMessage: "Opgaver",
    id: "menu.tasks",
  },
  tasksPrintout: {
    defaultMessage: "Opgaveudskrifter",
    id: "menu.label.tasks-printout",
  },
  timeOverview: {
    defaultMessage: "Min dagseddel",
    id: "menu.label.time-overview",
  },
  workingTime: {
    defaultMessage: "Min arbejdstid",
    id: "menu.label.working-time",
  },
  workshop: {
    defaultMessage: "Værksted",
    id: "menu.workshop",
  },
  workTypeStatus: {
    defaultMessage: "Områdestatus",
    id: "menu.workTypeStatus",
  },
});

// non-falsy sentinel value
const DIVIDER = "DIVIDER" as const;

function computeMenuContent(
  formatMessage: IntlShape["formatMessage"],
  role: Role | null,
  unreadInformationCount: number,
  customerSettings: Config,
): (
  | "DIVIDER"
  | {
      badgeCount?: number;
      Icon: React.ComponentType<object>;
      route: PathTemplate;
      text: string;
    }
)[] {
  const manager = role && role.manager;
  const jobber = role && role.jobber;
  const projectManager = role && role.projectManager;
  const seniorMachineOperator = role && role.seniorMachineOperator;
  const canAccessFoodBookingPage =
    (role && role.canReadFoodBookings) ||
    (customerSettings.allowSeniorMachineOperatorsToManageFoodBookings && seniorMachineOperator);

  const calendarEntry = {
    Icon: CalendarIcon,
    route: "/taskCalendar",
    text: formatMessage(messages.taskCalendar),
  } as const;
  const geolocationMapEntry = {
    Icon: MapIcon,
    route: "/geolocationMap",
    text: formatMessage(messages.geolocationMap),
  } as const;
  const orderEntry = {
    Icon: TagTextOutlineIcon,
    route: "/order",
    text: formatMessage(messages.order),
  } as const;
  const remunerationReportsEntry = {
    Icon: FileDocumentIcon,
    route: "/remunerationReport",
    text: formatMessage(messages.remunerationReports),
  } as const;
  const bookkeepingEntry = {
    Icon: ClipboardTextIcon,
    route: "/bookkeeping",
    text: formatMessage(messages.bookkeeping),
  } as const;
  const customersEntry = {
    Icon: DomainIcon,
    route: "/customer",
    text: formatMessage(messages.customers),
  } as const;
  const locationStorageEntry = {
    Icon: WarehouseIcon,
    route: "/locationStorage",
    text: formatMessage(messages.locationStorage),
  } as const;
  const tasksEntry = {
    Icon: TagMultipleIcon,
    route: "/task",
    text: formatMessage(messages.tasks),
  } as const;
  const timeOverviewEntry = {
    Icon: TimetableIcon,
    route: "/timeOverview",
    text: formatMessage(messages.timeOverview),
  } as const;
  const workshopEntry = {
    Icon: EngineIcon,
    route: "/workshop",
    text: formatMessage(messages.workshop),
  } as const;
  const employeesEntry = {
    Icon: AccountMultipleIcon,
    route: "/user",
    text: formatMessage(messages.employees),
  } as const;
  const salaryVouchersEntry = {
    Icon: FileDocumentIcon,
    route: "/salaryVoucher",
    text: formatMessage(messages.salaryVoucher),
  } as const;
  const computedSalaryVouchersEntry = {
    Icon: FileDocumentIcon,
    route: "/computeSalaryVoucher",
    text: formatMessage(messages.workingTime),
  } as const;
  const navSyncEntry = {
    Icon: CloudSyncIcon,
    route: "/navSync",
    text: formatMessage(messages.navSync),
  } as const;
  const economicSyncEntry = {
    Icon: CloudSyncIcon,
    route: "/economicSync",
    text: formatMessage(messages.economicSync),
  } as const;
  const brugerdataSyncEntry = {
    Icon: CloudSyncIcon,
    route: "/brugerdataSync",
    text: formatMessage(messages.brugerdataSync),
  } as const;
  const tasksPrintoutEntry = {
    Icon: CloudSyncIcon,
    route: "/tasksPrintout",
    text: formatMessage(messages.tasksPrintout),
  } as const;
  const settingsEntry = {
    Icon: SettingsIcon,
    route: "/settings/overview",
    text: formatMessage(messages.settings),
  } as const;
  const dinnerEntry = {
    Icon: FoodVariantIcon,
    route: "/dinnerBookings",
    text: formatMessage(messages.dinnerBookings),
  } as const;
  const reportGeneratorEntry = {
    Icon: FileChartIcon,
    route: "/report",
    text: formatMessage(messages.reports),
  } as const;
  const informationEntry = {
    badgeCount: unreadInformationCount,
    Icon: NewspaperIcon,
    route: "/information",
    text: formatMessage(messages.information),
  } as const;
  const workTypeStatus = {
    Icon: ChartArcIcon,
    route: "/worktypeStatus",
    text: formatMessage(messages.workTypeStatus),
  } as const;
  const culturesEntry = {
    Icon: CornIcon,
    route: "/culture",
    text: formatMessage(messages.culture),
  } as const;
  const appendicesEntry = {
    Icon: FolderMultipleIcon,
    route: "/appendices",
    text: formatMessage(messages.appendices),
  } as const;
  const fileArchiveEntry = {
    Icon: FileDocumentBoxMultipleIcon,
    route: "/files",
    text: formatMessage(messages.documents),
  } as const;
  const projectEntry = {
    Icon: ProjectIcon,
    route: "/projects",
    text:
      customerSettings.projectLabelVariant === "PROJECT"
        ? formatMessage({defaultMessage: "Projekter"})
        : formatMessage({defaultMessage: "Sager"}),
  } as const;

  if (manager) {
    const result = [
      customerSettings.informationPageEnabled ? informationEntry : undefined,
      calendarEntry,
      customerSettings.geolocation?.enabled ? geolocationMapEntry : undefined,
      customerSettings.enableTimeOverviewPage ? timeOverviewEntry : undefined,
      customerSettings.autoComputedSalaryVouchers ? computedSalaryVouchersEntry : undefined,
      customerSettings.salaryVouchersVisibleToManagers ? salaryVouchersEntry : undefined,
      DIVIDER,
      customerSettings.enableProjects && customerSettings.displayProjectsPageMenu === "MAIN"
        ? projectEntry
        : undefined,
      customerSettings.orderListPageEnabled ? orderEntry : undefined,
      !customerSettings.managerMenuTasksAfterEmployees ? tasksEntry : undefined,
      customerSettings.externalTaskCustomer ? customersEntry : undefined,
      customerSettings.externalTaskCulture ? culturesEntry : undefined,
      customerSettings.enableLocationStorage ? locationStorageEntry : undefined,
      DIVIDER,
      bookkeepingEntry,
      customerSettings.navSync ? navSyncEntry : undefined,
      customerSettings.economicSync ? economicSyncEntry : undefined,
      customerSettings.brugerdataSync ? brugerdataSyncEntry : undefined,
      customerSettings.tasksPrintout ? tasksPrintoutEntry : undefined,
      projectManager ? undefined : remunerationReportsEntry,
      employeesEntry,
      customerSettings.enableDocuments ? fileArchiveEntry : undefined,
      customerSettings.managerMenuTasksAfterEmployees ? tasksEntry : undefined,
      customerSettings.dinnerBookings ? dinnerEntry : undefined,
      DIVIDER,
      customerSettings.workshopEnabled ? workshopEntry : undefined,
      DIVIDER,
      projectManager ? undefined : settingsEntry,
      customerSettings.enableReportGenerator ? DIVIDER : undefined,
      customerSettings.enableReportGenerator ? reportGeneratorEntry : undefined,
      customerSettings.expectedAmountEnabled ? workTypeStatus : undefined,
      customerSettings.enableAppendicesPage ? appendicesEntry : undefined,
    ].filter(notUndefined);
    return result;
  } else {
    const result = [
      customerSettings.informationPageEnabled ? informationEntry : undefined,
      tasksEntry,
      (customerSettings.calendarForMachineOperators && !jobber) ||
      (seniorMachineOperator && customerSettings.seniorMachineOperatorCanManageOrders)
        ? calendarEntry
        : undefined,
      customerSettings.orderListPageEnabled &&
      seniorMachineOperator &&
      customerSettings.seniorMachineOperatorCanManageOrders
        ? orderEntry
        : undefined,
      customerSettings?.geolocation.enabled &&
      customerSettings.mapVisibleToAll &&
      !(!customerSettings.mapVisibleToJobberRole && jobber)
        ? geolocationMapEntry
        : undefined,
      customerSettings.enableTimeOverviewPage ? timeOverviewEntry : undefined,
      customerSettings.autoComputedSalaryVouchers ? computedSalaryVouchersEntry : undefined,
      customerSettings.salaryVouchersVisibleToEmployees ? salaryVouchersEntry : undefined,
      DIVIDER,
      !jobber && customerSettings.workshopEnabled ? workshopEntry : undefined,
      DIVIDER,
      !jobber &&
      !customerSettings.hideCustomerMenuFromMachineOperators &&
      customerSettings.externalTaskCustomer
        ? customersEntry
        : undefined,
      !customerSettings.hideCultureMenuFromMachineOperators && customerSettings.externalTaskCulture
        ? culturesEntry
        : undefined,
      customerSettings.locationStoragePageVisibleToEveryone ? locationStorageEntry : undefined,
      employeesEntry,
      customerSettings.enableDocuments ? fileArchiveEntry : undefined,
      customerSettings.expectedAmountEnabled &&
      customerSettings.expectedAmountVisibleToMachineOperators
        ? workTypeStatus
        : undefined,
      customerSettings.dinnerBookings && canAccessFoodBookingPage ? dinnerEntry : undefined,
      customerSettings.enableAppendicesPage && customerSettings.employeeCanAccessAppendicesPage
        ? appendicesEntry
        : undefined,
    ].filter(notUndefined);
    return result;
  }
}

interface DrawerContentProps {
  onClose: () => void;
  onRequestCheckForUpdates: () => void;
  unreadInformationCount: number;
}

export function MainDrawerContent(props: DrawerContentProps): React.JSX.Element {
  const {onClose, onRequestCheckForUpdates, unreadInformationCount} = props;

  const {formatMessage} = useIntl();
  const currentRole = useSelector(getCurrentRole);
  const customerSettings = useSelector(getCustomerSettings);
  const currentPathname = useSelector(getPathName);
  const currentUserID = useSelector(getUserId);
  const currestUserProfile = useSelector(getCurrentUserProfile);

  const dispatch = useDispatch();

  const [clearAppDialogOpen, setClearAppDialogOpen] = useState(false);
  const [fullscreenLinksDialogOpen, setFullscreenLinksDialogOpen] = useState(false);
  const [hideMenuLogo, setHideMenuLogo] = useState(false);

  const menuData = useMemo(
    () => computeMenuContent(formatMessage, currentRole, unreadInformationCount, customerSettings),
    [currentRole, customerSettings, formatMessage, unreadInformationCount],
  );

  const [selectedRoute, setSelectedRoute] = useState<PathTemplate | null>(() => {
    const selectedItem = menuData.find(
      (item) =>
        item !== DIVIDER &&
        item.route &&
        (currentPathname === item.route || currentPathname.startsWith(`${item.route}/`)),
    );
    if (selectedItem && selectedItem !== DIVIDER) {
      return selectedItem.route;
    } else {
      return null;
    }
  });

  const doFetchPunchInOut = useCallback((): Promise<unknown> => {
    const baseURL = resourceURL("punchInOut");
    const count = 5;
    const now = new Date().toISOString();
    const url = `${baseURL}?sortBy[]=-punchTimestamp&limit=${count}&employee=${currentUserID}&anticache=${now}`;
    return jsonFetch(url, "GET")
      .then((response) => {
        const punchInOutData = response.data.data.punchInOut;
        const entries = punchInOutData ? punchInOutData.all : null;
        if (entries) {
          for (let i = 0; i < entries.length; i += 1) {
            const entry = entries[i];
            dispatch(actions.addToOffline(entry));
          }
        }
        return;
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
      });
  }, [currentUserID, dispatch]);

  const handleChange = useCallback(
    (route: PathTemplate, params?: PathParameters): void => {
      onClose();
      if (currentPathname !== route) {
        setSelectedRoute(route);
        dispatch(actions.go(route, params));
      }
    },
    [currentPathname, dispatch, onClose],
  );
  const handleUserMenuSelect = useCallback(
    (event: React.MouseEvent<HTMLLIElement>, key: string): void => {
      if (event) {
        event.preventDefault();
      }
      if (key === "logout") {
        dispatch(actions.logout(true));
        dispatch(actions.go("/"));
      } else if (key === "checkForUpdates") {
        onRequestCheckForUpdates();
      } else if (key === "deviceSetup") {
        handleChange("/deviceSetup");
      } else if (key === "userProfile") {
        handleChange("/userProfile/:id", {
          id: currentUserID || "",
        });
      } else if (key === "account") {
        handleChange("/account");
      } else if (key === "systemSetup") {
        handleChange("/systemSetup");
      } else if (key === "consultantMirror") {
        handleChange("/mirror");
      } else if (key === "fetchPunchInOut") {
        doFetchPunchInOut();
      } else if (key === "fullscreenLinks") {
        setFullscreenLinksDialogOpen(true);
      } else if (key === "clearApp") {
        setClearAppDialogOpen(true);
      } else if (key === "forceReload") {
        const queries = computePersistedQueries(customerSettings, currentRole || undefined);
        dispatch(actions.persistedQueriesRequested(queries, true));
      } else if (key === "teamviewer") {
        window.open("https://get.teamviewer.com/nter6de", "_blank");
      } else if (key === "privacyPolicy") {
        window.open(
          "https://customoffice.dk/privatlivspolitik/",
          bowser.ios ? "_system" : "_blank",
        );
      } else if (key === "sync") {
        handleChange("/sync");
      }
    },
    [
      currentRole,
      currentUserID,
      customerSettings,
      dispatch,
      doFetchPunchInOut,
      handleChange,
      onRequestCheckForUpdates,
    ],
  );
  const handleMenuImageLoadingError = useCallback((): void => {
    setHideMenuLogo(true);
  }, []);

  const handleFullscreenLinksDialogOk = useCallback((): void => {
    setFullscreenLinksDialogOpen(false);
  }, []);

  const handleClearAppDialogCancel = useCallback((): void => {
    setClearAppDialogOpen(false);
  }, []);

  const initials = ((currestUserProfile && currestUserProfile.alias) || "").toUpperCase();
  const role = currentRole;
  const manager = role ? role.manager : false;
  const consultant = !!role && role.consultant;
  const owner = role && role.owner;

  const headerHeight = 48;
  const header = (
    <div style={{height: headerHeight}}>
      <UserDropdownMenu
        consultant={consultant}
        customerSettings={customerSettings}
        initials={initials}
        manager={manager}
        onItemClick={handleUserMenuSelect}
        owner={!!owner}
      />
    </div>
  );

  const content = menuData.map((data, index) => {
    if (data === DIVIDER) {
      return <Divider key={index} />;
    } else {
      return (
        <NavListItem
          key={data.route}
          onRequestChange={handleChange}
          selected={selectedRoute === data.route}
          {...data}
        />
      );
    }
  });
  const settingsArray = useSelector(getSettingsArray);
  const settings = settingsArray[0];

  const menuLogoHeight = 96;
  let menuLogo;
  if (!hideMenuLogo) {
    const imageStyle: React.CSSProperties = {
      bottom: 0,
      display: "block",
      height: "auto",
      maxHeight: menuLogoHeight,
      maxWidth: "100%",
      position: "absolute",
      width: "auto",
    };
    menuLogo = (
      <div
        style={{
          display: "flex",
          justifyContent:
            settings.menuLogoAlignment === "CENTER"
              ? "center"
              : settings.menuLogoAlignment === "LEFT"
                ? "flex-start"
                : "flex-end",
          minHeight: menuLogoHeight,
          position: "relative",
        }}
      >
        <img
          alt="logo"
          onError={handleMenuImageLoadingError}
          src={globalConfig.logoURL}
          style={imageStyle}
        />
      </div>
    );
  }
  return (
    <>
      {header}
      <List
        style={{
          flexGrow: 1,
        }}
      >
        {customerSettings.showManagersUnpaidBillWarning && manager ? (
          <div style={{color: "#F00", fontSize: 16, margin: "0 10px"}}>
            <FormattedMessage
              defaultMessage="Der er et forfaldent beløb til CustomOffice, som ikke er betalt. Venligst betal omgående for ikke at miste adgangen."
              id="menu.unpaid-bill-warning"
            />
          </div>
        ) : null}
        {content}
        <Divider />
        <ListItem button component="a" href="https://customoffice.dk/support" target="_blank">
          <ListItemIcon>
            <SvgIcon>
              <HelpCircleOutlineIcon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText primary={formatMessage(messages.help)} />
        </ListItem>
      </List>
      {menuLogo}
      <ClearAppDialog onCancel={handleClearAppDialogCancel} open={clearAppDialogOpen} />
      <FullscreenLinksDialog
        onOk={handleFullscreenLinksDialogOk}
        open={fullscreenLinksDialogOpen}
      />
    </>
  );
}
